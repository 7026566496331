import { toast } from "react-toastify";

export const Success = (message) => {
  return toast.success(message, {
    position: "top-right",
    autoClose:  10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export const Error = (message) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export const Warning = (message) => {
  return toast.warn(message);
};
