import { Get, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const GetUploadUrl = async (objectName) => {
    try {
        let {Authorization} = UserCredentials()
        let url = await Get(`${baseUrl}/account/uploadUrl/${objectName}`, Authorization)
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetObjectUrl = async (objectName) => {
    try {
        let {Authorization} = UserCredentials()
        let url = await Get(`${baseUrl}/account/objectUrl/${objectName}`, Authorization)
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetCreatorInfo = async (accountId) => {
    try {
        let url = await Get(`${baseUrl}/account/creatorIntro/${accountId}`, {})
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const BecomeCreator = async () => {
    try {
        let {Authorization} = UserCredentials()
        let url = await Get(`${baseUrl}/account/becomeCreator`, Authorization)
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetLinkedInAuthUrl = async () => {
    try {
        let {Authorization} = UserCredentials()
        let url = await Get(`${baseUrl}/account/linkedInAuthUrl`, Authorization)
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GenerateLinkedInPost = async (code, queryParams) => {
    try {
        let {Authorization} = UserCredentials()
        let url = await Get(`${baseUrl}/account/linkedIn/createPost/${code}?QuizId=${queryParams.QuizId}&PostType=${queryParams.PostType}`, Authorization)
        return url
    } catch (error) {
        console.log(error)
        throw error
    }
}