import React from "react";
import { FaFireFlameCurved } from "react-icons/fa6";

const StreakCard = ({
  streakIcon,
  streakTitle,
  streakCount,
  isCurrentStreakActive,
  todaysResponseCollected
}) => {
  return (
    <div className={`bg-gray-900 text-white h-[80px] rounded-lg flex flex-col items-center shadow-lg py-3 transform transition-transform ${!todaysResponseCollected ? "border-[1px] border-[#AE04FD] border-solid scale-105" : ""}`}>
      <div
        className={`text-xl flex flex-grow justify-center items-center w-[40px] h-[40px] rounded-full ${
          isCurrentStreakActive ? "text-[#E25822]" : "text-gray-400"
        }  `}
      >
        {/* <span>{streakIcon}</span> */}
        <FaFireFlameCurved />
      </div>
      <div className="text-center">
        <span className="flex justify-center items-center font-semibold">
          {streakTitle}
        </span>
        {/* <p className="text-xs">{streakCount} Days</p> */}
      </div>
    </div>
  );
};

export default StreakCard;
