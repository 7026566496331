import React, { useState } from "react";
import StreakCard from "../StreakCard/StreakCard";
import { GetStreakCount } from "../../service/quizAnswers/quizAnswers";
import { useQuizReportStore } from "../../store/quizAnswer/quizAnswer";
import { useEffect } from "react";

const streaksInitialState = [
  { icon: "🔥", title: "", count: null,isCurrentStreakActive: false, todaysResponseCollected: true },
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  { icon: "🔥", title: "", count: null, isCurrentStreakActive: false, todaysResponseCollected: true},
  // ... Add more streaks as needed
];

const StreakCardList = () => {
  let { getStreakCount, streak } = useQuizReportStore();

  const [streaks, setStreaks] = useState([...streaksInitialState]);

  useEffect(() => {
    fetchStreak();
  }, []);

  let fetchStreak = async () => {
    let Streak = await getStreakCount();
    if (Streak.currentStreak < 5) {
      let initialCount = 1;
      let resp = streaks.map((streak) => {
        let count = initialCount;
        initialCount++;
        if(count === Streak.currentStreak + 1 && !Streak.todaysResponse){
          return { ...streak, title: count, isCurrentStreakActive: count <= Streak.currentStreak, todaysResponseCollected: false };
        }
        return { ...streak, title: count, isCurrentStreakActive: count <= Streak.currentStreak, todaysResponseCollected: true};
      });
      setStreaks([...resp]);
    } else {
      let initialCount = Streak.currentStreak - 4; // 18 - 5 => 13+1, 14, 15, 16
      let resp = streaks.map((streak) => {
        let count = initialCount;
        initialCount++;
        if(count === Streak.currentStreak + 1 && !Streak.todaysResponse){
          return { ...streak, title: count, isCurrentStreakActive: count <= Streak.currentStreak, todaysResponseCollected: false };
        }
        return { ...streak, title: count, isCurrentStreakActive: count <= Streak.currentStreak, todaysResponseCollected: true };
      });
      setStreaks([...resp]);
    }
  };
  return (
    <>
      {streaks.map((streak, index) => (
        <StreakCard
          key={index}
          streakIcon={streak.icon}
          streakTitle={streak.title}
          streakCount={streak.count}
          isCurrentStreakActive={streak.isCurrentStreakActive}
          todaysResponseCollected={streak?.todaysResponseCollected}
        />
      ))}
    </>
  );
};
export default StreakCardList;
