import React from "react";
import SearchIcon from "../../assets/svg/search.svg";

const SearchBar = (props) => {
  const { data, onChange, placeholder, iconPosition = "right" } = props;
  return (
    <div className="text-white">
      <div className="flex items-center bg-[#353440] max-w-[85vw] md:max-w-[35vw] rounded-lg flex-row-reverse hover:cursor-pointer">
        <img src={SearchIcon} className={`w-[30px] h-[20px] fit-content mr-2 ${
          iconPosition === "left" ? "ml-3" : "mr-3"
        }`} alt="" />
        <input
          type="text"
          placeholder={placeholder}
          className="w-[83vw] md:w-[33vw] bg-transparent h-[40px] px-4 py-2 focus:outline-none text-sm"
          onChange={onChange}
          value={data}
        />
      </div>
    </div>
  );
};
export default SearchBar;
