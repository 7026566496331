import { Delete, Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"
import { useAuthStore } from "../../store/auth/auth"

export const CreateNotifier = async (data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/notifier/create`,data ,Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const CancelNotifier = async (id) => {
    try {
        let { Authorization } = UserCredentials()
        return await Delete(`${baseUrl}/notifier/delete/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const CancelDiscussionNotifier = async (id) => {
    try {
        let { Authorization } = UserCredentials()
        return await Delete(`${baseUrl}/notifier/cancel/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetDiscussionNotifier = async (discussionId) => {
    try {
        let { Authorization } = UserCredentials()
        return await Get(`${baseUrl}/notifier/getDiscussionNotifier/${discussionId}`, Authorization)       
    } catch (error) {
        console.log(error)
        throw error
    }
}