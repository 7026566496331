import { create } from "zustand";
import { FetchQuizCategories } from "../../service/quizCategory/quizCategory";

export const useQuizCategoriesStore = create((set, get) => ({
  quizCategories: [],
  quizCategoriesDDOptions: [],

  async fetchQuizCategories() {
    try {
      let quizCategories = await FetchQuizCategories();
      set(() => ({
        quizCategories: quizCategories,
        quizCategoriesDDOptions: quizCategories.map((quizCategory) => ({
          label: quizCategory.Title,
          value: quizCategory._id,
        })),
      }));
      return quizCategories;
    } catch (error) {
      console.log(error);
    }
  },
}));
