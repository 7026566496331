import React, {useState} from 'react';
import CreatorComponent from '../../components/Creator/Creator'

const Creator = (props) => {
const [state, setState] = useState();
    return <div className='w-[100%]'>
        <CreatorComponent />
    </div>;
};

export { Creator };