import { create } from "zustand";
import {
  ChargeYourWallet,
  ConnectWithStripe,
  CreateOrUpdateCharges,
  FetchAccountCharges,
  GetAccountPayouts,
  GetCurrentBalance,
  GetPaymentMethod,
  GetPlatformEarnings,
} from "../../service/charges/charges";
import { StripePayoutStatusConstants } from "../../config/constants/charges";
import { Badge } from "../../components/Badge/Badge";
import moment from "moment/moment";
import getSymbolFromCurrency from "currency-symbol-map";

export const useChargesStore = create((set, get) => ({
  charges: null,
  walletBalance: 0,
  platformEarning: 0,
  accountPayouts: [],
  paymentMethodInfo: null,

  async fetchAccountCharges(id) {
    try {
      let Charges = await FetchAccountCharges(id);
      set({ charges: Charges });
      return Charges;
    } catch (error) {
      console.log(error);
    }
  },

  async createOrUpdateCharges(data) {
    try {
      await CreateOrUpdateCharges(data);
    } catch (error) {
      console.log(error);
    }
  },

  async connectWithStripe(data) {
    try {
      let resp = await ConnectWithStripe(data);
      window.location.href = resp.url;
    } catch (error) {
      console.log(error);
    }
  },

  async chargeYourWallet(data) {
    try {
      await ChargeYourWallet(data);
      await get().getCurrentBalance();
      return {
        Success: true,
      };
    } catch (error) {
      console.log(error);
    }
  },

  async getCurrentBalance() {
    try {
      let walletBalance = await GetCurrentBalance();
      set((state) => ({ walletBalance: walletBalance }));
      return walletBalance;
    } catch (error) {
      console.log(error);
    }
  },

  async getPlatformEarnings() {
    try {
      let platformEarningResponse = await GetPlatformEarnings();
      set(() => ({ platformEarning: platformEarningResponse }));
      return platformEarningResponse;
    } catch (error) {
      console.log(error);
    }
  },

  async getAccountPayouts() {
    try {
      let Payouts = await GetAccountPayouts();
      let PayoutsData = Payouts.data.map((data) => {
        return {
          amount: (
            <div className="font-semibold text-md">
              {`${getSymbolFromCurrency(data.currency)}${parseFloat(
                data.amount / 100
              ).toFixed(2)}`}{" "}
              <span className="uppercase font-light">{data.currency}</span>
            </div>
          ),

          arrivalDate: (
            <div> {moment(data.arrival_date).format("MMM DD, h:mm A")}</div>
          ),
          created: <div> {moment(data.created).format("MMM DD")}</div>,
          status: (
            <>
              <Badge text={StripePayoutStatusConstants[data.status]} />
            </>
          ),
          // scheduleDate: (<div className="flex justify-between items-center"> <div> {moment(data.ScheduleTime).format("DD/MM, h:mm A")}</div> <TableNavigator route={`/account/meeting/${data._id}`} /></div>),
        };
      });
      set((state) => ({ accountPayouts: PayoutsData }));
      return Payouts;
    } catch (error) {
      console.log(error);
    }
  },

  async getPaymentMethod() {
    let PaymentMethodInfo = await GetPaymentMethod();
    set((state) => ({ paymentMethodInfo: PaymentMethodInfo }));
  },
}));
