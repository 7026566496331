import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Question as QuestionComponent } from '../../components/Question/Question';

const QuestionPage = props => {
    return <div>
        <QuestionComponent />
    </div>;
};

QuestionPage.propTypes = {};

export { QuestionPage };