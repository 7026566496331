import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { CreatorInfoComponent } from '../../components/CreatorInfoComponent/CreatorInfoComponent';

const CreatorInfo = props => {
    return <div>
        <CreatorInfoComponent />
    </div>;
};

CreatorInfo.propTypes = {};

export { CreatorInfo };