import React, { useState } from "react";
import { Referral as ReferralComponent } from "../../components/Referral/Referral";

const Referral = (props) => {
  return (
    <div>
      <ReferralComponent />
    </div>
  );
};

Referral.propTypes = {};

export { Referral };
