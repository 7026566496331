import { Get, Post, Put, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const FetchRelatedCreators = async () => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/account/relatedCreators`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}


export const FetchCreatorInfo = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/account/creatorsInfo/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const CreateMeeting = async (data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/meets/create`, data, Authorization) 
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const FetchAccountMeetings = async (pageConfig) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/meets/account?Page=${pageConfig.currentPage}&Limit=${pageConfig.limit}&SortBy=${pageConfig.sortBy}&SortOrder=${pageConfig.sortOrder}${pageConfig.filteredBy && `&Status=${pageConfig.filteredBy}`}${pageConfig.searchText && `&Search=${pageConfig.searchText}`}${`&Upcoming=${pageConfig.Upcoming}`}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
} 
export const GetMeetingInfo = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/meets/get/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const UpdateMeeting = async (id, data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Put(`${baseUrl}/meets/update/${id}`, data, Authorization)    
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const JoinMeeting = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Put(`${baseUrl}/meets/join/${id}`, {}, Authorization)    
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const StartMeeting = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Put(`${baseUrl}/meets/start/${id}`, {}, Authorization)    
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const EndMeeting = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Put(`${baseUrl}/meets/end/${id}`, {}, Authorization)    
    } catch (error) {
        console.log(error)
        throw error
    }
}