import { baseUrl, Get, Post } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const IsReferralExist = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/referral/exist`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateReferral = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/referral/create`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
