import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaAnglesRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const TableNavigator = ({route}) => {
  const [state, setState] = useState();
  let navigate = useNavigate()
  
  const handleNavigator = ()=>{
    navigate(route)
  }
  return (
    <div onClick={handleNavigator} className="cursor-pointer">
      <FaAnglesRight />
    </div>
  );
};

TableNavigator.propTypes = {};

export { TableNavigator };
