import { create, GetState, SetState } from "zustand";
import { Success } from "../../config/helpers/toast-helper";
import {
  GetAccountInfo,
  GetAccountVerification,
  GetSyncAccountApps,
  Login,
  SignUp,
  VerifyCode,
  VerifyOTPCode,
} from "../../service/auth/auth";
import {
  SetToLocalStorage,
  UserCredentials,
} from "../../config/helpers/local-storage-accessor-helper";
import { useAccountStore } from "../account/account";

export const useAuthStore = create((set, get) => ({
  user: null,
  account: null,
  userProfilePicture: "",
  planInfo: null,

  signUp: async (data) => {
    try {
      let { user, account, access_token } = await SignUp(data);
      set({ user: user, account: account });
      let payload = {
        accountId: account.id,
        accessToken: access_token,
      };
      SetToLocalStorage(payload);
      return { user, account };
    } catch (error) {
      console.log(error);
      return { user: null, account: null };
    }
  },

  login: async (data) => {
    try {
      let loginResp = await Login(data);
      let { user, account, access_token } = loginResp || {}; // Handle undefined loginResp
      set({ user: user, account: account });
      let payload = {
        accountId: account?.id,
        accessToken: access_token,
      };
      SetToLocalStorage(payload);
      return { user, account };
    } catch (error) {
      console.error(error);
      // Handle error appropriately (e.g., rethrow or return null user and account)
      return { user: null, account: null };
    }
  },

  verifyCode: async (code) => {
    try {
      let { user, account, interests, access_token } = await VerifyCode(code);
      set({ user: user, account: account });
      let payload = {
        accountId: account._id,
        accessToken: access_token,
      };
      SetToLocalStorage(payload);
      return { user, account, interests };
    } catch (error) {
      console.log(error);
    }
  },

  getAccountInfo: async () => {
    let { getObjectUrl } = useAccountStore.getState();
    try {
      let { user, account, planInfo } = await GetAccountInfo();
      let ProfilePicture = "";
      if (user.ProfilePicture) {
        ProfilePicture = await getObjectUrl(user.ProfilePicture);
      }
      set((state) => ({
        user: user,
        account: account,
        planInfo: planInfo,
        userProfilePicture: ProfilePicture,
      }));
      return { user, account };
    } catch (error) {
      console.log(error);
    }
  },

  getAccountVerification: async () => {
    try {
      await get().getAccountInfo();
      // Now you can access the user and account information
      const { user, account } = get();
      let payload = {
        Email: user.Email,
      };
      await GetAccountVerification(payload);
    } catch (error) {
      console.log(error);
    }
  },

  verifyOTPCode: async (data) => {
    try {
      let account = await VerifyOTPCode(data);
      set({ account });
      return account;
    } catch (error) {
      console.log(error);
    }
  },

  getSyncAccountApps: async () => {
    try {
      return await GetSyncAccountApps();
    } catch (error) {
      console.log(error);
    }
  },
}));
