import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { Input } from "../Input/Input";
import { CommentCard } from "../CommentCard/CommentCard";
import { Badge } from "../Badge/Badge";

import { RiPencilFill } from "react-icons/ri";
import { CreateMeeting } from "../CreateMeeting/CreatingMeeting";
import { useMeetingStore } from "../../store/meeting/meeting";
import { useParams } from "react-router-dom";
import * as moment from "moment";
import {
  MeetingStatusConstants,
  MeetingStatusConstantsMapping,
} from "../../config/constants/meeting";
import { useAuthStore } from "../../store/auth/auth";
import { Banner } from "../Banner/Banner";
import { CreatorInfoCard } from "../CreatorInfoCard/CreatorInfoCard";
import { useCommentStore } from "../../store/comment/comment";
import { IoSend } from "react-icons/io5";
import { CommentEntityTypeConstants } from "../../config/constants/comment";
import { Button } from "../Button/Button";
import { useParticipantsStore } from "../../store/participants/participants";
import { ParticipantEntityTypeConstants } from "../../config/constants/participants";
import { MeetingPreviewModal } from "../MeetingPreviewModal/MeetingPreviewModal";
import { MeetingSession } from "../MeetingSession/MeetingSession";
import { RatingCard } from "../RatingCard/RatingCard";
import { Avatar } from "../Avatar/Avatar";
import { PiBroadcastBold, PiHandshake } from "react-icons/pi";
import { CiCreditCard1 } from "react-icons/ci";
import { RiVideoAddLine } from "react-icons/ri";
import { FaBroadcastTower } from "react-icons/fa";
import { HiOutlineVideoCamera } from "react-icons/hi";
import { RxCircleBackslash } from "react-icons/rx";
import { TypeToChargeMapping } from "../../config/constants/charges";
import IconLoaderIcon from "../../assets/gif/IconButtonLoaderSm.gif"; 
import Loader from '../../assets/gif/buttonLoader.gif'
import { calculateNewValue } from "@testing-library/user-event/dist/utils";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";

let ZoomStates = {
  PREVIEW: 1,
  VIDEO: 2,
};

const MeetingDetailComponent = (props) => {
  let {
    analyzeMeeting,
    getMeetingInfo,
    fetchCreatorInfo,
    updateMeeting,
    startMeeting,
    endMeeting,
  } = useMeetingStore();

  let { becomeParticipant, sessionToken, resetSessionToken } =
    useParticipantsStore();
  let { createComment, fetchComments, comments } = useCommentStore();
  let { account, user, getAccountInfo, userProfilePicture } = useAuthStore();

  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zoomState, setZoomState] = useState(ZoomStates.PREVIEW);
  const [sessionFeatures, setSessionFeatures] = useState([
    "video",
    "audio",
    "users",
    "chat",
    "share",
  ]);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [confirmationModal, setShowConfirmationModal] = useState(false);

  let { id } = useParams();
  function handleMeetingModal() {
    setShowModal((prev) => !prev);
  }

  useEffect(() => {
    fetchMeetingInfo();
  }, [id]);

  async function fetchMeetingInfo() {
    try {
      let meetingInfo = await getMeetingInfo(id);
      let queryPayload = {
        EntityType: CommentEntityTypeConstants.MEETING,
        EntityId: id,
      };
      await fetchComments(queryPayload);
      await fetchCreatorInfo(meetingInfo.OrganizerAccount);
      if (!account) {
        await getAccountInfo();
      }
    } catch (error) {
      console.log(error);
    }
  }

  let handleMessage = async (e) => {
    e.preventDefault();
    let data = {
      EntityType: CommentEntityTypeConstants.MEETING,
      EntityId: id,
      Content: comment,
      // for just FE use
      FirstName: user.FirstName,
    };
    await createComment(data);
    setComment("");
  };

  let handleButtonText = () => {
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Requested
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        return "Accept";
      }
      return "Join";
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Schedule
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        return "Start";
      }
      return "Join";
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Inprogress
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        return "Ongoing";
      }
      return "Join";
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.WAITING_FOR_PAYMENT
    ) {
      if (account?._id == analyzeMeeting.CreatorAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        return "Pay Now";
      }
      return "";
    }
  };

  let handleButtonDisabled = () => {
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Requested
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        return false;
      }
      return true;
    }
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Schedule
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        // todo: we need to remove "!"
        return !isMeetingApproaching(analyzeMeeting.ScheduleTime);
      }
      return true;
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Inprogress
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        return false;
      }
      return false;
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Completed
    ) {
      return true;
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.WAITING_FOR_PAYMENT
    ) {
      if (account?._id == analyzeMeeting.CreatorAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        return false;
      }
      return true;
    }
  };

  let cancelMeeting = async () => {
    setLoading(true);
    await updateMeeting(id, { Status: MeetingStatusConstants.Cancelled });
    setLoading(false);
  };

  let updateMeetingStatus = async () => {
    setLoading(true);
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Requested
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        await updateMeeting(id, {
          Status: MeetingStatusConstants.WAITING_FOR_PAYMENT,
        });
      }
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.WAITING_FOR_PAYMENT
    ) {
      if (account?._id == analyzeMeeting.CreatorAccount) {
        await updateMeeting(id, { Status: MeetingStatusConstants.Schedule });
      }
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Schedule
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        // need to create an api(start meeting)
        await startMeeting(id);
        setSessionFeatures((prev) => [...prev, "share", "settings"]);
        let participantPayload = {
          EntityId: id,
          EntityType: ParticipantEntityTypeConstants.MEET,
        };
        let participant = await becomeParticipant(participantPayload);
        // await updateMeeting(id, { Status: MeetingStatusConstants.Inprogress });
      }
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Inprogress
    ) {
      let participantPayload = {
        EntityId: id,
        EntityType: ParticipantEntityTypeConstants.MEET,
      };
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        // redirecting to meeting page
        setSessionFeatures((prev) => [...prev, "share", "settings"]);
        let participant = await becomeParticipant(participantPayload);
        // let joinMeetingResp = await joinMeeting(id)
        if (!participant) {
          console.log("there is something wrong");
        }
        return;
      }
      // hit join meeting api.
      let participant = await becomeParticipant(participantPayload);
      // let joinMeetingResp = await joinMeeting(id)
      if (!participant) {
        console.log("there is something wrong");
      }
    }
    await fetchMeetingInfo();
    setLoading(false);
  };

  function isMeetingApproaching(meetingTime) {
    // Get the current time
    const currentTime = moment();

    // Calculate the time 5 minutes before the meeting starts
    const meetingStartTime = moment(meetingTime).subtract(5, "minutes");

    // Compare current time with meeting start time
    return currentTime.isBefore(meetingStartTime);
  }

  let handleCloseSession = async () => {
    await fetchMeetingInfo();
    setZoomState(ZoomStates.PREVIEW);
    if(analyzeMeeting?.OrganizerAccount !== account?._id){
      setShowRatingModal(true);
    }
    resetSessionToken("");
    if(analyzeMeeting?.OrganizerAccount == account?._id){
      setShowConfirmationModal(true)
    }
  };

  let handleEndMeeting = async () => {
    await fetchMeetingInfo();
    if (analyzeMeeting?.OrganizerAccount == account?._id) {
      await endMeeting(id);
      window.location.reload();
    }
  }

  let handleCloseRatingModal = () => {
    setShowRatingModal(false);
    window.location.reload();
  };

  function formatTime(timestamp) {
    const now = new Date();
    const target = new Date(timestamp);
    const diff = now - target;
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
      // If the date is before yesterday, show the date
      return target.toLocaleDateString();
    } else if (days === 1) {
      // If the date is yesterday, display "yesterday"
      return "Yesterday";
    } else if (hours >= 1) {
      // If the date is within the last 24 hours, display "X hours ago"
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes >= 1) {
      // If the date is within the last hour, display "X minutes ago"
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      // If the date is within the last minute, display "Just now"
      return "Just now";
    }
  }

  let handleCallIcon = () => {
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Requested
    ) {
      if (account?._id !== analyzeMeeting.OrganizerAccount) {
        return <></>;
      }
      return (
        <div className="flex flex-col justify-center items-center">
          <div
            onClick={updateMeetingStatus}
            className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#8254F8] ${
              loading && "bg-[#2E3036]"
            }`}
          >
            {!loading ? (
              <PiHandshake className="text-[25px] ml-[1px]" />
            ) : (
              <img width={30} height={30} src={IconLoaderIcon} />
            )}
          </div>
          <div className="text-[12px]">{handleButtonText()}</div>
        </div>
      );
    }
    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Schedule
    ) {
      if (account?._id == analyzeMeeting.OrganizerAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        // !isMeetingApproaching(analyzeMeeting.ScheduleTime)
        return true ? (
          <div className="flex flex-col justify-center items-center">
            <div
              onClick={updateMeetingStatus}
              className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full ${
                loading ? "bg-[#2E3036]" : "bg-[#8254F8]"
              }`}
            >
              {!loading ? (
                <PiBroadcastBold className="text-[20px] ml-[1px]" />
              ) : (
                <img width={30} height={30} src={IconLoaderIcon} />
              )}
            </div>
            <div className="text-[12px]">{handleButtonText()}</div>
          </div>
        ) : (
          <></>
        );
      }
      return <></>;
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Inprogress
    ) {
      return (
        <div className="flex flex-col justify-center items-center">
          <div
            onClick={updateMeetingStatus}
            className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#8254F8] ${
              loading && "bg-[#2E3036]"
            }`}
          >
            {!loading ? (
              <HiOutlineVideoCamera className="text-[25px] ml-[1px]" />
              ) : (
              <img width={30} height={30} src={IconLoaderIcon} />
            )}
          </div>
          <div className="text-[12px]">{handleButtonText()}</div>
        </div>
      );
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.Completed
    ) {
      return true;
    }

    if (
      analyzeMeeting &&
      analyzeMeeting.Status == MeetingStatusConstants.WAITING_FOR_PAYMENT
    ) {
      if (account?._id == analyzeMeeting.CreatorAccount) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        return (
          <div className="flex flex-col justify-center items-center">
            <div
              onClick={updateMeetingStatus}
              className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#8254F8] ${
                loading && "bg-[#2E3036]"
              }`}
            >
              {!loading ? (
                <CiCreditCard1 className="text-[30px] cursor-pointer" />
              ) : (
                <img width={30} height={30} src={IconLoaderIcon} />
              )}
            </div>
            <div className="text-[12px]">{handleButtonText()}</div>
          </div>
        );
      }
      return <></>;
    }
  };

  let showCancelButton = () => {
    return !(
      analyzeMeeting.Status == MeetingStatusConstants.Completed ||
      analyzeMeeting.Status == MeetingStatusConstants.Cancelled
    );
  };

  return (
    <>
      <RatingCard
        isOpen={ showRatingModal }
        onRequestClose={handleCloseRatingModal}
      />

      <ConfirmationModal
        isOpen={ confirmationModal }
        handleCloseModal={()=>setShowConfirmationModal((prev)=>!prev)}
        handleEndMeeting={handleEndMeeting}
      />
      <CreateMeeting
        isOpen={showModal}
        onRequestClose={handleMeetingModal}
        isUpdate={true}
      />
      {analyzeMeeting ? (
        <>
          {sessionToken && zoomState == ZoomStates.PREVIEW && (
            <MeetingPreviewModal
              handleNext={() => setZoomState(ZoomStates.VIDEO)}
            />
          )}
          {sessionToken && zoomState == ZoomStates.VIDEO && (
            <MeetingSession
              handleClose={handleCloseSession}
              features={sessionFeatures}
              handleNext={() => setZoomState(ZoomStates.VIDEO)}
            />
          )}
          {!sessionToken && (
            <>
              {/* <Banner classes='rounded-lg' /> */}
              <CreatorInfoCard showTags={false}>
                <div className="flex gap-x-2 justify-end items-center">
                  {handleCallIcon()}
                  {showCancelButton() ? (
                    <div className="flex flex-col justify-center items-center">
                      <div
                        onClick={cancelMeeting}
                        className="w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#2E3036]"
                      >
                        <RxCircleBackslash className="text-[20px] ml-[1px] text-red-500" />
                      </div>
                      <div className="text-[12px]">Cancel</div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </CreatorInfoCard>
              {/* <div className="w-full rounded-lg bg-[#181818] p-5 mt-4">
                <div className="flex justify-between items-start">
                  <div className="text-lg mb-5">
                    {" "}
                    <div> {'Plan Info'} </div>{" "}
                  </div>
                </div>
              </div> */}
              <div className="w-full rounded-lg bg-[#181818] p-5 mt-4">
                <div className="flex justify-between items-start">
                  <div className="text-lg mb-5">
                    {" "}
                    <div> {analyzeMeeting?.Title} </div>{" "}
                    <div className="text-sm text-[#AAAAAA]">
                      {" "}
                      <div className="my-1">
                        {moment(analyzeMeeting?.ScheduleTime).format(
                          "dddd, MMMM D ⋅ h:mm A"
                        )}{" "}
                      </div>
                      <Badge
                        text={
                          MeetingStatusConstantsMapping[analyzeMeeting?.Status]
                        }
                      />
                      {analyzeMeeting?.Status == MeetingStatusConstants.Hold &&
                      account._id == analyzeMeeting.CreatorAccount ? (
                        <div className="text-sm font-light text-red-500">
                          Note: Top up your account balance to schedule the
                          meeting.
                        </div>
                      ) : (
                        ""
                      )}
                      {analyzeMeeting?.Status == MeetingStatusConstants.Hold &&
                      account._id == analyzeMeeting.OrganizerAccount ? (
                        <div className="text-sm font-light text-red-500">
                          Note: Meeting scheduled pending payment from user.
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mt-5 flex flex-col text-md gap-y-2">
                        <div className="flex gap-x-3">
                          <div className="text-white">Plan Type: </div>
                          <div className="">
                            {
                              TypeToChargeMapping[
                                analyzeMeeting?.PlanInfo?.Type
                              ]
                            }
                          </div>
                        </div>
                        <div className="flex gap-x-3">
                          <div className="text-white">Plan Charges: </div>
                          <div className="">
                            $
                            {parseFloat(
                              analyzeMeeting?.PlanInfo?.Amount
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                  {account?._id == analyzeMeeting.CreatorAccount && (
                    <span
                      onClick={handleMeetingModal}
                      className="p-2 bg-[#353B4B] rounded-md cursor-pointer"
                    >
                      <RiPencilFill />
                    </span>
                  )}
                </div>

                <div className="my-6">
                  <Separator />
                </div>

                <div className="grid grid-cols-3 mt-5 gap-x-4">
                  <div className="col-span-3 md:col-span-2">
                    <div className="text-md font-normal">Description</div>
                    <div className="my-3 text-sm font-light">
                      {analyzeMeeting?.Description}
                    </div>

                    <div className="my-6">
                      <Separator />
                    </div>

                    <div className="">
                      <div className="text-md font-normal">Participants</div>
                      <div className="grid grid-cols-3 gap-2 gap-y-4 my-3">
                        {analyzeMeeting?.Participants.map((participant) => (
                          <div className="col-span-3 md:col-span-1 bg-[#353B4B] rounded-lg px-4 py-2 cursor-pointer flex items-start gap-x-2 ">
                            <div className="w-[33px] h-[33px] bg-[#8254F8] rounded-full flex justify-center items-center capitalize text-xs">
                              <Avatar
                                userProfilePicture={
                                  participant?.User?.ProfilePicture
                                }
                                classes={`w-[33px] h-[33px] text-[15px]`}
                              />
                              {/* A */}
                            </div>
                            <div className="capitalize text-sm mt-[2px]">
                              <div>{participant.User.FirstName}</div>{" "}
                              {participant._id ==
                                analyzeMeeting.OrganizerAccount && (
                                <div className="text-[10px] leading-relaxed">
                                  Organizer
                                </div>
                              )}
                              {participant._id ==
                                analyzeMeeting.CreatorAccount && (
                                <div className="text-[10px] leading-relaxed">
                                  Creator
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1 h-full border-[1px] border-[#353B4B] border-solid rounded-xl overflow-hidden flex flex-col">
                    <div className="text-sm w-full bg-[#353B4B] py-2 px-3">
                      Comments
                    </div>
                    <div className="flex-grow gap-2 py-3 px-3 overflow-y-auto h-[414px] max-h-[414px] scroll-bar">
                      {comments?.length > 0
                        ? comments.map((comment) => (
                            <CommentCard
                              profilePicture={
                                comment?.User?.ProfilePicture ||
                                userProfilePicture
                              }
                              name={comment.User.FirstName}
                              date={formatTime(comment.createdAt)}
                              comment={comment.Content}
                            />
                          ))
                        : null}
                    </div>
                    <form onSubmit={handleMessage} action="">
                      <div className="flex items-center bg-[#353440]">
                        <Input
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                          placeholder={"Type"}
                          classes={`text-white py-4 text-sm`}
                        />
                        <div className="mr-2 cursor-pointer">
                          <IoSend onClick={handleMessage} />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ): <div style={{height: 'calc(100vh - 62px)'}} className="flex justify-center items-center">
          <img src={Loader} />
      </div> }
    </>
  );
};

MeetingDetailComponent.propTypes = {};

export { MeetingDetailComponent };
