import { Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"
import { Error } from "../../config/helpers/toast-helper"

export const SignUp = async (data)=>{
    try {
        return await Post(`${baseUrl}/auth/signup`, data)
    } catch (error) {
        throw error
    }
}

export const Login = async (data)=>{
    try {
        return await Post(`${baseUrl}/auth/login`, data)
    } catch (error) {
        throw error
    }
}

export const VerifyCode = async (code)=>{
    try {
        console.log(`${baseUrl}/auth/verifyCode/${code}`)
        return await Get(`${baseUrl}/auth/verifyCode/${code}`)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetAccountInfo = async ()=>{
    try {
        let {Authorization} = UserCredentials()
        console.log('Authorization: ', Authorization)
        return await Get(`${baseUrl}/auth/accountInfo`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetAccountVerification = async (data)=>{
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/auth/getVerificationCode`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const VerifyOTPCode = async (data)=>{
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/auth/verifyCode`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetSyncAccountApps = async ()=>{
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/account/accountInfo/syncApps`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}