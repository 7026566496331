import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthStore } from "../../store/auth/auth";
import { IsReferralExist } from "../../service/referral/referral";
import { useReferralStore } from "../../store/referral/referral";
import { usePlanStore } from "../../store/plans/plans";

const Verify = (props) => {
  let { isPlanSubscribed } = usePlanStore();
  let { account } = useAuthStore();

  let verifyCode = useAuthStore.getState().verifyCode();
  let { code } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    verifyUser();
  }, []);

  let verifyUser = async () => {
    let isUserVerify = await useAuthStore.getState().verifyCode(code);

    let planSubscribed = await isPlanSubscribed();
    if (!planSubscribed) {
      // need to add another component
      navigate("/plans");
      return;
    }
    if (isUserVerify) {
      navigate("/");
    } else {
      navigate("/auth/login");
    }
  };

  return <div className="text-white">Verifying User...</div>;
};

Verify.propTypes = {};

export { Verify };
