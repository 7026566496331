import React, { useState } from "react";
import PropTypes from "prop-types";
import { InterestCard } from "../InterestCard/InterestCard";
import { useInterestStore } from "../../store/interest/interest";

const Interest = (props) => {
  let { interests } = useInterestStore();
  const [state, setState] = useState();
  return (
    <div className="my-6 flex flex-col gap-5">
      {interests?.length > 0 ? interests.map((interest) => (
        <InterestCard Skill={interest.Label} key={interest.Label} />
      )):null}
    </div>
  );
};

export { Interest };
