import React, { useState, useEffect, useRef } from "react";
import DropdownImg from "../../assets/png/Dropdown.png";
import DownArrowIcon from "../../assets/svg/down.svg";
import { Link, useNavigate } from "react-router-dom";
import { ClearLocalStorage } from "../../config/helpers/local-storage-accessor-helper";
import { useAuthStore } from "../../store/auth/auth";
import { Separator } from "../Separator/Separator";
import { Avatar } from "../Avatar/Avatar";
import { TbLayersLinked } from "react-icons/tb";
import { Tooltip } from "react-tooltip";
import { Success } from "../../config/helpers/toast-helper";
import { AccountTypeConstants } from "../../config/constants/account";

function Dropdown() {
  let { user, userProfilePicture, account } = useAuthStore();
  const [isOpen, setIsOpen] = useState(false);

  let navigate = useNavigate();

  let handleNavigator = (route) => {
    if (route == "/auth/login") {
      ClearLocalStorage();
    }
    toggleDropdown();
    navigate(route);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  let getFirstName = (user) => {
    // Split the first name by spaces
    const firstNameParts = user?.FirstName.split(" ");

    // Check if the first name has multiple parts
    if (firstNameParts.length > 1) {
      // If yes, return the second part
      return firstNameParts[1];
    } else {
      // If not, return the first part
      return firstNameParts[0];
    }
  };

  const copyToClipboard = () => {
    const origin = window.location.origin;
    console.log(window.location);
    navigator.clipboard
      .writeText(`${origin}/${account._id}`)
      .then(() => {
        Success("Link Copied");
        // setCopySuccess('Copied!');
      })
      .catch((err) => {
        // setCopySuccess('Failed to copy!');
        console.error("Could not copy text: ", err);
      });
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block flex flex-row" ref={dropdownRef}>
      <div onClick={toggleDropdown} className="cursor-pointer">
        {/* <img src={DropdownImg} className="w-[32px] h-[32px] " alt="" /> */}
        <Avatar
          userProfilePicture={userProfilePicture}
          classes={"w-[35px]  h-[35px] text-[17px] text-[18px]"}
        />
      </div>

      {isOpen && (
        // this is my component I want to get a trigger as the user click outside this component
        <div className="absolute right-0 z-10 w-[300px] py-2 top-10 bg-white rounded-md shadow-sm dark:bg-gray-800">
          <div className="text-center relative">
            <div
              onClick={toggleDropdown}
              className="cursor-pointer mx-auto w-full flex justify-center items-center"
            >
              {/* <img src={DropdownImg} className="w-[70px] h-[70px] " alt="" /> */}
              <Avatar
                userProfilePicture={userProfilePicture}
                classes={"w-[70px] h-[70px] text-[30px]  text-[18px]"}
              />
            </div>
            <div className="capitalize"> {user.FirstName} </div>
            <div className="text-xs"> {user.Email} </div>
            {account.Type == AccountTypeConstants.CREATOR && (
              <div
                data-tooltip-id="invitation-link-tooltip"
                data-tooltip-content="Invitation Link"
                className="absolute right-3 top-1 cursor-pointer"
              >
                <Tooltip id="invitation-link-tooltip" />
                <TbLayersLinked onClick={copyToClipboard} />
              </div>
            )}
          </div>
          <div className="my-3">
            <Separator />
          </div>
          <ul className="relative">
            <li>
              <Link
                to={"/account/discussion"}
                className="rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
              >
                Your Discussions
              </Link>
            </li>
            <li>
              <Link
                to={"/account/meeting"}
                className="rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
              >
                Your Meetings
              </Link>
            </li>
            <li>
              <Link
                to={"/user-info"}
                className="rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
              >
                Profile Settings
              </Link>
            </li>
            <li>
              <Link
                to={"/referrals"}
                className="rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
              >
                Refer Your Friend
              </Link>
            </li>
            {/* <li>
              <Link to={'/user-customize'} className="rounded-md hover:bg-gray-100 dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200">
                Interest Settings
              </Link>
            </li> */}
            <li onClick={toggleDropdown}>
              <div
                onClick={() => handleNavigator("/auth/login")}
                className="rounded-md hover:bg-gray-100 cursor-pointer dark:hover:bg-gray-700 block px-4 py-2 text-sm text-gray-700 dark:text-gray-200"
              >
                Logout
              </div>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
