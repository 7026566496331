import { Post, baseUrl } from "."
import { UserCredentials } from "../config/helpers/local-storage-accessor-helper"

export const BecomeParticipants = async (data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Post(`${baseUrl}/participant`, data, Authorization) 
    } catch (error) {
        console.log(error.message)
    }
}