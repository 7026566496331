export const quizTypeConstant = {
  QUIZ: 1,
  COMPETITION: 2,
};

export const quizLevelModeConstant = {
  EASY: 1,
  MEDIUM: 2,
  HARD: 3,
  ADVANCE: 4,
};

export const quizLevelModeColorMapping = {
  1: "#81c784",
  2: "#ffeb3b",
  3: "#ffb74d",
  4: "#9575cd",
};

export const quizLevelModeConstantMapping = {
  1: "Easy",
  2: "Medium",
  3: "Hard",
  4: "Advance",
};

export const resourceTypeConstantMapping = {
  1: "VIDEO",
  2: "ARTICLE",
  3: "PDF",
  4: "BOOK",
  5: "PODCAST",
  6: "WEBSITE",
  7: "APP",
  8: "EXERCISE",
  9: "VISUALS",
};

export const resourceTypeColorMapping = {
  1: "#FF5722", // VIDEO - Deep Orange
  2: "#2196F3", // ARTICLE - Blue
  3: "#4CAF50", // PDF - Green
  4: "#9C27B0", // BOOK - Purple
  5: "#FFC107", // PODCAST - Amber
  6: "#009688", // WEBSITE - Teal
  7: "#F44336", // APP - Red
  8: "#FF9800", // EXERCISE - Orange
  9: "#3F51B5", // VISUALS - Indigo
};
