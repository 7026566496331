import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "../Table/Table";
import { useProblemStore } from "../../store/problem/problem";

let headers = [
  {
    label: "Status",
    cols: 1,
    id: "status",
  },
  {
    label: "Title",
    cols: 5,
    id: "title",
  },
  {
    label: "Acceptance",
    cols: 2,
    id: "acceptance",
  },
  {
    label: "Difficulty",
    cols: 2,
    id: "difficulty",
  },
  {
    label: "",
    cols: 2,
    id: "invitePeer",
  },
];
const Problems = (props) => {
  let { problems, getProblems, totalPages, getProblemsReport, problemReport } =
    useProblemStore();

  const [loading, setLoading] = useState(false);
  const [pageConfig, setPageConfig] = useState({
    Page: 1,
    Limit: 10,
    // sortBy: "ScheduleTime",
    // sortOrder: 1,
    // filteredBy: null,
    Search: "",
  });

  useEffect(() => {
    fetchProblems();
  }, [JSON.stringify(pageConfig)]);

  let fetchProblems = async () => {
    setLoading(true);
    await getProblems(pageConfig);
    await getProblemsReport();
    setLoading(false);
  };

  async function handleNextPage() {
    if (
      Math.max(Math.ceil(totalPages / pageConfig.Limit), 1) == pageConfig.Page
    ) {
      return;
    }
    let nextPage = pageConfig.Page + 1;
    setPageConfig({ ...pageConfig, Page: nextPage });
  }

  async function handlePreviousPage() {
    if (pageConfig.Page == 1) {
      return;
    }
    let previousPage = pageConfig.Page - 1;
    setPageConfig({ ...pageConfig, Page: previousPage });
  }
  return (
    <div className=" max-w-[1280px] mx-auto grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 px-2 md:px-10 gap-5">
      <div className="col-span-3 py-3 px-5 bg-[#252836] rounded-lg mt-4">
        <div className="text-3xl font-bold my-3">Byte Battle</div>
        <Table
          currentPage={pageConfig.Page}
          headers={headers}
          loading={loading}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          totalPages={Math.ceil(totalPages / pageConfig.Limit) || 1}
          footer={() => {}}
          meetings={problems}
        />
      </div>
      <div className="col-span-1 ">
        {" "}
        <div className="bg-[#252836] shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105">
          <h2 className="text-xl font-light mb-4 flex justify-between items-center">
            <span className="text-green-500">{"Easy"}</span>
            <span className="text-white font-light text-3xl">
              {problemReport?.TotalEasyProblems
                ? (
                    (problemReport?.AccountEasySolutions /
                      problemReport?.TotalEasyProblems) *
                    100
                  ).toFixed(1)
                : 0}
              %
            </span>
          </h2>
          <div className="text-gray-300 flex justify-between items-center">
            <span>Sessions:</span>
            <span>
              {problemReport?.AccountEasySolutions}/
              {problemReport?.TotalEasyProblems}
            </span>
          </div>
        </div>
        <div className="bg-[#252836] shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105">
          <h2 className="text-xl font-light mb-4 flex justify-between items-center">
            <span className="text-yellow-500">{"Medium"}</span>
            <span className="text-white font-light text-3xl">
              {problemReport?.TotalMediumProblems
                ? (
                    (problemReport?.AccountMediumSolutions /
                      problemReport?.TotalMediumProblems) *
                    100
                  ).toFixed(1)
                : 0}
              %
            </span>
          </h2>
          <div className="text-gray-300 flex justify-between items-center">
            <span>Sessions:</span>
            <span>
              {problemReport?.AccountMediumSolutions}/
              {problemReport?.TotalMediumProblems}
            </span>
          </div>
        </div>
        {problemReport?.TotalHardProblems ? (
          <div className="bg-[#252836] shadow-lg rounded-lg p-6 m-4 transition-transform transform hover:scale-105">
            <h2 className="text-xl font-light mb-4 flex justify-between items-end">
              <span className="text-red-500">{"Hard"}</span>
              <span className="text-white font-light text-3xl">
                {problemReport?.TotalHardProblems
                  ? (
                      (problemReport?.AccountHardSolutions /
                        problemReport?.TotalHardProblems) *
                      100
                    ).toFixed(1)
                  : 0}
                %
              </span>
            </h2>
            <div className="text-gray-300 flex justify-between items-center">
              <span>Sessions:</span>
              <span>
                {problemReport?.AccountHardSolutions}/
                {problemReport?.TotalHardProblems}
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

Problems.propTypes = {};

export { Problems };
