import { useState } from 'react';
import { useLocation, useNavigate, useNavigation } from 'react-router-dom';

const tabs = [
  {
    title: "Sign In",
    route: 'login'
  },
  {
    title: "Sign Up",
    route: 'signUp',
  },
];

const Tabs = (props) => {
  
  let navigate = useNavigate()
  let location = useLocation()
  
  let handleNavigation = (route) => {
    navigate(route)
  }
  console.log(location.pathname.split('/'))
  
  const activeTab = location.pathname.split('/')[2];

  return (
    <div className="border-none mt-10">
      <nav className="flex bg-[#353440] rounded-lg p-1 w-fit m-auto" aria-label="Tabs">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`${
              activeTab === tab.route
                ? 'border-white text-white bg-[#703BF7]   '
                : 'border-transparent text-gray-500 hover:text-white hover:border-gray-900'
            } h-[2rem] w-[9rem] inline-flex items-center border-none rounded-lg font-medium text-sm m-0 justify-center items-center text-sm font-semibold px-7`}
            aria-current={activeTab === index ? 'page' : undefined}
            onClick={() => handleNavigation(tab.route)}
          >
            {tab.title}
          </button>
        ))}
      </nav>
      {/* <div className="mt-4">
        {tabs[activeTab].content}
      </div> */}
    </div>
  );
};

export default Tabs;