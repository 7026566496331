import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { QuizCategory as QuizCategoryComponent  } from '../../components/QuizCategory/QuizCategory';
const QuizCategory = props => {
    return <div>
        <QuizCategoryComponent />
    </div>;
};

QuizCategory.propTypes = {};

export { QuizCategory };