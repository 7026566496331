import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoSchoolSharp } from "react-icons/io5";
import * as moment from "moment";
import { MdModeEditOutline } from "react-icons/md";
import { CreateEducationModal } from "../Education/CreateEducationModal";
import { useEducationStore } from "../../store/education/education";

const EducationCard = ({
  Data,
  School,
  Degree,
  FieldOfStudy,
  StartDate,
  EndDate,
  Description,
  ShowUpdate = false,
}) => {

  let { education, selectedForUpdate } = useEducationStore()

  const [showEducationModal, setShowEducationModal] = useState(false);


  function handleUpdateCard(){
    selectedForUpdate(Data)
    setShowEducationModal(true)
    
  }

  return (
    <>
      <CreateEducationModal
        onRequestClose={() => {
          selectedForUpdate(null)
          setShowEducationModal(false);
        }}
        show={showEducationModal}
      />
      <div className="flex items-start gap-3">
        <div>
          <div className="text-[22px] md:text-[40px] bg-[#7A5CEE] p-2 md:p-5 rounded-full">
            <IoSchoolSharp />{" "}
          </div>
        </div>
        <div className="mt-3 w-full">
          <div className="text-white font-normal text-lg text-left flex justify-between items-start">
            <div className="">{School}</div>
            {ShowUpdate ? <div
              onClick={handleUpdateCard}
              className="p-1 cursor-pointer bg-[#353440] flex justify-center items-center rounded"
            >
              <MdModeEditOutline />
            </div> : <></>}
          </div>
          <div className="text-[13px] font-normal font-light text-[#AAAAAA] text-left">
            {Degree}
          </div>
          {StartDate ? <div className="flex items-center text-xs">
            <div>{moment(StartDate).format("MMM, YYYY")}</div>
            <div>- {EndDate ? moment(EndDate).format("MMM, YYYY") : 'Present'}</div>
          </div>: null}
          {Description && (
            <div className="text-sm mt-4 text-left">{Description}</div>
          )}
        </div>
      </div>
      <div className="w-full bg-[#353B4B] h-[1px]"></div>
    </>
  );
};

export { EducationCard };
