import React, { useState } from "react";
import PropTypes from "prop-types";
import { CurrentPlanCard } from "./CurrentPlanCard";
import { PaymentMethods } from "./PaymentMethods";
import { MeetingComponent } from "../MeetingComponent/MeetingComponent";
import { InvoiceListing } from "./InvoiceListing";

const Billing = (props) => {
  return (
    <div className="py-5 px-2 flex flex-col gap-y-5">
      <CurrentPlanCard />
      <PaymentMethods />
      <InvoiceListing />
    </div>
  );
};

Billing.propTypes = {};

export { Billing };
