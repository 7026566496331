import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/png/icon.png";
import SearchBar from "../../components/SearchBar/SearchBar";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useInterestStore } from "../../store/interest/interest";
import { useAuthStore } from "../../store/auth/auth";
import { useSkillStore } from "../../store/skill/skills";


const UserCustomize = () => {
  // NAVIGATION
  let { platformSkills, skills, updatedInterests, getInterest, interests, updateSkills } = useInterestStore()
  let { getNestedSkills } = useSkillStore()
  let { account, getAccountInfo } = useAuthStore()


  const [searchBar, setSearchBar] = useState("");
  const [searchText, setSearchText] = useState('')
  const [selected, setSelected] = useState({})
  let [intrustedTopics, setIntrustedTopics] = useState([])
  
  const navigate = useNavigate();

  useEffect(() => {
    getAccountInfo()
    fetchPlatformSkills()
  }, [])

  useEffect(() => {
    if(account?._id){
      handleInterests()
    }
  }, [account?._id])

  let handleInterests = async  () => {
    let userInterests = await getInterest(account._id)
    let resp = {}
    userInterests.forEach(data => {
      resp[data._id]= true
    })
    setSelected(resp)
    updateSkills(-1, userInterests.map(data => ({name: data.Label, id: data._id})))
  }

  let timeoutId = useRef(null);

  const onChange = async (e) => {
    setSearchBar(e.target.value);
    clearTimeout(timeoutId.current);
    if(e.target.value==''){
      // await fetchPlatformSkills()
    }

    // Set a new timeout to trigger the API call after 2 seconds
    timeoutId.current = setTimeout(async () => {
      if (e.target.value !== searchText) {
        // Call your API here using the value
        await platformSkills(e.target.value)
      }
      setSearchText(e.target.value);
    }, 1000);
  };

  let fetchPlatformSkills = async () => { 
    await platformSkills()
  }

  const selectedOptions = async (topic, index) => {
    // Check if the topic is already selected
    if (selected[topic]) {
      // If selected, remove it from the selected topics
      const updatedSelected = { ...selected };
      delete updatedSelected[topic];
      setSelected(updatedSelected);
    } else {
      if(searchText.length>0){
        let currentTopic = skills.find(data => data.id == topic)
        console.log(selected)
        setSelected({ ...selected, [topic]: true });
        updateSkills(-1, [currentTopic])
      } else {
        console.log('topic: ', topic)
        let newSkills = await getNestedSkills(topic)
        console.log('newSkills: ', newSkills)
          // If not selected, add it to the selected topics
        setSelected({ ...selected, [topic]: true });
        updateSkills(index, newSkills.map(data=>({name: data.Label, id: data._id})))
      }
    }
};

  let isTopicIntrusted = (topic) => {
    // setSelected({...selected, [topic]: false})
    return selected[topic] == true ? true : false
  }

  let handleContinue = async () => {
    let payload = {
      Skills: Object.keys(selected).map(data => data)
    }
    await updatedInterests(account._id, payload)
    if(interests.length == 0){
      navigate("/user-info")
    }else{
      navigate("/")
    }
  }
  return (
    <div className="p-8 flex justify-center items-center text-center flex-col text-white">
      <div className="flex gap-5 flex-row items-center">
        <img src={Logo} alt="" className="w-[61px] h-[61px]" />
        <h2 class="text-3xl font-[600] ">Discoursefy</h2>
      </div>

      <div className="py-10">
        <h2 className="font-[600] text-[30px]">Pick Your Interests!</h2>
        <p className="text-[#9C9AA5] py-3 text-[24px] font-[400]">
          Select your interests for users to know you.
        </p>
      </div>

        <div className="search-bar m-auto flex justify-center w-full">
          <SearchBar
            placeholder="Javascript, php, gif, etc..."
            iconPosition="left"
            data={searchBar}
            onChange={onChange}
          />
        </div>
        <div className="flex flex-wrap md:w-[40%] gap-2 mt-10 justify-center">
          {skills.map((item, index) => (
            <div className="relative">
              <p
                onClick={() => selectedOptions(item.id, index)}
                className={`w-max justify-center mapped-items p-1.5 rounded-lg text-[14px] items-center ${
                  (isTopicIntrusted(item.id) ? "bg-[#8b71d1]" : "bg-[#353440]")
                } hover:bg-[#8b71d1] cursor-pointer  `}
              >
                {item.name}
              </p>
            </div>
          ))}
      </div>
      <div className="btn mt-10">
        {Object.keys(selected).length < 5 ? 
        <div className="text-sm text-[#AAAAAA]">Discover tailored content! Select a minimum 5 topics of interest.</div>
        : <Button
        buttonText="Continue"
        fullWidth={true}
        type="outlined"
        onClick={handleContinue}
      />
        }
      </div>
    </div>
  );
};

export default UserCustomize;
