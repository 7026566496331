import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { Input } from "../Input/Input";
import { CommentCard } from "../CommentCard/CommentCard";
import { Badge } from "../Badge/Badge";

import { RiPencilFill } from "react-icons/ri";
import { CreateDiscussion } from "../CreateDiscussion/CreateDiscussion";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { useParams } from "react-router-dom";
import * as moment from "moment";
import { DiscussionStatusConstantsMapping } from "../../config/constants/discussion";
import { useAuthStore } from "../../store/auth/auth";
import { CreatorInfoCard } from "../CreatorInfoCard/CreatorInfoCard";
import { useMeetingStore } from "../../store/meeting/meeting";
import { Button } from "../Button/Button";
import { useParticipantsStore } from "../../store/participants/participants";
import { ParticipantEntityTypeConstants } from "../../config/constants/participants";
import { MeetingPreviewModal } from "../MeetingPreviewModal/MeetingPreviewModal";
import { MeetingSession } from "../MeetingSession/MeetingSession";
import { RxCircleBackslash } from "react-icons/rx";
import { PiBroadcastBold } from "react-icons/pi";
import { HiOutlineVideoCamera } from "react-icons/hi";
import IconLoaderIcon from "../../assets/gif/IconButtonLoaderSm.gif"; 


let ZoomStates = {
  PREVIEW: 1,
  VIDEO: 2
}

const DiscussionDetailComponent = (props) => {
  let { getDiscussionInfo, analyzedDiscussions, startDiscussion, endDiscussion, cancelDiscussion } =
    useDiscussionStore();
    let { becomeParticipant, participantInfo, sessionToken, resetSessionToken } = useParticipantsStore()
  let { fetchCreatorInfo } = useMeetingStore();
  let { account } = useAuthStore();

  const [state, setState] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cancelLoader, setCancelLoader] = useState(false)
  const [zoomState, setZoomState] = useState(ZoomStates.PREVIEW);
  const [sessionFeatures, setSessionFeatures] = useState(["video", "audio", "users", "chat", "share"])

  let { id } = useParams();

  useEffect(() => {
    fetchDiscussion();
  }, [id]);

  let fetchDiscussion = async () => {
    let Discussion = await getDiscussionInfo(id);
    await fetchCreatorInfo(Discussion.Account);
  };

  function handleDiscussionModal() {
    setShowModal((prev) => !prev);
  }

  let isDiscussionOwner = () => {
    if (account._id === analyzedDiscussions.Account) return true;
    return false;
  };

  let handleShowButton = () => {
    if(analyzedDiscussions?.Status == 3){
      return false
    }
    else if (analyzedDiscussions?.Account === account?._id) {
      return true;
    } else {
      if (analyzedDiscussions?.Status == 2) {
        return true;
      }
      return false;
    }
  };

  let handleButtonText = () => {
    if (analyzedDiscussions?.Account === account?._id) {
      if (analyzedDiscussions?.Status == 2) {
        return "Rejoin";
      }
      return "Start";
    } else {
      return "Join";
    }
  };

  async function updateDiscussionStatus() {
    setLoading(true);
    if (analyzedDiscussions?.Status == 1) {

      await startDiscussion(id);
      let participantPayload = {
        EntityId: id,
        EntityType: ParticipantEntityTypeConstants.DISCUSSION
      }
      if(analyzedDiscussions.Account === account?._id) {
        setSessionFeatures((prev)=>[...prev, "share", "settings" ])
      }
      let resp = await becomeParticipant(participantPayload)
      
      return
    
    } else if (analyzedDiscussions?.Status == 2) {
    
      if (analyzedDiscussions?.Account === account?._id) {
        // just redirect user to meeting screen no need to hit participant api.
        setSessionFeatures((prev)=>[...prev, "share", "settings" ])
      }
      // join meeting to participants
      let participantPayload = {
        EntityId: id,
        EntityType: ParticipantEntityTypeConstants.DISCUSSION
      }
      let resp = await becomeParticipant(participantPayload)

    }
    setLoading(false);
  }

  let handleCloseSession = async () => {
    if(analyzedDiscussions.Account === account?._id) {
      await endDiscussion(id)
    }
    await fetchDiscussion()
    setZoomState(ZoomStates.PREVIEW)
    resetSessionToken("")
    window.location.reload()
  }

  let handleCancelDiscussion = async () => {
    setCancelLoader(true)
    await cancelDiscussion(id);
    setCancelLoader(false)
  }

  let handleCallIcon = () => {
    
    if (
      analyzedDiscussions &&
      analyzedDiscussions.Status == 1
    ) {
      if (account?._id == analyzedDiscussions.Account) {
        // if() meeting.ScheduleTime < 5min then analyze meeting true else false
        // !isMeetingApproaching(analyzeMeeting.ScheduleTime)
        return true ? (
          <div className="flex flex-col justify-center items-center">
            <div
              onClick={updateDiscussionStatus}
              className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full ${
                loading ? "bg-[#2E3036]" : "bg-[#8254F8]"
              }`}
            >
              {!loading ? (
                <PiBroadcastBold className="text-[20px] ml-[1px]" />
              ) : (
                <img width={30} height={30} src={IconLoaderIcon} />
              )}
            </div>
            <div className="text-[12px]">{handleButtonText()}</div>
          </div>
        ) : (
          <></>
        );
      }
      return <></>;
    }

    if (
      analyzedDiscussions &&
      analyzedDiscussions.Status == 2
    ) {
      return (
        <div className="flex flex-col justify-center items-center">
          <div
            onClick={updateDiscussionStatus}
            className={`w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#8254F8] ${
              loading && "bg-[#2E3036]"
            }`}
          >
            {!loading ? (
              <HiOutlineVideoCamera className="text-[25px] ml-[1px]" />
              ) : (
              <img width={30} height={30} src={IconLoaderIcon} />
            )}
          </div>
          <div className="text-[12px]">{handleButtonText()}</div>
        </div>
      );
    }
  };

  // async function fetchDiscussion(){
  //   await getDiscussionInfo(id)
  // }
  return (
    <div id="meeting-detail-container">
      <CreateDiscussion
        isOpen={showModal}
        onRequestClose={handleDiscussionModal}
      />
      {(sessionToken && zoomState == ZoomStates.PREVIEW) && <MeetingPreviewModal handleNext={()=>setZoomState(ZoomStates.VIDEO)} />}
      {(sessionToken && zoomState == ZoomStates.VIDEO) && <MeetingSession handleClose={handleCloseSession} features={sessionFeatures} handleNext={()=>setZoomState(ZoomStates.VIDEO)} />}
      {!sessionToken && <>
      <CreatorInfoCard showTags={false}>
      <div className="flex gap-x-2 justify-end items-center">
        {handleShowButton() ? (
          <>
          {handleCallIcon()}
          {((analyzedDiscussions?.Status == 1 || analyzedDiscussions?.Status == 2) && analyzedDiscussions.Account == account?._id) && (
              <div className="flex flex-col justify-center items-center">
                <div
                  onClick={handleCancelDiscussion}
                  className="w-[40px] h-[40px] cursor-pointer flex justify-center items-center rounded-full bg-[#2E3036]"
                >
                  {!cancelLoader ? (
                    <RxCircleBackslash className="text-[20px] ml-[1px] text-red-500" />
                  ) : (
                    <img width={30} height={30} src={IconLoaderIcon} />
                  )}
                </div>
                <div className="text-[12px]">Cancel</div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
        </div>
      </CreatorInfoCard>
      <div className="w-full rounded-lg bg-[#181818] p-5">
        <div className="flex justify-between items-start">
          <div className="text-lg mb-5">
            {" "}
            <div> {analyzedDiscussions && analyzedDiscussions.Title} </div>{" "}
            <div className="text-sm text-[#AAAAAA]">
              {" "}
              {analyzedDiscussions?.ScheduleTime && (
                <div className="my-1">
                  {moment(analyzedDiscussions?.ScheduleTime).format(
                    "dddd, MMMM D ⋅ h:mm A"
                  )}
                </div>
              )}
              {analyzedDiscussions?.Status && (
                <Badge
                  text={
                    DiscussionStatusConstantsMapping[analyzedDiscussions.Status]
                  }
                />
              )}
            </div>{" "}
          </div>
          {analyzedDiscussions && isDiscussionOwner() && (
            <span
              onClick={handleDiscussionModal}
              className="p-2 bg-[#353B4B] rounded-md cursor-pointer"
            >
              {" "}
              <RiPencilFill />{" "}
            </span>
          )}
        </div>

        <div className="my-6">
          <Separator />
        </div>

        <div className=" mt-5 gap-x-4">
          <div className="">
            <div className="text-md font-normal">Description</div>
            {analyzedDiscussions?.Description && (
              <div className="my-3 text-sm font-light">
                {analyzedDiscussions.Description}
              </div>
            )}

            <div className="my-6">
              <Separator />
            </div>

            {analyzedDiscussions?.Skills.length > 0 && (
              <div className="">
                <div className="text-md font-normal">Tags</div>
                <div className="flex items center flex-wrap gap-2 ">
                  {analyzedDiscussions?.Skills.map((skill) => (
                    <div className="text-sm font-light rounded-full py-1 px-4 mt-3 cursor-pointer bg-[#353B4B] flex items-center gap-2">
                      #{skill.Label.toLowerCase()}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      </>}
    </div>
  );
};

DiscussionDetailComponent.propTypes = {};

export { DiscussionDetailComponent };
