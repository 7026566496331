import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

let style = { background: `linear-gradient(135deg, #f7df1e, #f39c12)`, color: "#2c3e50" }

const CategoryCard = ({info}) => {
    const [shine, setShine] = useState(false);

    let navigate = useNavigate()

    const handleMouseEnter = () => {
        if (!shine) {
            setShine(true);
        }
    };

    const handleMouseOut = () => {
        if (shine) {
            setShine(false);
        }
    };

    let handleNavigation = () => {
        navigate(`/quiz/${info._id}`)
    }

    return (
        <div
            style={{ background: info?.Styles?.background?.replace(/`/g, ''), color: info?.Styles?.color }}
            onClick={handleNavigation}
            className={`w-full rounded-xl cursor-pointer h-[240px] hover:scale-105 relative flex justify-center items-center shadow-lg transform transition-transform duration-300 ease-out ${shine ? 'shine-effect' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseOut={handleMouseOut}
        >
            {/* Main Content */}
            <div className="font-bold mb-[45px] text-[30px] drop-shadow-md">{info?.Title}</div>

            {/* Bottom Info Section */}
            <div className="absolute bottom-0 left-0 bg-black bg-opacity-60 font-semibold text-sm h-[55px] w-full flex justify-between items-center px-3 rounded-b-xl">
                <div className="flex flex-col items-start text-white">
                <span>{info.Category}</span>
                <span className="text-xs font-normal">{info?.TotalQuizzes} levels</span>
                </div>

                {/* Start Button */}
                <button className="relative bg-gradient-to-r from-[#6A00F4] via-[#9C66FF] to-[#6A00F4] text-white font-semibold py-1 px-3 rounded-full overflow-hidden shadow-md hover:shadow-lg transform hover:scale-105 transition-transform duration-300 ease-out">
                <span className="relative">Let's start</span>
                <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-white to-transparent opacity-25 shine-effect"></div>
                </button>
            </div>
            </div>
    );
};

export { CategoryCard };