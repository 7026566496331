import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoPerson } from "react-icons/io5";
import { ChargesToTypeMapping, DurationToChargesMapping } from "../../config/constants/charges";
import { FaStar } from "react-icons/fa";
import { MdRocketLaunch } from "react-icons/md";

const PackageCard = ({name, price, isActive, onClick, duration}) => {
  const [state, setState] = useState();
  
  function handleDuration(){
    if(duration == ChargesToTypeMapping.hourlyHustle){
        return <IoPerson />
    }
    else if(duration == ChargesToTypeMapping.swiftSession){
        return <FaStar />
    }
    else if(duration == ChargesToTypeMapping.epicEncounter){
        return <MdRocketLaunch />
    }
  }
  return (
    <div onClick={()=>onClick(duration)} className={`h-[140px] cursor-pointer w-[140px] rounded-lg border-[1px] border-solid ${!isActive ? 'border-[#353B4B]':'border-[#8254F8]'} p-2 flex flex-col justify-end gap-y-1`}>
    <div style={{...(isActive && {color:'#8254F8'})}} className="p-2 bg-[#353B4B] w-fit rounded-md text-[18px] md:text-[25px]">{handleDuration()}</div>
      <div className="text-sm font-semibold">{name}</div>
      <div className="flex items-end">
        <div className="text-xs mb-1">$</div>
        <div className="text-2xl">{price}</div>
        <div className="text-2xl">/</div>
        <div className="text-xs mb-1">{DurationToChargesMapping[duration]} min</div>
      </div>
    </div>
  );
};

PackageCard.propTypes = {};



const PlatformPackageCard = ({amount, isActive, onClick, name}) => {
  const [state, setState] = useState();
  return (
    <div onClick={ onClick } className={`h-[170px] cursor-pointer w-[95px] md:w-[140px] rounded-lg border-[1px] border-solid ${!isActive ? 'border-[#353B4B]':'border-[#8254F8]'} p-2 flex flex-col justify-end gap-y-1`}>
    {/*  */}
    <div className="flex justify-between items-center mt-0">
      <div className="font-semibold">{name}</div>
      {isActive && <div className="w-[20px] h-[20px] bg-[#8254F8] rounded-full"></div>}
    </div>
    <div className="flex justify-center items-center flex-grow">
      <div className="text-[60px] flex items-end"> <div className="text-[40px] mb-2">$</div><div className=""> {amount} </div> </div>
    </div>
    </div>
  );
};


export { PackageCard, PlatformPackageCard };


