import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import {
  FaRegFaceFrown,
  FaRegFaceFrownOpen,
  FaRegFaceGrin,
  FaRegFaceGrinBeam,
  FaRegFaceGrinHearts,
} from "react-icons/fa6";
import { useRatingStore } from "../../store/rating/rating";
import { RatingTypeConstants } from "../../config/constants/rating";
import { useParams } from "react-router-dom";
import { useMeetingStore } from "../../store/meeting/meeting";
import { Button } from "../Button/Button";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    background: "#1F1D2B",
    border: "none",
    width: "450px",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const customMobileStyles = {
  content: {
    top: "50%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    transform: "translate(0%, -50%)",
    background: "#1F1D2B",
    border: "none",
    width: "calc(100% - 72px)",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const ConfirmationModal = ({ isOpen, handleCloseModal , handleEndMeeting }) => {

  let [loading, setLoading] = useState(false)

  let handleEndSession = async () => {
    setLoading(true)
    await handleEndMeeting()
    handleCloseModal()
    setLoading(false)
  }

  function getScreenWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  let getScreenStyle = () => {
    if(getScreenWidth()>768){
      return customStyles
    }else{
      return customMobileStyles
    }
  } 

  return (
    <ReactModal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white">
        <div className="text-lg font-normal">Are You Sure?</div>
        <div className="my-3 flex flex-col gap-y-3">
          <div className="text-sm">Should we end this meeting now?</div>
          <div className="flex justify-end flex-wrap gap-x-3">
            <Button classes='text-sm md:text-md' buttonText="End This Meeting" onClick={handleEndSession} isDisabled={loading} />
            <Button classes='text-sm md:text-md' buttonText="I will rejoin" isActive={true} onClick={handleCloseModal} />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

ConfirmationModal.propTypes = {};

export { ConfirmationModal };
