import { create } from "zustand";
import { SaveQuestionAnswers } from "../../service/questionAnswers/questionAnswers";
import {
  GetActivityRecord,
  GetQuizRanking,
  GetStreakCount,
  SaveQuizReport,
} from "../../service/quizAnswers/quizAnswers";

export const useQuizReportStore = create((set, get) => ({
  QuizReport: [],
  quizRanking: [],
  streak: null,
  activityRecord: [],

  async saveQuizReport(body) {
    try {
      let Answer = await SaveQuizReport(body);
      // set(()=>({questions: questions}))
      return Answer.Success;
    } catch (error) {
      console.log(error);
    }
  },

  async getStreakCount() {
    try {
      let Streak = await GetStreakCount();
      set((state) => ({ streak: { ...Streak } }));
      return Streak;
    } catch (error) {
      console.log(error);
    }
  },

  async getActivityRecord() {
    try {
      let activityRecord = await GetActivityRecord();

      set((state) => ({ activityRecord: [...activityRecord] }));
    } catch (error) {
      console.log(error);
    }
  },
}));
