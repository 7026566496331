import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "../Table/Table";
import { Badge } from "../Badge/Badge";
import { Button } from "../Button/Button";
import { CreateDiscussion } from "../CreateDiscussion/CreateDiscussion";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { discussionFilters } from "../../config/constants/discussion";
import { Input } from "../Input/Input";

export const MeetingCell = ({ title, desc }) => {
  let handleDescription = (desc) => {
    if (desc.length < 60) return desc;
    return desc.slice(0, 60) + "...";
  };
  return (
    <div>
      <div className="flex gap-2">
        {/* <div className="w-[240px] h-[130px] bg-[#80BCBD]"></div> */}
        <div className="text-sm text-white">
          <div className="font-semibold capitalize">{title}</div>{" "}
          <div className="text-xs text-[#AAAAAA]">
            {handleDescription(desc)}
          </div>
        </div>
      </div>
    </div>
  );
};

const DiscussionComponent = (props) => {
  let {
    getAccountDiscussion,
    discussions,
    fetchingDiscussions,
    newDiscussions,
    totalItems,
  } = useDiscussionStore();

  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  // need to configure upcoming functionality from BE
  const [pageConfig, setPageConfig] = useState({
    currentPage: 1,
    limit: 10,
    sortBy: "ScheduleTime",
    sortOrder: 1,
    filteredBy: null,
    searchText: "",
    Upcoming: true,
  });
  // const [filteredBy, setFilteredBy] = useState(1)

  useEffect(() => {
    fetchAccountDiscussions();
  }, [newDiscussions, JSON.stringify(pageConfig)]);

  function toggleModal() {
    setShowModal((prev) => !prev);
  }

  async function fetchAccountDiscussions() {
    await getAccountDiscussion(pageConfig);
  }
  async function handleNextPage() {
    if (
      Math.max(Math.ceil(totalItems / pageConfig.limit), 1) ==
      pageConfig.currentPage
    ) {
      return;
    }
    let nextPage = pageConfig.currentPage + 1;
    setPageConfig({ ...pageConfig, currentPage: nextPage });
  }

  async function handlePreviousPage() {
    if (pageConfig.currentPage == 1) {
      return;
    }
    let previousPage = pageConfig.currentPage - 1;
    setPageConfig({ ...pageConfig, currentPage: previousPage });
  }

  async function handleFilter(value) {
    setPageConfig({
      ...pageConfig,
      filteredBy: value,
      currentPage: 1,
      searchText: searchText,
    });
  }

  let timeoutId = useRef(null);

  const onChange = async (e) => {
    setSearchText(e.target.value);
    clearTimeout(timeoutId.current);
    if (e.target.value == "") {
      await getAccountDiscussion({ ...pageConfig });
    }

    // Set a new timeout to trigger the API call after 2 seconds
    timeoutId.current = setTimeout(async () => {
      if (e.target.value !== searchText) {
        await getAccountDiscussion({
          ...pageConfig,
          currentPage: 1,
          searchText: e.target.value,
        });
      }
      setSearchText(e.target.value);
    }, 1000);
  };

  let headers = [
    {
      label: "Meetings",
      cols: 6,
      id: "meetings",
    },
    {
      label: "Status",
      cols: 2,
      id: "status",
    },
    {
      label: "Schedule Date",
      cols: 2,
      id: "scheduleDate",
    },
    {
      label: "Organizer",
      cols: 2,
      id: "organizer",
    },
  ];

  return (
    <>
      <CreateDiscussion isOpen={showModal} onRequestClose={toggleModal} />
      <div className="w-[100%] md:w-full rounded-lg bg-[#181818] py-5 px-2 md:p-5">
        <div className="flex justify-between items-center">
          <div className="text-lg"> Discussions </div>
          <div className="">
            <Button
              onClick={toggleModal}
              buttonText={"Create"}
              isActive={true}
              classes={"py-1 px-4 text-xs"}
            />
          </div>
        </div>
        <div className="w-[80%] md:w-[410px] my-3">
          <Input
            classes={"text-sm"}
            onChange={onChange}
            value={searchText}
            placeholder={"Search with title"}
          />
        </div>
        <div className="flex justify-between flex-col md:flex-row">
          <div className="my-3 flex items-center gap-2 md:flex-wrap overflow-x-auto">
            {discussionFilters.map((filter) => (
              <Button
                onClick={() => handleFilter(filter.value)}
                buttonText={filter.Title}
                isActive={pageConfig.filteredBy == filter.value}
                classes={"text-xs py-[5px] rounded-md"}
              />
            ))}
          </div>
          <div className="">
            <Button
              onClick={() =>
                setPageConfig((prev) => ({ ...prev, Upcoming: !prev.Upcoming }))
              }
              buttonText={"Upcoming"}
              isActive={pageConfig.Upcoming}
              classes={"text-xs py-[5px] rounded-md"}
            />
          </div>
        </div>
        <div className="overflow-auto w-[100%]">
          <Table
            headers={headers}
            meetings={discussions}
            currentPage={pageConfig.currentPage}
            totalPages={Math.ceil(totalItems / pageConfig.limit) || 1}
            loading={fetchingDiscussions}
            onNextPage={handleNextPage}
            onPreviousPage={handlePreviousPage}
          />
        </div>
      </div>
    </>
  );
};

DiscussionComponent.propTypes = {};

export { DiscussionComponent };
