import { baseUrl, Get } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const GetProblemById = async (problemId) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/problems/${problemId}`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetProblems = async (pageConfig) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/problems`, Authorization, pageConfig);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetProblemsReport = async (pageConfig) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/problems/reports`, Authorization, pageConfig);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
