import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { IoMdClose } from "react-icons/io";
import { Separator } from "../Separator/Separator";
import { Input } from "../Input/Input";
import DatePicker from "react-datepicker";
import { Button } from "../Button/Button";
import { useEducationStore } from "../../store/education/education";
import { useAuthStore } from "../../store/auth/auth";
import { Error } from "../../config/helpers/toast-helper";

let customStyles =  {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "35%",
    height: "80%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};
const customMobileStyles = {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "85%",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const CreateEducationModal = ({ show, onRequestClose }) => {
  let { account, getAccountInfo } = useAuthStore();
  let { createEducation, getCreatorEducation, educationCardGoingToUpdate, updateEducation } = useEducationStore();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [description, setDescription] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getAccountInfo();
    if(educationCardGoingToUpdate){
      setSchool(educationCardGoingToUpdate.School)
      setDegree(educationCardGoingToUpdate.Degree)
      setDescription(educationCardGoingToUpdate.Description)
      setFieldOfStudy(educationCardGoingToUpdate.FieldOfStudy)
      setStartDate(educationCardGoingToUpdate.StartDate ? new Date(educationCardGoingToUpdate.StartDate) : '')
      setEndDate(educationCardGoingToUpdate.EndDate ? new Date(educationCardGoingToUpdate.EndDate) : '')
    }else{
      handleResetValues()
    }
  }, [JSON.stringify(educationCardGoingToUpdate)]);

  let handleResetValues = () => {
    setStartDate(new Date())
    setEndDate(new Date())
    setSchool("")
    setDegree("")
    setDescription("")
    setFieldOfStudy("")
  }

  let handleSubmit = async () => {
    setLoading(true)
    if (!school.length || !degree.length) {
      Error("school and degree are required fields");
      setLoading(false)
      return;
    }
    let data = {
      AccountId: account._id,
      School: school,
      Degree: degree,
      Description: description,
      FieldOfStudy: fieldOfStudy,
      StartDate: startDate ? startDate.toISOString():'',
      EndDate: endDate ? endDate.toISOString():'',
    };

    try {
      if(!educationCardGoingToUpdate){
        await createEducation(account._id, data);
      }else{
        await updateEducation(educationCardGoingToUpdate._id, data);
      }

      await getCreatorEducation(account._id);
      setLoading(false)
      handleResetValues()
      onRequestClose();
    } catch (error) {
        setLoading(false)
        console.log(error);
    }

  };

  
  function getScreenWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  let getScreenStyle = () => {
    if(getScreenWidth()>768){
      return customStyles
    }else{
      return customMobileStyles
    }
  } 

  return (
    <ReactModal
      isOpen={show}
      onRequestClose={()=>{ onRequestClose(); handleResetValues(); }}
      shouldCloseOnOverlayClick={false}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="text-white">
        <div className="header flex justify-between items-center">
          <div className="">Add Education</div>
          <div onClick={()=>{onRequestClose(); handleResetValues();}} className="cursor-pointer">
            <IoMdClose />
          </div>
        </div>
        <div className="my-3">
          <Separator />
        </div>

        <div className="flex flex-col gap-y-5">
          <Input
            label={"School"}
            required={true}
            type="text"
            placeholder={"Ex: Oxford University"}
            onChange={(e) => {
              setSchool(e.target.value);
            }}
            value={school}
          />
          <Input
            required={true}
            label={"Degree"}
            type="text"
            placeholder={"Ex: Bachelor's"}
            onChange={(e) => {
              setDegree(e.target.value);
            }}
            value={degree}
          />
          <Input
            label={"Field Of Study"}
            type="text"
            placeholder={"Ex: Computer Science"}
            onChange={(e) => {
              setFieldOfStudy(e.target.value);
            }}
            value={fieldOfStudy}
          />
          <div className="flex items-center justify-between flex-col md:flex-row md:justify-between">
            <div className="date-section">
              <div className="text-sm mb-2"> Start Date </div>
              <DatePicker
                className="bg-[#353440] rounded text-sm text-white py-1 px-3 border-none active:border-none"
                selected={startDate}
                showMonthYearPicker
                dateFormat="MMM, yyyy"
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="date-section">
              <div className="text-sm mb-2"> End Date </div>
              <DatePicker
                className="bg-[#353440] rounded text-sm text-white py-1 px-3 border-none active:border-none"
                selected={endDate}
                showMonthYearPicker
                dateFormat="MMM, yyyy"
                onChange={(date) => setEndDate(date)}
              />
            </div>
          </div>
          {/* <Input label={'Start Date'} type='date' placeholder={"Ex: 2020-01-01"} onChange={()=>{}} /> */}
          {/* <Input label={'End Date'} type='date' placeholder={"Ex: 2020-01-01"} onChange={()=>{}} /> */}
          <Input
            label={"Description"}
            type="textarea"
            placeholder={""}
            classes={"text-white"}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            value={description}
          />
          <div className="flex justify-end items-center">
            <Button
              onClick={handleSubmit}
              isDisabled={loading}
              buttonText={educationCardGoingToUpdate ?'Update': "Save"}
              isActive={true}
            />
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

CreateEducationModal.propTypes = {};

export { CreateEducationModal };
