import { create } from "zustand";
import { GetExpertise, GetInterest, PlatformSkills, UpdateExpertise, UpdateInterest } from "../../service/interest/interest";

export const useInterestStore = create((set, get) => ({
    interests: [],
    skills: [],
    archivedSkills: [],
    expertise: [],
    isTyping: false,

    getInterest: async (accountId) => {
        try {
            let interest = await GetInterest(accountId)   
            set({interests: interest.Skills})
            return interest.Skills
        } catch (error) {
            console.log(error)
        }
    },

    getExpertise: async (accountId) => {
        try {
            let expertise = await GetExpertise(accountId)
            set({expertise: expertise.Skills})
            return expertise.Skills
        } catch (error) {
            console.log(error)
        }
    },

    platformSkills: async (skill='') => {
        try {
            console.log('this fn hit  targeted')
            const state = get(); // Access the current state
            let Skills = await PlatformSkills(skill)
            if((skill.length && !state.isTyping)){
                set((state) => ({archivedSkills: state.skills, isTyping: true}))
            }
            if((!skill.length && state.isTyping)){
                set((state) => ({skills: state.archivedSkills, isTyping: false, archivedSkills: []}))
                return 
            }
            console.log(Skills)
            console.log(state.skills.length, Skills.length)
            set((state) => ({skills: Skills.map(data => ({name: data.Label, id: data._id}))}))
            return Skills.map(data => ({name: data.Label, id: data._id}))
        } catch (error) {
            console.log(error)
        }
    },

    updatedInterests: async (accountId, data) => {
        try {
            let interest = await UpdateInterest(accountId, data)   
            return interest
            // set({interests: interest.Skills})
        } catch (error) {
            console.log(error)
        }
    },

    updatedExpertise: async (accountId, data) => {
        try {
            let interest = await UpdateExpertise(accountId, data)   
            return interest
            // set({interests: interest.Skills})
        } catch (error) {
            console.log(error)
        }
    },
    updateSkills: (index, subArray) => {
        set((state) => {
            const currentSkills = state.archivedSkills.length ? state.archivedSkills : state.skills;
            console.log('okay: ',currentSkills)
            const newSkills = [
                ...currentSkills.slice(0, index + 1),
                ...subArray,
                ...currentSkills.slice(index + 1)
            ];
            console.log('new skills: ', newSkills)
    
            // Check for duplicate IDs
            const uniqueSkillsById = new Map();
            for (const skill of newSkills) {
                if (!uniqueSkillsById.has(skill.id)) {
                    uniqueSkillsById.set(skill.id, skill);
                }
            }
            console.log('Array.from(uniqueSkillsById.values()): ',Array.from(uniqueSkillsById.values()))
            if(state.archivedSkills.length){
                console.log(Array.from(uniqueSkillsById.values()))
                return { archivedSkills: Array.from(uniqueSkillsById.values()) };
            }
    
            return { skills: Array.from(uniqueSkillsById.values()) };
        });
    }
}))