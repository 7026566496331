import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { DiscussionDetailComponent } from '../../components/DiscussionDetailComponent/DiscussionDetailComponent';

const DiscussionDetail = props => {
const [state, setState] = useState();
    return <div>
        <DiscussionDetailComponent />
    </div>;
};

DiscussionDetail.propTypes = {};

export { DiscussionDetail };