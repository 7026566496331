import React, { useState } from "react";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { BiCircleThreeQuarter } from "react-icons/bi";
import { VscPlayCircle } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { FaUserFriends } from "react-icons/fa";
import { PeerInvitationModal } from "../PeerInvitation/PeerInvitationModal";
import { usePeerSessionStore } from "../../store/peerSession/peerSession";
import { useAuthStore } from "../../store/auth/auth";
import ButtonLoader from "../../assets/gif/IconButtonLoaderSm.gif";

const ListItemStatus = ({ status }) => {
  return (
    <div>
      {status == 2 ? (
        <div
          data-tooltip-id="problem-listitem-complete-status"
          data-tooltip-content="Solved"
          className="text-white text-xs rounded-full py-1 px-3"
        >
          <Tooltip id="problem-listitem-complete-status" />
          <IoCheckmarkDoneCircleOutline className="text-green-500 text-2xl" />
        </div>
      ) : status == 1 ? (
        <div
          data-tooltip-id="problem-listitem-complete-attempted"
          data-tooltip-content="Attempted"
          className="text-white text-xs rounded-full py-1 px-3"
        >
          <Tooltip id="problem-listitem-complete-attempted" />
          <BiCircleThreeQuarter className="text-yellow-500 text-2xl" />
        </div>
      ) : (
        <div className="text-white text-xs rounded-full py-1 px-3">
          <VscPlayCircle className="text-purple-500 text-2xl" />
        </div>
      )}
    </div>
  );
};

const ListItemTitle = ({ title, id }) => {
  return (
    <Link
      to={`${id}`}
      className="text-white text-sm h-[32px] flex items-center transition-colors duration-300 ease-in-out hover:text-purple-500"
    >
      {title}
    </Link>
  );
};

const ListItemAcceptance = ({ acceptance }) => {
  return (
    <div className="text-white text-sm h-[32px] flex items-center cursor-auto">
      <div className="w-fit cursor-text">
        {acceptance ? parseFloat(acceptance).toFixed(1) : "0"}%
      </div>
    </div>
  );
};

const ListItemDifficulty = ({ difficulty }) => {
  return (
    <div className="text-white text-sm h-[32px] flex items-center cursor-auto">
      <div className="w-fit cursor-text">
        {difficulty === "EASY" ? (
          <div className="text-green-500"> Easy </div>
        ) : difficulty === "MEDIUM" ? (
          <div className="text-yellow-500"> Medium </div>
        ) : (
          <div className="text-red-500"> Hard </div>
        )}
      </div>
    </div>
  );
};

const ListItemInvitePeer = ({ id }) => {
  let { account } = useAuthStore();
  let { createSession, getSessionInfo } = usePeerSessionStore();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedId, seSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");

  let handleCreateSession = async () => {
    seSelectedId(id);
    // Handle the logic for creating a session
    let payload = {
      CreatorId: account._id,
      Participants: [account._id],
      Problems: [id],
    };
    let Session = await createSession(payload);
    setSessionId(Session._id);
    setIsOpen(true);
    // await getSessionInfo(Session._id);
  };
  return (
    <div className="text-white text-sm h-[32px] flex items-center cursor-auto">
      <PeerInvitationModal
        isOpen={isOpen}
        id={sessionId}
        onRequestClose={() => setIsOpen(false)}
      />
      <div
        className="w-fit cursor-text"
        data-tooltip-id="problem-listitem-invite-peer"
        data-tooltip-content="Invite Peer to This Problem"
      >
        <Tooltip id="problem-listitem-invite-peer" />
        <div className="text-white text-xs rounded-full py-1 px-3">
          {selectedId == id && loading ? (
            <img width={20} src={ButtonLoader} />
          ) : (
            <FaUserFriends
              onClick={handleCreateSession}
              className="text-purple-500 text-2xl cursor-pointer"
            />
          )}
        </div>
        {/* <FaUserFriends /> */}
      </div>
    </div>
  );
};

ListItemStatus.propTypes = {};
ListItemTitle.propTypes = {};
ListItemAcceptance.propTypes = {};
ListItemDifficulty.propTypes = {};
ListItemInvitePeer.propTypes = {};

export {
  ListItemStatus,
  ListItemTitle,
  ListItemAcceptance,
  ListItemDifficulty,
  ListItemInvitePeer,
};
