import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import VideoIcon from "../../assets/svg/Video.svg";
import SearchBar from "../SearchBar/SearchBar";
import Dropdown from "../Dropdown/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IsAuthenticated } from "../../config/helpers/local-storage-accessor-helper";
import { useAuthStore } from "../../store/auth/auth";
import { IoWallet } from "react-icons/io5";
import { CreateEducationModal } from "../Education/CreateEducationModal";
import { PaymentCard } from "../PaymentCard/PaymentCard";
import { usePlanStore } from "../../store/plans/plans";

const Header = (props) => {
  const { getAccountInfo, planInfo } = useAuthStore();
  let { isPlanSubscribed } = usePlanStore();

  const [text, setText] = useState("");
  const [headerLeft, setHeaderLeft] = useState(48);
  const [showPaymentModal, setShowPaymentModal] = useState();

  const { pathname } = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      let isUserCredsExist = IsAuthenticated();
      if (!isUserCredsExist) {
        localStorage.setItem("redirect", pathname);
        navigate("/auth/login");
        return;
      }

      let planSubscribed = await isPlanSubscribed();
      if (!planSubscribed) {
        navigate("/plans");
        return;
      }

      getAccountInfo();

      let isRedirectExist = localStorage.getItem("redirect");
      if (isRedirectExist) {
        navigate(isRedirectExist);
        localStorage.removeItem("redirect");
      }

      window.scrollTo(0, 0);
      if (!pathname.includes("setting")) {
        setHeaderLeft(() => headerLeft);
      } else {
        setHeaderLeft(312);
        // setHeaderLeft(50)
      }
    })();
  }, [pathname]);

  const onChange = (e) => setText(e.target.value);
  return (
    <div
      className={`flex items-center justify-end px-5 py-2 h-min ${
        !pathname.includes("account") ? "header-width" : "setting-header-width"
      } fixed top-[0] right-0 z-50 bg-[#1F1D2B]`}
    >
      <PaymentCard
        show={showPaymentModal}
        onRequestClose={() => setShowPaymentModal(false)}
      />
      {/* Future version */}
      {/* <div className="search-bar hidden md:block">
    <SearchBar data={text} onChange={onChange} placeholder={"Search"} />
  </div> */}
      <div className="right-side flex flex-row items-center justify-between gap-x-3 w-max">
        {planInfo?.PlanCategory == 0 ? (
          <div
            onClick={() => navigate("/account/billing/plans")}
            className="hidden md:flex premium-button items-center cursor-pointer justify-center text-white text-sm font-semibold uppercase py-2 px-4 rounded-lg 
                 transition-transform duration-300 ease-in-out 
                 bg-gradient-to-r from-[#3E1E5F] to-[#1E1E2F] 
                 hover:scale-102 hover:bg-gradient-to-l 
                 shadow-lg backdrop-blur-lg bg-opacity-30 
                 border border-transparent hover:border-[#3E1E5F]
                 transform hover:animate-pulse"
          >
            Get Premium
          </div>
        ) : null}

        <div onClick={() => setShowPaymentModal(true)} className="">
          <IoWallet className={`text-[24px] text-[#4B4B53] cursor-pointer`} />
        </div>
        <div className="">
          <Dropdown />
        </div>
      </div>
    </div>
  );
};

export { Header };
