import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CreatorAvatar } from "../CreatorAvatar/CreatorAvatar";
import { BiSolidBell } from "react-icons/bi";
import { Modal } from "../Modal/Modal";
import * as moment from "moment";
import { useNotifyStore } from "../../store/notify/notify";

const DiscussionCard = ({
  FirstName,
  LastName,
  Description,
  Theme,
  Notification,
  CoverPhoto,
  Title,
  Skills,
  Date,
  Discussion,
}) => {
  let { getEntityNotifier } = useNotifyStore();

  const [state, setState] = useState();
  const [showModal, setShowModal] = useState(false);

  let handleDiscussionTitle = () => {
    if (Title.length > 70) return Title.slice(0, 70) + "...";
    return Title;
  };

  let handleCloseModal = () => {
    setShowModal(false);
  };

  let OpenDiscussionModal = async () => {
    setShowModal(true);
    await getEntityNotifier(Discussion._id);
  };

  let handleTags = (skill) =>{
    return skill.split('').filter(d=>(d !== ' ')).join('').toString()
  }

  return (
    <div id="discussion-card" className="">
      <Modal
        discussion={Discussion}
        isOpen={showModal}
        onRequestClose={handleCloseModal}
      />
      <div
        onClick={OpenDiscussionModal}
        className="rounded-xl cursor-pointer overflow-hidden w-[100%] min-w-[275px] h-[400px] bg-[#252836] "
      >
        <CreatorAvatar
          Theme={Theme}
          CoverPhoto={CoverPhoto}
          FirstName={Skills[0]}
          CreatorCard={false}
          Creator={Discussion.User}
        />
        <div className="relative h-[200px]">
          <div className="name-container m-3 my-2 mt-3 text-white">
            <div className="mb-5 text-[#B7B9D2]">
              <div className="font-semibold font-[400] text-[14px] text-white capitalize">
                {FirstName} {LastName && LastName}
              </div>
              <div className="flex items-center flex-wrap text-[11px]">
                {Skills.slice(0, 4).map((skill, i) => (
                  <div>#{handleTags(skill)} &nbsp;</div>
                ))}
              </div>
            </div>

            <div className="text-md h-[50px] font-[500] text-[17px] mb-5">
              {handleDiscussionTitle()}
            </div>
          </div>
          <div className="mx-3 text-white text-[12px]">
            {moment(Date).format("DD MMM, h:mm A")}
          </div>
          <div className="absolute bottom-0 w-full">
          {!Notification && (<div className="w-[90%] mt-1 mx-auto py-1 rounded-lg text-[14px] flex justify-center items-center bg-[#7E5DEC]">
              Get Notify &nbsp; <BiSolidBell />
            </div>)}
          </div>
        </div>
      </div>
    </div>
  );
};

DiscussionCard.propTypes = {};

export { DiscussionCard };
