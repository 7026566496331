import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useAuthStore } from '../../store/auth/auth';
import { FaRegUser } from 'react-icons/fa6';

const Avatar = ({userProfilePicture, classes}) => {
    const [state, setState] = useState();
    return <div>
        {
            userProfilePicture ? <div>
                <div className={`cursor-pointer mx-auto w-full flex justify-center items-center  rounded-full overflow-hidden ${classes}`}>
                    <img style={{objectFit:'cover'}} src={userProfilePicture} className={` ${classes}`} alt="" />
                </div>
            </div>:
            <div
            className={`flex justify-center items-center rounded-full bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border text-3xl ${classes}`}
          >
            <FaRegUser className={``} />
          </div>
        }    
    </div>;
};

Avatar.propTypes = {};

export { Avatar };