import { Delete, Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const FetchQuizCategories = async() => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quizCategory/get` ,Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}