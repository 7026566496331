import { create } from "zustand";
import {
  CreateSession,
  GetSessionInfo,
  IsSessionExpired,
} from "../../service/peerSession/peerSession";

export const usePeerSessionStore = create((set, get) => ({
  currentSession: null,
  fetchingSessionInfo: false,

  async isSessionExpired(id) {
    let resp = await IsSessionExpired(id);
    return resp;
  },

  async getSessionInfo(id) {
    set(() => ({ currentSession: null, fetchingSessionInfo: true }));
    let SessionInfo = await GetSessionInfo(id);
    set(() => ({ currentSession: SessionInfo, fetchingSessionInfo: false }));
    console.log(SessionInfo);
    return SessionInfo;
  },

  async createSession(payload) {
    console.log(payload);
    let SessionInfo = await CreateSession(payload);
    return SessionInfo;
  },
}));
