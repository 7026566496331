import React, { useState } from "react";
import PropTypes from "prop-types";
import { Avatar } from "../Avatar/Avatar";

const CommentCard = ({ profilePicture, name, date, comment }) => {
  const [state, setState] = useState();
  return (
    <div className="flex items-start gap-x-2 my-3">
  <div className="w-[40px] h-[40px] rounded-full text-white flex justify-center items-center bg-[#8254F8]">
    <Avatar userProfilePicture={profilePicture} classes={`w-[40px] h-[40px] text-[20px]`} />
  </div>
  <div className="flex flex-col flex-1 text-xs text-white">
    <div className="flex items-start justify-between flex-col">
      <div className="truncate capitalize">{name}</div>
      <div className="text-[#AAAAAA] text-xs">{date}</div>
    </div>
    <div className="text-xs mt-1">{comment}</div>
  </div>
</div>
  );
};

CommentCard.propTypes = {};

export { CommentCard };
