import { baseUrl, Get, Post } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const CreateSolution = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/solution/create`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetProblemsSolutions = async (problemId) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/solution/get/${problemId}`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
