import React, { useState } from "react";
import PropTypes from "prop-types";
import { AccountSettingComponent } from "../../components/AccountSetting/AccountSetting";

const AccountSetting = (props) => {
  const [state, setState] = useState();
  return (
    <div>
      <AccountSettingComponent />
    </div>
  );
};

AccountSetting.propTypes = {};

export { AccountSetting };
