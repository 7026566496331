import React, { useState } from "react";
import PropTypes from "prop-types";
import { DiscussionComponent } from "../../components/DiscussionComponent/DiscussionComponent";

const Discussion = (props) => {
  const [state, setState] = useState();
  return <div>
    <DiscussionComponent />
  </div>;
};

Discussion.propTypes = {};

export { Discussion };
