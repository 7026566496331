import { create } from "zustand";
import {
  CreateSolution,
  GetProblemsSolutions,
} from "../../service/solution/solution";

export const useSolutionStore = create((set, get) => ({
  currentSolution: null,
  solutionResponse: null,

  resetSolutionResponse: () => {
    set((state) => ({ solutionResponse: null }));
  },

  async createSolution(data) {
    try {
      let Solution = await CreateSolution(data);
      set((state) => ({
        solutionResponse: {
          ...data,
          comparison: Solution.performanceComparison,
        },
      }));
      return Solution;
    } catch (error) {
      console.log(error);
    }
  },

  async getProblemsSolutions(problemId) {
    try {
      let Solution = await GetProblemsSolutions(problemId);
      set((state) => ({ currentSolution: Solution }));
      return Solution;
    } catch (error) {
      console.log(error.message);
    }
  },
}));
