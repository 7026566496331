import { create } from "zustand";
import {
  CreateReferral,
  IsReferralExist,
} from "../../service/referral/referral";

export const useReferralStore = create((set, get) => ({
  isAccountHasReferral: false,

  async createReferral(payload) {
    try {
      let referralCreated = await CreateReferral(payload);
      //   console.log("referral response: ", isReferralExist);
      //   set(() => ({ isAccountHasReferral: referralCreated }));
      return referralCreated;
    } catch (error) {
      console.log(error);
    }
  },

  async isReferralExist() {
    let isReferralExist = await IsReferralExist();
    set(() => ({ isAccountHasReferral: isReferralExist }));
    return isReferralExist;
  },
}));
