import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { BsFolderX } from "react-icons/bs";
import { SlMagnifier } from 'react-icons/sl';

const EmptyData = props => {
    return <div className='my-6 flex flex-col justify-center items-center'>
        <SlMagnifier className='text-[80px]' />
        <h3>No Record Found</h3>
    </div>;
};

EmptyData.propTypes = {};

export { EmptyData };