import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdModeEditOutline, MdRocketLaunch } from "react-icons/md";
import * as moment from "moment";
import { useExperienceStore } from "../../store/experience/experience";
import { CreateExperienceModal } from "../Experience/CreateExperienceModal";

const ExperienceCard = ({Data, Organization, Designation, EmploymentType, Location, JobDescription, StartDate, EndDate, IsCurrentJob, ShowUpdate = false}) => {

  let { updateExperienceCard } = useExperienceStore()

  const [showExperienceModal, setShowExperienceModal] = useState(false);

  function handleUpdateCard(){
    updateExperienceCard(Data)
    setShowExperienceModal(true)
  }

  return (
    <>
    <CreateExperienceModal onRequestClose={() => {
          updateExperienceCard(null)
          setShowExperienceModal(false);
        }}
        show={showExperienceModal} />
      <div className="flex items-start gap-3">
        <div>
          <div className="text-[22px] md:text-[40px] bg-[#7A5CEE] p-2 md:p-5 rounded-full">
            <MdRocketLaunch />{" "}
          </div>
        </div>
        <div className="mt-3 w-full">
          <div className="text-white font-normal w-full text-lg flex justify-between items-start ">
              <div className="">
                {Designation}
              </div>
              {ShowUpdate ? <div
                onClick={handleUpdateCard}
                className="p-1 cursor-pointer bg-[#353440] flex justify-center items-center rounded"
              >
                 <MdModeEditOutline />
              </div>:<></>}
          </div>
          <div className="text-[13px] font-normal font-light text-[#AAAAAA]">
            {Organization} {EmploymentType && ' . '+EmploymentType}
          </div>
          <div className="flex items-center text-xs">
            <div>{moment(StartDate).format('MMM, YYYY')}</div> &nbsp;
            <div> - {!IsCurrentJob ? moment(EndDate).format('MMM, YYYY') : 'Present'}</div>
          </div>
          <div className="flex items-center text-xs">
            <div>{Location}</div>
          </div>
          {JobDescription && (
            <div className="text-sm mt-4">
              {JobDescription}
            </div>
          )}
        </div>
      </div>
      <div className="w-full bg-[#353B4B] h-[1px]"></div>
    </>
  );
};

export { ExperienceCard };
