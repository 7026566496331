import React, { useState } from "react";
import PropTypes from "prop-types";
import { ExperienceCard } from "../ExperienceCard/ExperienceCard";
import { useExperienceStore } from "../../store/experience/experience";
import { EmptyData } from "../EmptyData/EmptyData";

const Experience = (props) => {
  let { experiences } = useExperienceStore();

  const [state, setState] = useState();
  return (
    <div className="my-6 flex flex-col gap-5">
      {experiences?.length > 0 ? experiences.map(experience=>
        <ExperienceCard
          Data={experience}
          Organization={experience?.Organization}
          Designation={experience?.Designation}
          Location={experience?.Location}
          StartDate={experience?.StartDate}
          EndDate={experience?.StartDate}
          IsCurrentJob={experience?.IsCurrentJob}
          JobDescription={experience?.JobDescription}
          ShowUpdate={false}
        />
      ) : (
        <div className="">
          <EmptyData />
        </div>
      )}
    </div>
  );
};

export { Experience };
