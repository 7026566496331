import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Outlet } from "react-router";
import { Header } from "../../components/Header/Header";

const Layout = (props) => {
  return (
    <div className='text-white'>
      <Sidebar />
      <div className="ml-[62px] min-[2400px]:m-auto ">
        {/* NavBar */}
        <Header />
        <section className="section relative mt-[60px]">
            <Outlet />
        </section>
      </div>
    </div>
  );
};

export { Layout };
