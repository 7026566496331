import { create } from 'zustand';
import {
  ListItemAcceptance,
  ListItemDifficulty,
  ListItemInvitePeer,
  ListItemStatus,
  ListItemTitle,
} from '../../components/Problems/ProblemListItem';
import {
  GetProblemById,
  GetProblems,
  GetProblemsReport,
} from '../../service/problem/problem';

export const useProblemStore = create((set, get) => ({
  problems: [],
  currentProblem: null,
  languages: [],
  totalPages: 1,
  problemReport: null,
  numberOfInputs: 1,

  async getProblemById(id) {
    try {
      let Problem = await GetProblemById(id);

      set((state) => ({
        currentProblem: Problem,
        numberOfInputs:
          Problem?.Parameters.length || Problem.TestCases[0].Input.length,
      }));
      return Problem;
    } catch (error) {
      console.log(error);
    }
  },

  async getProblems(pageConfig) {
    try {
      let Problem = await GetProblems(pageConfig);
      set((state) => ({
        problems: Problem.data.map((item) => ({
          status: <ListItemStatus status={item.Status} />,
          title: <ListItemTitle title={item.Title} id={item._id} />,
          acceptance: <ListItemAcceptance acceptance={item.AcceptanceRate} />,
          difficulty: <ListItemDifficulty difficulty={item.Difficulty} />,
          invitePeer: <ListItemInvitePeer id={item._id} />,
        })),
        totalPages: Problem.TotalCount,
      }));
      return Problem;
    } catch (error) {
      console.log(error);
    }
  },

  setLanguageOptions(languageOptions) {
    set({ languages: languageOptions });
  },

  async getProblemsReport() {
    try {
      let Report = await GetProblemsReport();
      set({ problemReport: Report });
    } catch (error) {
      console.log(error.message);
    }
  },
}));
