import { create } from "zustand";
import { CreateExperience, GetCreatorExperience, UpdateExperience } from "../../service/experience/experience";

export const useExperienceStore = create((set, get) => ({
    experiences: [],
    experienceCardGoingToUpdate: null,

    getCreatorExperience: async (accountId) => {
        try {
            let experiences = await GetCreatorExperience(accountId)   
            set((state)=>({experiences}))
        } catch (error) {
            console.log(error)
        }
    },

    createExperience: async (data) => {
        try {
            let experience = await CreateExperience(data)
            await get().getCreatorExperience(data.AccountId)
            return {experiences: experience}
        } catch (error) {
            console.log(error)
        }
    },

    updateExperienceCard: (data) => {
        set({experienceCardGoingToUpdate: data})
    },

    updateExperience: async (id, data) => {
        try {
            let education = await UpdateExperience(id, data)
            set({ experienceCardGoingToUpdate: null })
        } catch (error) {
            console.log(error)            
        }
    }
}))