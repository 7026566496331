import React, {useState} from 'react';
import { FaStar } from "react-icons/fa6";
import { CreatorProfile } from '../CreatorProfile/CreatorProfile';
import { GiCheckeredDiamond, GiGoldBar } from "react-icons/gi";
import { CreatorCategoryConstants } from '../../config/constants/account';

const CreatorAvatar = ({...props }) => {

let handleCreatorName = () =>{
    if(props.FirstName && props.LastName && (props.FirstName+' '+props.LastName).length<10) return props.FirstName+' '+props.LastName
    if(props.FirstName && props.FirstName.length<10) return props.FirstName
    if(props.FirstName && props.LastName) return props.FirstName.charAt(0)+'.'+props.LastName.charAt(0)
    return props.FirstName.charAt(0)
}
    return (
        <div className="w-full h-[180px] bg-blue relative">
          {props.CoverPhoto && <img className="object-cover h-full w-full" src={props.CoverPhoto} alt="" />}
          {!props.CoverPhoto && <div style={{background: props.Theme}} className='h-full w-full flex flex-col justify-center items-center capitalize'>
            <span className='text-[30px] font-bold text-[#f6f6f6]'>{handleCreatorName()}</span>
            <span className='text-sm'>Discoursefy</span>
          </div>}
          <div className="">
            <CreatorProfile profilePicture={props?.Creator?.ProfilePicture} creatorName={handleCreatorName()} containerWidth={60} padding={5} />
          </div>
          {/* <div className="h-[60px] w-[60px] border-[1px] border-solid border-white p-[5px] rounded-full  absolute -bottom-7 right-2">
            <img className="rounded-full" src={CreatorProfilePicture} />
            <div className="relative w-full h-full -mt-[40px]">
              <div className="absolute bottom-1 -right-1 rounded-full h-[18px] w-[18px] bg-[#252836] flex justify-center items-center">
                <div style={{background: '#08A0F7'}} className="rounded-full flex justify-center items-center w-[15px] h-[15px] bg-blue-900">
                  <img className='mt-[1px]' src={Check} />
                </div>
              </div>
            </div>
          </div> */}
          {(props?.CreatorCard) && <div className='absolute top-1 right-2 bg-[#24273099] w-[30px] h-[30px]  text-sm rounded-full flex justify-center items-center gap-1'>
            {props.Creator.CreatorCategory === CreatorCategoryConstants.GOLD && <GiGoldBar className='text-xl text-[#FFD700]' />}
            {props.Creator.CreatorCategory === CreatorCategoryConstants.PLATINUM && <GiGoldBar className='text-xl text-[#e5e4e2]' />}
            {props.Creator.CreatorCategory === CreatorCategoryConstants.DIAMOND && <GiCheckeredDiamond className='text-xl text-white' />}
          </div>}
        </div>
    );
};

export { CreatorAvatar };