import { baseUrl, Get, Post } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const GetSessionInfo = async (id) => {
  try {
    return await Get(`${baseUrl}/peerSession/get/${id}`);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateSession = async (payload) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/peerSession/create`, payload, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const IsSessionExpired = async (id) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/peerSession/isExpired/${id}`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
