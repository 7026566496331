import { create } from "zustand";
import { CreateComment, FetchComments } from "../../service/comment/comment";

export const useCommentStore = create((set, get) => ({
    comments: [],

    async createComment(data){
        try {
            let payload = {
                User: {
                    FirstName: data.FirstName,
                },
                Content: data.Content
            }
            delete data.FirstName
            let comments = await CreateComment(data)
            set((state)=>({comments: [...state.comments, payload]}))
            return true
        } catch (error) {
            console.log(error)
        }
    },

    async fetchComments(data){
        try {
            let comments = await FetchComments(data)
            set({ comments: comments})
            return comments
        } catch (error) {
            console.log(error)
        }
    }
}))
