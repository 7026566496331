import React, { useEffect, useState } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';

const OutputWindow = ({
  outputDetails = [],
  processing,
  code,
  handleCompile,
  testCases,
  isPeerSession = false,
  handleSwitchRole = () => {},
  outputWidowTab = 0,
  totalInputs,
  setCustomTestCases,
  setOutputWidowTab,
}) => {
  const [testCase, setTestCase] = useState(0);

  useEffect(() => {
    if (testCases.length > 0) {
      setTestCase(0);
    }
  }, [testCases.length]);

  function normalizeOutput(output) {
    // Remove brackets, extra spaces, and newline characters
    if (outputDetails[testCase]?.language?.id === 54) {
      return output.trim().replace(/\s+/g, ',');
    }
    return output
      .replace(/[\[\]]/g, '')
      .trim()
      .replace(/\s+/g, '');
  }

  const [testCaseInputs, setTestCaseInputs] = useState([
    Array(totalInputs).fill(''), // Initialize with one test case
  ]);

  const [selectedTestCase, setSelectedTestCase] = useState(0); // Track selected test case outputWidowTab

  // Handle input change for each test case
  const handleInputChange = (testCaseIndex, inputIndex, value) => {
    const newTestCaseInputs = [...testCaseInputs];
    newTestCaseInputs[testCaseIndex][inputIndex] = value;
    setTestCaseInputs(newTestCaseInputs);
    generateTestCases();
  };

  // Add new test case with the same structure as the first one
  const addTestCase = () => {
    let currentTestCaseLength = testCaseInputs.length;
    // Array(totalInputs).fill('')
    let newObject = [...testCaseInputs[testCaseInputs.length - 1]];
    setTestCaseInputs([...testCaseInputs, newObject]);
    setSelectedTestCase(currentTestCaseLength);
  };

  // Remove a specific test case
  const removeTestCase = (index) => {
    const newTestCaseInputs = testCaseInputs.filter((_, i) => i !== index);
    setTestCaseInputs(newTestCaseInputs);
    if (selectedTestCase >= newTestCaseInputs.length) {
      setSelectedTestCase(newTestCaseInputs.length - 1); // Adjust the selected outputWidowTab if necessary
    }
  };

  // Select a test case by index
  const selectTestCase = (index) => {
    setSelectedTestCase(index);
  };

  // Generate test case objects from input values
  const generateTestCases = () => {
    const result = testCaseInputs.map((inputs) => {
      let testCase = {};
      inputs.forEach((input, index) => {
        testCase[`input${index + 1}`] = input;
      });
      return testCase;
    });
    setCustomTestCases(result);
  };

  function isEqual(value1, value2) {
    // Normalize both values
    const normalized1 = normalizeOutput(value1);
    const normalized2 = normalizeOutput(value2);

    return normalized1 === normalized2;
  }

  const getOutput = () => {
    if (!outputDetails.length) {
      return;
    }
    let statusId = outputDetails[testCase].status?.id;
    let outputDetail = outputDetails[testCase];
    // setSelectedTestCase(outputDetail);

    if (statusId === 6) {
      // compilation error
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {atob(outputDetail?.compile_output)}
        </pre>
      );
    } else if (statusId === 3 || statusId === 4) {
      return (
        <pre className="px-3 py-1 font-normal text-xs text-green-500 nanum-gothic-coding-regular">
          {atob(outputDetail.stdout) !== null ? (
            <div className="my-3">
              <div>
                <div className="text-sm">Input</div>{' '}
                <div className="text-xl  flex px-4 py-3 items-center font-semibold bg-[#2A3C4D]">
                  {testCases[testCase]?.map((el) => el).join(';')}
                </div>
              </div>
              <div className="mt-3">
                <div className="text-sm">Output</div>{' '}
                <div className="text-xl  flex px-4 py-3 items-center font-semibold bg-[#2A3C4D]">
                  {atob(outputDetail?.stdout)}
                </div>
              </div>
              <div className="mt-3">
                <div className="text-sm">Expected</div>{' '}
                <div className="text-xl flex px-4 py-3 items-center font-semibold bg-[#2A3C4D]">
                  {atob(outputDetail?.expected_output)}
                </div>
              </div>
            </div>
          ) : null}
        </pre>
      );
    } else if (statusId === 5) {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {`Time Limit Exceeded`}
        </pre>
      );
    } else {
      return (
        <pre className="px-2 py-1 font-normal text-xs text-red-500">
          {atob(outputDetail?.stderr)}
        </pre>
      );
    }
  };
  return (
    <>
      <div className="w-full h-[100%]  bg-[#1B2B34] rounded-md  font-normal text-sm overflow-hidden">
        <div className="flex justify-between items-center font-semibold bg-gray-700 py-2 px-3">
          <div className="flex items-center space-x-4">
            <span
              onClick={() => setOutputWidowTab(0)}
              className={`cursor-pointer ${
                outputWidowTab === 0
                  ? 'text-white font-semibold'
                  : 'text-gray-500'
              }`}
            >
              TestCases
            </span>
            <span
              onClick={() => setOutputWidowTab(1)}
              className={`cursor-pointer ${
                outputWidowTab === 1
                  ? 'text-white font-semibold'
                  : 'text-gray-500'
              }`}
            >
              Output
            </span>
          </div>
          <span className="flex gap-x-2">
            {isPeerSession ? (
              <button
                onClick={handleSwitchRole}
                disabled={!code || processing}
                className={`
                "mt-4 border-2 text-black border-white z-10 rounded-md px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                ${!code || processing ? 'opacity-50' : ''}
              `}
                data-tooltip-id="problem-output-window-switch-role-button"
                data-tooltip-content="Switch Your Role"
              >
                <Tooltip id="problem-output-window-switch-role-button" />
                {/* {processing ? "Processing..." : "Run"} */}
                Switch Role
              </button>
            ) : (
              <></>
            )}

            <button
              onClick={() => handleCompile(false)}
              disabled={!code || processing}
              className={`
                "mt-4 border-2 text-black border-white z-10 rounded-md px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                ${!code || processing ? 'opacity-50' : ''}
              `}
              data-tooltip-id="problem-output-window-test-button"
              data-tooltip-content="Consider to remove log/print before run"
            >
              <Tooltip id="problem-output-window-test-button" />
              {processing ? 'Processing...' : 'Run'}
            </button>
            <button
              onClick={() => handleCompile(true)}
              disabled={!code || processing}
              className={`
                "mt-4 border-2 text-black border-white z-10 rounded-md px-4 py-1 hover:shadow transition duration-200 bg-white flex-shrink-0",
                ${!code || processing ? 'opacity-50' : ''}
              `}
              data-tooltip-id="problem-output-window-submit-button"
              data-tooltip-content="Consider to remove log/print before submit your code"
            >
              <Tooltip id="problem-output-window-submit-button" />
              {'Submit'}
            </button>
          </span>
        </div>
        {outputWidowTab === 1 ? (
          <div className="h-[calc(32vh-56px)] scroll-bar overflow-y-auto">
            {outputDetails?.length && outputDetails[testCase]?.status && (
              <div className="flex gap-x-2 items-end m-3 nanum-gothic-coding-regular">
                <div
                  className={`text-xl font-semibold ${
                    outputDetails[testCase]?.status.id == 3
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                >
                  {outputDetails[testCase]?.status.description}
                </div>
                <div className="text-gray-200 font-light">
                  Runtime: {outputDetails[testCase]?.time} ms
                </div>
                <div className="text-gray-200 font-light">
                  Memory: {outputDetails[testCase]?.memory} KB
                </div>
              </div>
            )}
            <div className="flex gap-x-2 m-3">
              {outputDetails?.length &&
                outputDetails.map((_, index) => (
                  <span
                    onClick={() => setTestCase(index)}
                    className={`font-semibold px-3 py-2 cursor-pointer rounded-lg bg-gray-700 ${
                      testCase == index ? 'shadow-xl scale-110' : ''
                    }`}
                  >
                    {' '}
                    Case {index + 1}{' '}
                  </span>
                ))}
            </div>
            {outputDetails ? <>{getOutput()}</> : null}
            {/* Okay wait a bit. */}
          </div>
        ) : (
          <div className="h-[calc(32vh-56px)] scroll-bar overflow-y-auto">
            {/* Tabs for selecting test cases */}
            <div className="flex items-center gap-x-2 m-3">
              {testCaseInputs.map((_, index) => (
                <span
                  key={index}
                  onClick={() => selectTestCase(index)}
                  className={`font-semibold text-sm px-3 py-2 cursor-pointer rounded-lg bg-gray-700 ${
                    selectedTestCase === index ? 'shadow-xl scale-110' : ''
                  }`}
                >
                  Case {index + 1}
                  {testCaseInputs.length > 1 ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent outputWidowTab click event when clicking delete button
                        removeTestCase(index);
                      }}
                      className="ml-2 text-xs text-red-500"
                    >
                      X
                    </button>
                  ) : (
                    <></>
                  )}
                </span>
              ))}
              <IoAddCircleOutline
                onClick={addTestCase}
                className="text-2xl mb-1 cursor-pointer"
              />
            </div>

            {/* Displaying the inputs for the selected test case */}
            <pre className="px-3 py-1 font-normal text-xs text-green-500 nanum-gothic-coding-regular">
              <div className="my-3">
                {/* Render inputs for the selected test case */}
                <div>
                  {Array.from({ length: totalInputs }).map((_, inputIndex) => (
                    <div key={inputIndex} className="my-2">
                      <div className="text-sm mb-2">Input {inputIndex + 1}</div>
                      <input
                        type="text"
                        value={
                          testCaseInputs[selectedTestCase]?.[inputIndex] || ''
                        } // Add fallback for undefined
                        onChange={(e) =>
                          handleInputChange(
                            selectedTestCase,
                            inputIndex,
                            e.target.value
                          )
                        }
                        className="text-xl border-0 outline-none w-[100%] flex px-4 py-3 items-center font-semibold bg-[#2A3C4D]"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </pre>
          </div>
        )}
      </div>
    </>
  );
};

export default OutputWindow;
