import React, { useState } from "react";
import PropTypes from "prop-types";
import { ColorPalette } from "../../config/constants/palette";

const Tag = ({ text, active = false, selectedTab, onClick, classes = "" }) => {
  const [state, setState] = useState();
  return (
    <div
      onClick={onClick}
      className={`rounded-md text-sm px-3 py-1 font-light cursor-pointer ${
        selectedTab !== text ? "bg-[#353B4B]" : `bg-[#7A5CEE]`
      } ${classes}`}
    >
      {text}
    </div>
  );
};

export { Tag };
