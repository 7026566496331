import { Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const CreateVideoRecord = async (data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/videos/create/record`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}