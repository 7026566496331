import { Get, Put, baseUrl } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const FetchPlatformUsers = async (data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/user?Label=${data}`, Authorization)    
    } catch (error) {
        console.log(error);
        throw error
    }
}

export const UpdateUsers = async (id, data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Put(`${baseUrl}/user/update/${id}`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}