import { create, GetState, SetState, useStore } from "zustand";
import { Success } from "../../config/helpers/toast-helper";
import { GetAccountInfo, GetAccountVerification, Login, SignUp, VerifyCode, VerifyOTPCode } from "../../service/auth/auth";
import { SetToLocalStorage, UserCredentials } from "../../config/helpers/local-storage-accessor-helper";
import { CancelDiscussion, CreateDiscussion, EndDiscussion, GetAccountDiscussions, GetDiscussionInfo, GetSuggestedDiscussions, StartDiscussion, UpdateDiscussion } from "../../service/discussion/discussion";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { Badge } from "../../components/Badge/Badge";
import { DiscussionStatusConstantsMapping } from "../../config/constants/discussion";
import * as moment from "moment/moment";
import { useAuthStore } from "../auth/auth";
import { FaAnglesRight } from "react-icons/fa6";
import { TableNavigator } from "../../components/TableNavigator/TableNavigator";

export const useDiscussionStore = create((set, get) => ({
  discussions: [],
  fetchingDiscussions: false,
  newDiscussions: false,
  totalItems: 1,
  analyzedDiscussions: null,
  suggestedDiscussions: [],

  async createDiscussions (data) {
    try {
        let Discussion = await CreateDiscussion(data)
        set((state) => ({
          newDiscussions: !state.newDiscussions, // Toggle newDiscussions flag
        }));
        return Discussion
    } catch (error) {
        console.log(error)
    }
  },

  async getAccountDiscussion(pageConfig){
    try {
        set({fetchingDiscussions: true})
        let { account, user, getAccountInfo } = useAuthStore.getState();
        if(!account){
          let resp = await getAccountInfo()
          account = resp.account
        }
        let { Discussions, Count } = (await GetAccountDiscussions(pageConfig))
        set({totalItems: Count,  discussions: Discussions.map(data => ({meetings:( <MeetingCell title={data.Title} desc={data.Description} /> ), status: <Badge text={DiscussionStatusConstantsMapping[data.Status]} />, scheduleDate: moment(data.ScheduleTime).format("DD/MM, h:mm A"), organizer: <div className="capitalize flex justify-between items-center"> {account._id == data.Account ? 'You' : data.User.FirstName } <TableNavigator route={`/account/discussion/${data._id}`} /> </div>}))})
        set({fetchingDiscussions: false})
    } catch (error) {
      console.log(error)
    }
  },

  async getDiscussionInfo(id){
    try {
      let { account, user, getAccountInfo } = useAuthStore.getState();
      if(!account){
        let resp = await getAccountInfo(id)
        account = resp.account
      }
      let { Discussion } = (await GetDiscussionInfo(id))
      set({analyzedDiscussions: Discussion})
      return Discussion
    } catch (error) {
      console.log(error)
    }
  },

  async updateDiscussions (id, data) {
    try {
        let Discussion = await UpdateDiscussion(id, data)
        // set((state) => ({
        //   newDiscussions: !state.newDiscussions, // Toggle newDiscussions flag
        // }));
        await get().getDiscussionInfo(id);
        return Discussion
    } catch (error) {
        console.log(error)
    }
  },

  async getSuggestedDiscussions(){
    try {
      let suggestedDiscussions = await GetSuggestedDiscussions()
      set({ suggestedDiscussions })
    } catch (error) {
      console.log(error)
    }
  },

  async startDiscussion(id){
    try {
        let startDiscussion = await StartDiscussion(id)
        return startDiscussion
    } catch (error) {
        console.log(error)
    }
  },

  async endDiscussion(id){
    try {
        let endDiscussion = await EndDiscussion(id)
        return endDiscussion
    } catch (error) {
        console.log(error)
    }
  },

  async cancelDiscussion (id){
    try {
      let cancelDiscussion = await CancelDiscussion(id)
      return cancelDiscussion
    } catch (error) {
        console.log(error)
    }
  },
}));
