export function SetToLocalStorage(data) {
    try {
        // Iterate over the keys of the data object
        Object.keys(data).forEach((key) => {
            // Set each key-value pair to localStorage
            localStorage.setItem(key, data[key]);
        });
    } catch (error) {
        console.error("Error storing data in localStorage:", error);
        // Handle the error appropriately
    }
}

export function ClearLocalStorage() {
    try {
        console.log('thi fn hit.')
        localStorage.clear();
    } catch (error) {
        console.error("Error clearing localStorage:", error);
        // Handle the error appropriately
    }
}

export function IsAuthenticated() {
    const account_id = localStorage.getItem('accountId');
    const access_token = localStorage.getItem('accessToken');
    
    // Check if both account_id and access_token exist in localStorage
    if (account_id && access_token) {
        // Both items exist, user is authenticated
        return true;
    } else {
        // Either account_id or access_token is missing, user is not authenticated
        return false;
    }
}

export function UserCredentials(){
    const access_token = localStorage.getItem('accessToken');
    return {
        Authorization: `bearer ${access_token}`
    }
}