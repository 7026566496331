import React, { useState } from "react";
import PropTypes from "prop-types";
import Logo from "../../assets/png/icon.png";
import { useReferralStore } from "../../store/referral/referral";
import { useAuthStore } from "../../store/auth/auth";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../assets/gif/buttonLoader.gif";

const Referral = (props) => {
  let { createReferral } = useReferralStore();
  let { account } = useAuthStore();

  const [referrals, setReferrals] = useState([{ name: "", email: "" }]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const handleInputChange = (index, event) => {
    const newReferrals = [...referrals];
    newReferrals[index][event.target.name] = event.target.value;
    console.log(newReferrals);
    setReferrals(newReferrals);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let payload = {
      Email: referrals[0].email,
      Name: referrals[0].name,
    };
    try {
      await createReferral(payload);
      navigate("/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="relative p-8 flex justify-center items-center text-center flex-col text-white rounded-lg"
      //   style={{ background: "linear-gradient(135deg, #A16AF2, #CBA0F7)" }}
    >
      <div className="flex gap-5 flex-row items-center">
        <img src={Logo} alt="" className="w-[61px] h-[61px]" />
        <h2 class="text-3xl font-[600] ">Discoursefy</h2>
      </div>

      <div className="hidden md:block py-8 px-5 max-w-3xl text-center">
        <h2 className="font-semibold text-3xl mb-4">
          Tell Your Friends About Discoursefy!
        </h2>
        <p className="text-lg leading-relaxed text-gray-400">
          <span className="text-white font-semibold">
            Unlock exclusive perks!
          </span>{" "}
          Refer a friend and enjoy our current and upcoming modules{" "}
          <span className="text-white font-semibold">for free.</span> Whether
          it’s{" "}
          <span className="text-white font-semibold">
            enhancing your skills
          </span>{" "}
          or{" "}
          <span className="text-white font-semibold">
            {" "}
            preparing to shine in front of top recruiters
          </span>
          , Discoursefy has you covered. Plus, you’ll strengthen your profile,
          &nbsp;
          <span className="text-white font-semibold">
            making you stand out to HR professionals and recruiters{" "}
          </span>{" "}
          actively seeking{" "}
          <span className="text-white font-semibold">
            motivated, skilled candidates
          </span>{" "}
          like you. Don’t miss the chance to{" "}
          <span className="text-white font-semibold">grow your career</span> and
          help your friends do the same!
        </p>
      </div>
      <div className="py-8 px-5 max-w-3xl text-center block md:hidden">
        <h2 className="font-semibold text-3xl mb-4">
          Tell Your Friends About Discoursefy!
        </h2>
        <p className="text-lg leading-relaxed text-gray-400">
          <span className="text-white font-semibold">
            Unlock exclusive perks!
          </span>{" "}
          Refer a friend to enjoy current and upcoming modules{" "}
          <span className="text-white font-semibold">for free.</span> Enhance
          your skills and shine in front of top recruiters. Discoursefy helps
          you strengthen your profile, making you stand out to HR professionals
          and motivated recruiters. Don’t miss the chance to{" "}
          <span className="text-white font-semibold">grow your career</span> and
          support your friends!
        </p>
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-6 w-full max-w-xl mx-auto"
      >
        {referrals.map((referral, index) => (
          <div
            key={index}
            className="p-4 bg-gray-800 rounded-lg shadow-lg transition-transform"
          >
            <div className="flex flex-col gap-4 sm:flex-row">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={referral.name}
                onChange={(e) => handleInputChange(index, e)}
                className="flex-1 p-3 rounded-lg bg-gray-700 text-white placeholder:text-gray-400 focus:outline-none transition"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={referral.email}
                onChange={(e) => handleInputChange(index, e)}
                className="flex-1 p-3 rounded-lg bg-gray-700 text-white placeholder:text-gray-400 focus:outline-none transition"
                required
              />
            </div>
          </div>
        ))}

        <div className="flex flex-col-reverse md:flex-row gap-y-3 justify-between items-center mt-6">
          <Link
            to="/"
            type="button"
            className="text-lg underline hover:text-gray-300 transition"
            onClick={() => console.log("Skip clicked")}
          >
            I’ll consider paying later!
          </Link>

          {loading ? (
            <>
              <img className="h-[55px] " src={Loader} />
            </>
          ) : (
            <button
              type="submit"
              disabled={loading}
              className={`py-3 px-6 ${
                loading ? "" : "bg-purple-600 hover:bg-purple-700"
              }  rounded-lg  transition-all duration-200 ease-in-out shadow-md font-semibold tracking-wide`}
            >
              Submit Referral
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

Referral.propTypes = {};

export { Referral };
