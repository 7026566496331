export const AccountStatusConstants = {
    ACTIVE: 1,
    INACTIVE: 2,
    DISABLED: 3,
    BLOCKED: 4
}

export const AccountTypeConstants = {
  USER: 1,
  CREATOR: 2
}

export const CreatorCategoryConstants = {
  GOLD: 1,
  PLATINUM: 2,
  DIAMOND: 3,
}