import { Get, Post, Put, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const GetCreatorExperience = async (accountId) => {
    try {
        let {Authorization} = UserCredentials()       
        return await Get(`${baseUrl}/experience/getByAccount/${accountId}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const CreateExperience = async (data) => {
    try {
        let { Authorization } = UserCredentials()
        Post(`${baseUrl}/experience/create`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const UpdateExperience = async (id, data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Put(`${baseUrl}/experience/update/${id}`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}