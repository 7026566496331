import * as moment from "moment";

export const formatTime = (seconds) => {
  return moment.utc(seconds * 1000).format("HH:mm:ss");
};

export const hoursAndMinuteFormat = (seconds) => {
  return moment.utc(seconds * 1000).format("HH:mm");
};

export const inMinutes = (seconds) => {
  // return moment.utc(seconds * 1000).format("HH:mm");
  return Math.ceil(seconds / 60);
};

export const monthsAndDaysFormat = (date) => {
  return moment(date).format("MMM DD");
};

export const changeFormat = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};
