import { create } from "zustand";
import { SaveQuestionAnswers } from "../../service/questionAnswers/questionAnswers";
import { SaveQuizReport } from "../../service/quizAnswers/quizAnswers";
import { GetQuizProfile, GetQuizRanking } from "../../service/quizProfile/quizProfile";

export const useQuizProfileStore = create((set, get) => ({
    quizProfile: null,
    quizRanking: [],

    async getQuizProfile(){
        try {
            let QuizProfile = await GetQuizProfile()
            set(()=>({quizProfile: QuizProfile}))
            return {
                Success: true
            }
        } catch (error) {
            console.log(error)
        }
    },

    async getQuizRanking(){
        try {
            let Ranking = await GetQuizRanking()
            set((state)=>({quizRanking: Ranking}))
            return Ranking
        } catch (error) {
            console.log(error)
        }
    }
}))