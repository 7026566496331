import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSolutionStore } from "../../store/solution/solution";
import { FaRegClock } from "react-icons/fa";
import { VscChip, VscOutput } from "react-icons/vsc";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism"; // VS Code dark theme
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

const Description = ({ currentProblem }) => {
  let { solutionResponse, resetSolutionResponse } = useSolutionStore();

  let { id } = useParams();

  useEffect(() => {
    resetSolutionResponse();
  }, [id]);

  return (
    <div className="w-full min-h-40 bg-[#1B2B34] rounded-md overflow-hidden  font-normal text-sm relative">
      <div className="h-[54px] flex justify-between items-center font-semibold bg-gray-700 py-2 px-3">
        <span>Description</span>
      </div>
      {solutionResponse == null ? (
        <div className="px-3 h-[calc(91vh-54px)] pt-3 scroll-bar flex flex-col gap-y-3 overflow-y-auto">
          <div className="text-xl">{currentProblem?.Title}</div>
          <div className="text-xs w-fit rounded-lg bg-gray-700 px-3 py-2">
            {currentProblem?.Difficulty}
          </div>
          <div className="text-sm my-2">
            <ReactMarkdown children={currentProblem?.Description} />
          </div>
          <div className="">
            {currentProblem?.Examples.map((data, i) => (
              <div className="text-sm my-2">
                <div className="text-lg nanum-gothic-coding-bold">
                  Example: {i + 1}
                </div>
                <div className="nanum-gothic-coding-regular bg-[#2A3C4D] p-2">
                  <div className="">
                    <span className="font-semibold">Input: </span>{" "}
                    <span>{data.input.map((item) => item).join(", ")}</span>
                  </div>
                  <div className="">
                    <span className="font-semibold">Output: </span>{" "}
                    {data.output}
                  </div>
                  {data.explanation && (
                    <div className="">
                      <span className="font-semibold">Explanation: </span>{" "}
                      {data.explanation}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="mb-2">
            <div className="text-lg font-semibold">Constraints</div>
            <div className="text-sm">{currentProblem?.Constraints}</div>
          </div>
        </div>
      ) : (
        <div className="px-3 h-[calc(91vh-54px)] pt-3 scroll-bar flex flex-col gap-y-3 overflow-y-auto">
          <div>
            <div className="flex gap-x-2 items-end m-3 nanum-gothic-coding-regular">
              {solutionResponse.FailedTestCases === 0 ? (
                <div className={`text-xl my-0 font-semibold text-green-500 `}>
                  Accepted
                </div>
              ) : (
                <div className={`text-xl my-0 font-semibold text-red-500 `}>
                  Rejected
                </div>
              )}
              <div className="mb-1 text-gray-200 font-light">
                Runtime: {solutionResponse?.Time.toFixed(2)} ms
              </div>
              <div className="mb-1 text-gray-200 font-light">
                Memory: {solutionResponse?.Memory.toFixed(2)} KB
              </div>
            </div>
          </div>

          <div className="my-5 flex flex-col gap-y-3">
            <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
              <div className="text-sm text-gray-300 flex items-center gap-x-1">
                {" "}
                <FaRegClock className="mb-[2px]" />{" "}
                <span className="font-light"> Runtime </span>
              </div>

              <div className="text-xl pt-3 font-semibold">
                <div className="flex items-end gap-x-2">
                  <span className="nanum-gothic-coding-regular text-green-400 text-2xl">
                    {solutionResponse.Time.toFixed(2)}
                  </span>
                  <span className="text-gray-400 text-sm mb-1">ms</span>
                </div>

                <div className="flex items-end gap-x-2 mt-2">
                  <span className="text-gray-400 text-sm mb-1">Beats</span>
                  <span className="nanum-gothic-coding-regular text-green-400 text-2xl">
                    {solutionResponse.comparison.betterTimePercentage.toFixed(
                      2
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
              <div className="text-sm text-gray-300 flex items-center gap-x-1">
                {" "}
                <VscChip className="mb-[2px] text-lg" />{" "}
                <span className="font-light"> Memory </span>
              </div>

              <div className="text-xl pt-3 font-semibold">
                <div className="flex items-end gap-x-2">
                  <span className="nanum-gothic-coding-regular text-green-400 text-2xl">
                    {solutionResponse.Memory.toFixed(2)}
                  </span>
                  <span className="text-gray-400 text-sm mb-1">KB</span>
                </div>

                <div className="flex items-end gap-x-2 mt-2">
                  <span className="text-gray-400 text-sm mb-1">Beats</span>
                  <span className="nanum-gothic-coding-regular text-green-400 text-2xl">
                    {solutionResponse.comparison.betterMemoryPercentage.toFixed(
                      2
                    )}
                    %
                  </span>
                </div>
              </div>
            </div>

            {/* I have out put code I want to show it here */}

            <div className="bg-gradient-to-r from-[#2A3C4D] to-[#3E1E5F] py-3 px-6 rounded-lg shadow-lg transform transition-all duration-300">
              <div className="text-sm text-gray-300 flex items-center gap-x-1">
                <VscOutput className="mb-[2px] text-lg" />{" "}
                <span className="font-light"> Output </span>
              </div>

              <div className="text-xl pt-3 font-semibold">
                <pre className="scroll-bar-hr p-3 rounded-md text-sm overflow-x-auto">
                  <CodeOutput code={atob(solutionResponse?.Code)} />
                </pre>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const CodeOutput = ({ code }) => (
  <SyntaxHighlighter language="javascript" style={vscDarkPlus} showLineNumbers>
    {code}
  </SyntaxHighlighter>
);

Description.propTypes = {};

export { Description };
