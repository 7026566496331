import { baseUrl, Get } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const FetchQuizComparisonByCategory = async (categoryId) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quizAnalytics/quizPerformance/${categoryId}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const FetchQuizAttemptsStats = async () => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quizAnalytics/quizAttemptsStats`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}