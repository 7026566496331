import { Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const CreateComment = async (data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Post(`${baseUrl}/comments/create`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const FetchComments = async (data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Get(`${baseUrl}/comments/get?EntityType=${data.EntityType}&EntityId=${data.EntityId}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}