import React, { useState } from "react";
import PropTypes from "prop-types";
import { WeekPerformance } from "../../components/WeekPerformance/WeekPerformance";
import { SkeletonTheme } from "react-loading-skeleton";

const WeeklyPerformance = (props) => {
  return (
    <div>
      <WeekPerformance />
    </div>
  );
};

WeeklyPerformance.propTypes = {};

export { WeeklyPerformance };
