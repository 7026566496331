export const DiscussionStatusConstantsMapping = {
    1: 'Planned',
    2: 'Live',
    3: 'Completed',
    4: 'Cancelled',
    5: 'Archived',
    6: 'Draft',
  }

export const discussionFilters = [
  {
    Title: 'All',
    value: null
  },
  {
    Title: 'Planned',
    value: 1
  },
  {
    Title: 'Live',
    value: 2
  },
  {
    Title: 'Completed',
    value: 3
  },
  {
    Title: 'Cancelled',
    value: 4
  }
]