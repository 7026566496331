import Google from "../../assets/svg/Google.svg";
import Logo from "../../assets/png/icon.png";
import { useState } from "react";
import Tabs from "../../components/Tabs/Tabs";
import { Button } from "../../components/Button/Button";
import SignIn from "../SignIn/SignIn";
import SignUp from "../SignUp/SignUp";
import { useAuthStore } from "../../store/auth/auth";
import { baseUrl } from "../../service";
import { Outlet, useLocation } from "react-router-dom";
import Typewriter from "typewriter-effect";

const Auth = () => {
  // let googleRedirect = useAuthStore.getState().googleRedirect()

  const [text, setText] = useState("");
  const [googleRedirection, setGoogleRedirection] = useState(false);

  const location = useLocation();

  let handleGoogleLogin = async () => {
    try {
      setGoogleRedirection(true);
      window.location.href = `${baseUrl}/auth/google-redirect`;
      // await useAuthStore.getState().GoogleRedirect()
      setTimeout(() => {
        setGoogleRedirection(false);
      }, 5000);
    } catch (error) {
      console.log(error);
    }
  };

  let isActivationScreen = () => {
    return location.pathname.includes("/account/activation") ? true : false;
  };

  const onChange = (e) => setText(e.target.value);
  return (
    <div className="m-4">
      <section class=" min-h-screen flex items-center justify-center">
        <div class="grid grid-cols-5 w-full h-[100vh] shadow-lg">
          {/* I want this component to be render from lg screens */}

          <div className="hidden md:block md:col-span-3 h-[410px] items-center bg-[#1F1D2B] my-auto px-4 typewriter-text pt-5">
            <Typewriter
              options={{
                strings: [
                  "<span style='color: #703BF7; font-weight: bold;'>Recruiters</span> most reliable tool.",
                  "It's time to showcase your <span style='color: #703BF7; font-weight: bold;'>hustle</span> and get noticed.",
                  "<span style='color: #703BF7; font-weight: bold;'>Focus</span> on learning—your future opportunities are in good hands.",
                  "Don’t let your <span style='color: #703BF7; font-weight: bold;'>hard work</span> go unnoticed.",
                  "<span style='color: #703BF7; font-weight: bold;'>Data</span> never lies—let your skills speak for themselves.",
                  "Your learning, our priority. Your <span style='color: #703BF7; font-weight: bold;'>success</span>, our goal.",
                  "Stand out to HRs with in-depth <span style='color: #703BF7; font-weight: bold;'>performance insights</span>.",
                  "Turn your daily hustle into <span style='color: #703BF7; font-weight: bold;'>career-defining opportunities</span>.",
                  "<span style='color: #703BF7; font-weight: bold;'>Maximize</span> your potential—Discoursefy tracks your path to success.",
                  "Learning today, leading tomorrow. We'll help you get <span style='color: #703BF7; font-weight: bold;'>there</span>.",
                ],
                autoStart: true,
                loop: true,
                delay: 65,
              }}
            />
          </div>
          <div class="col-span-5 md:col-span-2 md:w-full px-4 bg-[#1F1D2B] flex justify-center items-center">
            <div className="h-content">
              <div className="flex justify-center items-center gap-6">
                <img className="w-[61px] h-[61px]" src={Logo} alt="" />
                {/* <h2 class="text-3xl font-[65px] text-white">Discoursefy</h2> */}
                <h2 class="text-[40px] font-[600] text-white">Discoursefy</h2>
              </div>
              {!isActivationScreen() && (
                <div className={`my-3`}>
                  <Tabs />
                </div>
              )}
              <Outlet />
              {!isActivationScreen() && (
                <div
                  class={`my-2 w-1/2 m-auto grid grid-cols-3 items-center text-gray-200 ${
                    isActivationScreen() ? "hidden" : ""
                  }`}
                >
                  <hr class="border-gray-400" />
                  <p class="text-center text-[11px]">OR</p>
                  <hr class="border-gray-400" />
                </div>
              )}
              <div className={`my-3 ${isActivationScreen() ? "hidden" : ""}`}>
                <Button
                  onClick={() => handleGoogleLogin()}
                  isImage={true}
                  isDisabled={googleRedirection}
                  buttonText={"Connect With Google"}
                  image={Google}
                  classes={"text-sm font-semibold px-7"}
                  fullWidth
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Auth;
