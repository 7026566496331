import { create } from "zustand";
import { SaveQuestionAnswers } from "../../service/questionAnswers/questionAnswers";

export const useQuestionAnswerStore = create((set, get) => ({
    Answers: [],

    async saveQuestionAnswers(body){
        try {
            let Answer = await SaveQuestionAnswers(body)
            // set(()=>({questions: questions}))
            return {
                Success: true
            }
        } catch (error) {
            console.log(error)
        }
    }
}))