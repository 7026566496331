import React, { useState } from "react";
import PropTypes from "prop-types";
import { EducationCard } from "../EducationCard/EducationCard";
import { useEducationStore } from "../../store/education/education";
import { EmptyData } from "../EmptyData/EmptyData";

const Education = (props) => {
  const [state, setState] = useState();
  let { education } = useEducationStore()
  return (
    <>
   <div className="my-6 flex flex-col gap-5">
      {education?.length>0 ? education.map(data => (
          <EducationCard
            Data={data}
            School={data.School}
            Degree={ data.Degree}
            FieldOfStudy={ data.FieldOfStudy}
            StartDate={data.StartDate}
            EndDate={data.EndDate}
            Description={data.Description}
            ShowUpdate = {false}
          />
      )):<div className="">
      <EmptyData />
    </div>}
    </div>
    </>
  );
};

export { Education };
