import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { Button } from "../Button/Button";
import CoverPhoto from "../../assets/png/CreatorCoverPhoto.png";
import Avatar from "../../assets/png/CreatorPicture.png";
import Check from "../../assets/svg/Check.svg";
import { IoMdClose } from "react-icons/io";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { useNotifyStore } from "../../store/notify/notify";
import { NotifyTypeConstants } from "../../config/constants/notify";
import { useAuthStore } from "../../store/auth/auth";
import { Error } from "../../config/helpers/toast-helper";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa6";

const customStyles = {
  content: {
    top: "auto",
    left: "50%",
    right: "auto",
    bottom: "0%",
    transform: "translate(-50%, -10%)",
    background: "#1F1D2B",
    border: "none",
    width: "70%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const customMobileStyles = {
  content: {
    top: "10%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    // transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "calc(100% - 62px)",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const Modal = ({ isOpen, onRequestClose, discussion }) => {
  let { createNotifier, cancelDiscussionNotifier, discussionNotifiers } =
    useNotifyStore();
  let { account } = useAuthStore();

  const [descriptionView, setDescriptionView] = useState(true);
  const [loading, setLoading] = useState(false);

  const toggleDescriptionView = () => {
    setDescriptionView((prev) => !prev);
  };

  async function cancelNotifier() {
    setLoading(true);
    await cancelDiscussionNotifier(discussion._id);
    setLoading(false);
  }

  async function handleNotifyMe() {
    try {
      setLoading(true);
      let payload = {
        AccountId: account._id,
        EntityId: discussion._id,
        EntityType: NotifyTypeConstants.DISCUSSION,
      };
      if (discussion.Account._id == account._id) {
        Error("You cannot create discussion with your self");
        setLoading(false);
        return;
      }
      await createNotifier(payload);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function getScreenWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  let getScreenStyle = () => {
    if(getScreenWidth()>768){
      return customStyles
    }else{
      return customMobileStyles
    }
  } 

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      <div className="rounded-lg bg-[#1F1D2B] mx-3">
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-6">
          {/* Modal content */}
          <div className="w-full h-[250px] md:h-[100%] bg-[#252836] rounded-2xl overflow-hidden ">
            <div
              style={{ background: discussion.Theme }}
              className={`h-[70%] w-full relative flex items-center justify-center`}
            >
              {/* <img className="w-full h-full fit-cover" src={CoverPhoto} alt="" /> */}
              <div className=" text-white font-bold">
                <div className="text-[22px] md:text-[45px] text-center">{discussion.Skills[0].Label}</div>
                <div className="text-xs md:text-md my-0 text-center font-bold">
                  #discoursefy.
                </div>
              </div>
              <div className="absolute w-full -bottom-[75px] md:-bottom-[90px]">
                <div className="w-[70px] h-[70px] md:w-[90px] relative bg-[#252836] md:h-[90px] rounded-full mx-auto border-[1px] border-white flex justify-center items-center">
                  {discussion.User.ProfilePicture ? <img
                    className="w-[55px] h-[55px] md:w-[80px] mt-1 md:h-[80px] mx-auto rounded-full object-cover"
                    src={discussion.User.ProfilePicture}
                  />:
                    <div
                      style={{ background: "#7E5DEC" }}
                      className={`w-[55px] h-[55px] md:w-[80px] md:h-[80px] flex justify-center items-center rounded-full `}
                    >
                      <FaRegUser className="text-[35px] md:text-[45px] text-white" />
                  </div>
                  }

                  {/* <div className="w-[20px] h-[20px] absolute bottom-1 right-1 rounded-full bg-[#252836] flex justify-center items-center">
                    <div className="w-[15px] h-[15px] rounded-full bg-[#08A0F7] flex justify-center items-center">
                      <img src={Check} />
                    </div>
                  </div> */}
                </div>
              <div className="text-center mb-3 text-[14px] text-white capitalize py-2">
                <Link to={`/creator/${discussion.Account._id}`} className="flex items-center justify-center gap-2">
                  <span className="font-semibold"> {discussion.User.FirstName} </span> <span className="font-light"> <FaExternalLinkAlt /> </span>
                </Link>
              </div>
              </div>
            </div>
          </div>
          {/* Modal description */}
          <div className="text-white my-3">
            <div className="my-2">
              <div className="flex items-center">
                <h3 className="text-[22px] md:text-2xl font-semibold w-[90%] leading-relaxed flex-grow">
                  {discussion.Title}
                </h3>
                <div className="flex justify-end">
                  <div
                    onClick={onRequestClose}
                    className="p-2 bg-[#353440] rounded-md cursor-pointer"
                  >
                    <IoMdClose />
                  </div>
                </div>
              </div>
              {/* <div
                onClick={toggleDescriptionView}
                className="text-[13px] font-semibold underline cursor-pointer"
              >
                Meeting {descriptionView ? "Agenda" : "Description"}
              </div> */}
            </div>
            {/* Dynamic content based on descriptionView state */}
            {descriptionView ? (
              <div className="">
                <div className="flex justify-start items-center gap-3 flex-wrap my-4">
                  {discussion.Skills.map((skill) => (
                    <div className="px-3 py-1 rounded-md bg-[#353440] text-xs">
                      #{skill.Label.toLowerCase()}
                    </div>
                  ))}
                </div>
                <div className="text-xs my-2">
                  {moment(discussion.ScheduleTime).format("DD MMMM, h:mm A")}
                </div>
                <div className="my-3 text-sm h-[180px] overflow-y-auto scroll-bar">{discussion.Description}</div>
              </div>
            ) : (
              <div className="h-[200px] mt-6">
                <ol className="text-[13px]">
                  <li>1. Briefly Understand what is docker.</li>
                  <li>2. Make A JS(Node.JS) application.</li>
                  <li>3. Containerize it.</li>
                  <li>4. Run it Docker.</li>
                </ol>
              </div>
            )}
            <div className="my-6 flex justify-start items-center gap-4">
              {!discussionNotifiers.find(
                (data) => data.Account == account._id
              ) ? (
                <Button
                  isDisabled={loading}
                  onClick={handleNotifyMe}
                  buttonText="Notify Me"
                  isActive={true}
                />
              ) : (
                <Button
                  isDisabled={loading}
                  onClick={cancelNotifier}
                  buttonText="Cancel Notifier"
                  isActive={true}
                  classes={"bg-red-500"}
                />
              )}
              <Link
                to={`/account/discussion/${discussion._id}`}
                className="text-[14px] hidden md:block cursor-pointer"
              >
                About Discussion
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export { Modal };
