import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { CiBadgeDollar } from 'react-icons/ci';
import { Button } from '../Button/Button';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const BecomeCreatorStepCard = ({isInfoAdded, icon, title, description, step, cardClass,  onClick, buttonText}) => {
    console.log('cardClasses: ',cardClass)
    return <div className={`relative border-[1px] border-[#8254F8] rounded-2xl h-[380px] w-[250px] mx-auto py-10 ${cardClass}`}>
        <div className="h-[150px] flex justify-center items-center">
        {isInfoAdded ? <IoIosCheckmarkCircleOutline className='text-[120px]' /> :icon}
        </div>
        <div className="my-2 text-lg font-semibold text-white text-center">
            {title}
        </div>
        <div className="text-sm text-normal text-center my-3 w-[80%] mx-auto">
           {description}
        </div>
        <div className="absolute bottom-5 w-full">
            {step !== 6 ?<>
        <div className="flex justify-center items-center gap-x-2">
            <div className={`w-[8px] h-[8px] rounded-full border-[1px] border-white ${step===1 && 'bg-[#8254F8]'}`}></div>
            <div className={`w-[8px] h-[8px] rounded-full border-[1px] border-white ${step===2 && 'bg-[#8254F8]'} `}></div>
            <div className={`w-[8px] h-[8px] rounded-full border-[1px] border-white ${step===3 && 'bg-[#8254F8]'} `}></div>
            <div className={`w-[8px] h-[8px] rounded-full border-[1px] border-white ${step===4 && 'bg-[#8254F8]'} `}></div>
            <div className={`w-[8px] h-[8px] rounded-full border-[1px] border-white ${step===5 && 'bg-[#8254F8]'}  hidden min-[1600px]:block`}></div>
        </div>
            </>:
            <div className="text-center">
                <Button onClick={onClick} buttonText={buttonText} isActive={true} classes={'rounded-full text-md w-[150px]'} />
            </div>
            }
        </div>
    </div>;
};

BecomeCreatorStepCard.propTypes = {};

export { BecomeCreatorStepCard };