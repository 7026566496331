import { Get, Put, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const GetInterest = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/interest/getByAccount/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetExpertise = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/interest/expertise/getByAccount/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const PlatformSkills = async (search) => {
    try {
        let { Authorization } = UserCredentials()
        return await Get(`${baseUrl}/skills/get?Label=${search?search:''}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const UpdateInterest = async (accountId, data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Put(`${baseUrl}/interest/update/${accountId}`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const UpdateExpertise = async (accountId, data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Put(`${baseUrl}/interest/expertise/update/${accountId}`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}