import React, { useState } from "react";
import PropTypes from "prop-types";

let badges = {
  Completed: "bg-[#8254F8]", // discussion
  Planned: 'bg-green-400', // discussion
  Live: 'bg-red-400', // discussion
  Inprogress: 'bg-red-400',
  Requested: "bg-sky-500",  
  Schedule: 'bg-fuchsia-500',
  Ongoing: 'bg-emerald-500',
  Success: 'bg-[#8254F8]',
  Cancel: "bg-rose-500",
  Cancelled: "bg-rose-500",
  "Waiting For Payment": "bg-yellow-500",
  Paid: 'bg-green-400', // stripe,Description

  Easy: 'bg-[#81c784]', // quiz difficulty
  Medium: 'bg-[#ffeb3b]', // quiz difficulty
  Hard: 'bg-[#ffb74d]', // quiz difficulty
  Advance: 'bg-[#9575cd]', // quiz difficulty
};

const Badge = ({ text, classes='' }) => {
  const [state, setState] = useState();
  return (
    <div className={`py-1 rounded-full w-fit text-[9px] flex items-center gap-2 bg-[#353B4B] px-2 ${classes}`}>
      {" "}
      <div
        className={`w-[10px] h-[10px] rounded-full ${badges[text]}`}
      ></div>{" "}
      <div className=""> {text.toUpperCase()}</div>
    </div>
  );
};

Badge.propTypes = {};

export { Badge };
