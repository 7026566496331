import { create } from "zustand";
import { CreateMeeting, EndMeeting, FetchAccountMeetings, FetchCreatorInfo, FetchRelatedCreators, GetMeetingInfo, JoinMeeting, StartMeeting, UpdateMeeting } from "../../service/meeting/meeting";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { Badge } from "../../components/Badge/Badge";
import { TypeToChargeMapping } from "../../config/constants/charges";
import { DiscussionStatusConstantsMapping } from "../../config/constants/discussion";
import { MeetingStatusConstantsMapping } from "../../config/constants/meeting";
import * as moment from "moment";
import { TableNavigator } from "../../components/TableNavigator/TableNavigator";

export const useMeetingStore = create((set, get) => ({
    creators: [],
    creatorInfo: null,
    accountMeetings: [],
    analyzeMeeting: null,
    totalItems: 0,

    async fetchRelatedCreators(){
        try {
            let creators = await FetchRelatedCreators()
            set({creators})
        } catch (error) {
            console.log(error)
        }
    },

    async fetchCreatorInfo(id){
        try {
            let creator = await FetchCreatorInfo(id)
            set({creatorInfo: creator})
            return creator
        } catch (error) {
            console.log(error)
        }
    },

    async createMeeting(data){
        try {
            let meeting = await CreateMeeting(data)
            return meeting 
        } catch (error) {
            console.log(error)
        }
    },

    async fetchAccountMeeting(pageConfig){
        try {
            let {Meetings, Count}  = await FetchAccountMeetings(pageConfig) 
            let mapMeetings = Meetings.map(data => {
                return (
                    {
                        meetings: (
                          <>
                            <MeetingCell title={data.Title} desc={data.Description} />
                          </>
                        ),
                        plan: TypeToChargeMapping[data.Plan.Duration],
                        status:( <>
                            <Badge text={MeetingStatusConstantsMapping[data.Status]} />
                        </>),
                        scheduleDate: (<div className="flex justify-between items-center"> <div> {moment(data.ScheduleTime).format("DD/MM, h:mm A")}</div> <TableNavigator route={`/account/meeting/${data._id}`} /></div>),
                      }
                )
            })
            console.log(mapMeetings)
            set({accountMeetings: mapMeetings, totalItems: Count})
            return Meetings
        } catch (error) {
            console.log(error)
        }
    },

    async getMeetingInfo(id){
        try {
            let meetingInfo = await GetMeetingInfo(id)
            set({analyzeMeeting: meetingInfo})
            return meetingInfo
        } catch (error) {
            console.log(error)
        }
    },

    async updateMeeting(id, data){
        try {
            let updateMeeting = await UpdateMeeting(id, data)
            return updateMeeting
        } catch (error) {
            console.log(error)
        }
    },

    async joinMeeting(id){
        try {
            let joinMeetingResp = await JoinMeeting(id)
            return joinMeetingResp
        } catch (error) {
            console.log(error)
        }
    },

    async startMeeting(id){
        try {
            let joinMeetingResp = await StartMeeting(id)
            return joinMeetingResp
        } catch (error) {
            console.log(error)
        }
    },

    async endMeeting(id){
        try {
            let joinMeetingResp = await EndMeeting(id)
            return joinMeetingResp
        } catch (error) {
            console.log(error)
        }
    }
}))