import React, { useState } from "react";
import PropTypes from "prop-types";
import { ColorPalette } from "../../config/constants/palette";
import { useMeetingStore } from "../../store/meeting/meeting";

const Banner = (props) => {
  let { fetchCreatorInfo, creatorInfo } = useMeetingStore()

  const [state, setState] = useState();
  let BannerTitle = () => {
    if(creatorInfo){
      return `${creatorInfo.User.FirstName} ${(!!creatorInfo.User.LastName ? creatorInfo.User.LastName : '')}`
    }
    else if(props.User){
      return `${props.User.FirstName} ${(!!props.User.LastName ? props.User.LastName : '')}`
    }
  }
  return (
    <div
      style={{ background: ColorPalette.PastelGreen }}
      className={`w-full h-[100px] md:h-[180px] flex justify-end items-center ${props?.classes ? props?.classes : 'rounded-xl'}`}
    >
       <div className="text-[18px] md:text-[40px] font-bold mx-10 capitalize  ">
            {BannerTitle()}
      </div>
    </div>
  );
};

export { Banner };
