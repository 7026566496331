export const SupportedLanguagesCodeMapping = {
  JavaScript: 63,
  Python: 71,
  Cpp: 54,
  Java: 96,
};

export const SupportedCodeLanguagesMapping = {
  63: "JavaScript",
  71: "Python",
  54: "C++",
  96: "Java",
};
