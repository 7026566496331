import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input } from "../Input/Input";
import { Separator } from "../Separator/Separator";
import { MdOutlinePayments } from "react-icons/md";
import { Button } from "../Button/Button";
import { RiPencilFill } from "react-icons/ri";
import { IoCheckmark } from "react-icons/io5";
import { isValidPackageValue } from "../../config/constants/regix";
import { ChargesDurationEnums, ChargesToTypeMapping, ChargesTypeEnums } from "../../config/constants/charges";
import { useChargesStore } from "../../store/charges/charges";
import { useAuthStore } from "../../store/auth/auth";
import { Tooltip } from "react-tooltip";


const Charges = ({setChargesStatus}) => {
  let {fetchAccountCharges, charges, createOrUpdateCharges} = useChargesStore()
  let { account, getAccountInfo} = useAuthStore()
  
  const [packages, setPackages] = useState({
    swiftSession: { value: 0, active: false },
    hourlyHustle: { value: 0, active: false },
    epicEncounter: { value: 0, active: false },
  });

  useEffect(()=>{
    getAccountCharges()
  },[])

  let getAccountCharges = async () => {
    if(!account){
      let AccountResp = await getAccountInfo()
      account = AccountResp.account
    }
    let Charges = await fetchAccountCharges(account._id)
    let swiftSessionCharges = Charges.find(data => data.Duration == ChargesDurationEnums.HALF_HOUR)?.Amount || 0
    let hourlyHustleCharges = Charges.find(data => data.Duration == ChargesDurationEnums.HOUR)?.Amount || 0
    let epicEncounterCharges = Charges.find(data => data.Duration == ChargesDurationEnums.NINETY_MINUTES)?.Amount || 0

    setPackages({
      swiftSession: { value: swiftSessionCharges, active: false },
      hourlyHustle: { value: hourlyHustleCharges, active: false },
      epicEncounter: { value: epicEncounterCharges, active: false },
    })
    setChargesStatus(swiftSessionCharges && hourlyHustleCharges && epicEncounterCharges)
  }

  let activeSwiftSession = () => {
    setPackages({
      ...packages,
      swiftSession: {
        value: packages.swiftSession.value,
        active: !packages.swiftSession.active,
      },
    });
  };

  let UpdatePayment = async (type) => {
    try {
      let data = {
        Type: ChargesTypeEnums.MEETING,
        Duration: ChargesToTypeMapping[type],
        Amount: packages[type].value
      }
      await createOrUpdateCharges(data)

      let updatedPackage = {
        ...packages,
        [type]: {
          value: packages[type].value,
          active: !packages[type].active,
        },
      }
      setPackages(updatedPackage);

    setChargesStatus(updatedPackage.swiftSession.value && updatedPackage.hourlyHustle.value && updatedPackage.epicEncounter.value)
    }catch (err) {
      console.log(err)
    }
  }

  let activeHourlyHustle = () => {
    let data = {
      Type: ChargesTypeEnums.MEETING,
      Duration: ChargesDurationEnums.HALF_HOUR,
      value: packages.hourlyHustle.value
    }
    setPackages({
      ...packages,
      hourlyHustle: {
        value: packages.hourlyHustle.value,
        active: !packages.hourlyHustle.active,
      },
    });
  };

  let activeEpicEncounter = () => {
    let data = {
      Type: ChargesTypeEnums.MEETING,
      Duration: ChargesDurationEnums.HALF_HOUR,
      value: packages.epicEncounter.value
    }
    setPackages({
      ...packages,
      epicEncounter: {
        value: packages.epicEncounter.value,
        active: !packages.epicEncounter.active,
      },
    });
  };

  let onSwiftSessionChange = (e) => {
    if(e.target.value==''){
      setPackages({
        ...packages,
        swiftSession: {
          value: 0,
          active: packages.swiftSession.active,
        },
      });
    }
    if (isValidPackageValue(e.target.value)) {
      setPackages({
        ...packages,
        swiftSession: {
          value: parseFloat(e.target.value),
          active: packages.swiftSession.active,
        },
      });
    }
  };

  let onHourlyHustleChange = (e) => {
    if(e.target.value==''){
      setPackages({
        ...packages,
        hourlyHustle: {
          value: 0,
          active: packages.hourlyHustle.active,
        },
      });
    }
    if (isValidPackageValue(e.target.value)) {
      setPackages({
        ...packages,
        hourlyHustle: {
          value: parseFloat(e.target.value),
          active: packages.hourlyHustle.active,
        },
      });
    }
  };

  let onEpicEncounterChange = (e) => {
    if(e.target.value==''){
      setPackages({
        ...packages,
        epicEncounter: {
          value: 0,
          active: packages.epicEncounter.active,
        },
      });
    }
    if (isValidPackageValue(e.target.value)) {
      setPackages({
        ...packages,
        epicEncounter: {
          value: parseFloat(e.target.value),
          active: packages.epicEncounter.active,
        },
      });
    }
  };

  return (
    <div className="bg-[#181818] text-white p-5 rounded-lg">
      <div className="text-lg">Packages Setting</div>
      <div className="my-6 text-sm font-light">
        Welcome to Discoursefy, a platform where experts like you can set their
        own rates. For those new to the platform or with fewer than five 5-star
        ratings, we recommend setting your rates conservatively. Please note
        that the rates displayed below represent what we charge our clients.
        Your earnings will be subject to a 30% platform fee, deducted as our
        service charge. Thank you for being a part of our community!
      </div>

      <div className="my-6">
        <Separator />
      </div>

      <div className="grid grid-cols-1 gap-6 mt-3">
        <div className="grid grid-cols-2 max-w-[400px]">
          <div className="flex items-center gap-2">
            <div className="w-[50px] h-[50px] rounded-full bg-[#8254F8] flex justify-center items-center">
              <MdOutlinePayments className="text-[30px]" />
            </div>
            <span className="flex flex-col">
              {" "}
              Swift Session:{" "}
              <span className="text-xs font-light text-[#AAAAAA]">
                {" "}
                (30 minutes)
              </span>{" "}
            </span>
          </div>
          {!packages.swiftSession?.active ? (
            <div className="flex items-center">
              {/* <Input classes={"w-[80px] text-white"} />{" "} */}
              <div className="flex-grow text-2xl">
                {" "}
                {packages.swiftSession?.value}${" "}
              </div>
              <span
                onClick={activeSwiftSession}
                className="p-2 bg-[#353B4B] rounded-md cursor-pointer"
                data-tooltip-id="epic-encounter-price-suggestion" data-tooltip-content="Suggested Rates USD 15-35"
              >
                {" "}
                <Tooltip id="epic-encounter-price-suggestion" />
                <RiPencilFill />{" "}
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <Input
                value={packages.swiftSession?.value}
                onChange={onSwiftSessionChange}
                classes={"w-[80px] text-white"}
                placeholder={'Suggested Rates USD 15-35'}
              />{" "}
              <span
                onClick={() => UpdatePayment('swiftSession')}
                className="ml-1 p-2 bg-[#353B4B] rounded-md cursor-pointer"
              >
                {" "}
                <IoCheckmark />{" "}
              </span>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 max-w-[400px]">
          <div className="flex items-center gap-2">
            <div className="w-[50px] h-[50px] rounded-full bg-[#8254F8] flex justify-center items-center">
              <MdOutlinePayments className="text-[30px]" />
            </div>
            <span className="flex flex-col">
              {" "}
              Hourly Hustle:{" "}
              <span className="text-xs font-light text-[#AAAAAA]">
                {" "}
                (60 minutes)
              </span>{" "}
            </span>
          </div>
          {!packages.hourlyHustle?.active ? (
            <div className="flex items-center">
              {/* <Input classes={"w-[80px] text-white"} />{" "} */}
              <div className="flex-grow text-2xl">
                {" "}
                {packages.hourlyHustle?.value}${" "}
              </div>
              <span
                onClick={activeHourlyHustle}
                className="p-2 bg-[#353B4B] rounded-md cursor-pointer" data-tooltip-id="hourly-hustle-price-suggestion" data-tooltip-content="Suggested Rates USD 30-60"
                >
                {" "}
                <Tooltip id="hourly-hustle-price-suggestion" />
                <RiPencilFill />{" "}
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <Input
                onChange={onHourlyHustleChange}
                value={packages.hourlyHustle?.value}
                placeholder={'Suggested Rates USD 30-60'}
                classes={"w-[80px] text-white"}
              />{" "}
              <span
                onClick={() => UpdatePayment('hourlyHustle')}
                className="ml-1 p-2 bg-[#353B4B] rounded-md cursor-pointer"
              >
                {" "}
                <IoCheckmark />{" "}
              </span>
            </div>
          )}
        </div>

        <div className="grid grid-cols-2 max-w-[400px]">
          <div className="flex items-center gap-2">
            <div className="w-[50px] h-[50px] rounded-full bg-[#8254F8] flex justify-center items-center">
              <MdOutlinePayments className="text-[30px]" />
            </div>
            <span className="flex flex-col">
              {" "}
              Epic Encounter:{" "}
              <span className="text-xs font-light text-[#AAAAAA]">
                {" "}
                (90 minutes)
              </span>{" "}
            </span>
          </div>
          {!packages.epicEncounter?.active ? (
            <div className="flex items-center">
              {/* <Input classes={"w-[80px] text-white"} />{" "} */}
              <div className="flex-grow text-2xl">
                {" "}
                {packages.epicEncounter?.value}${" "}
              </div>
              <span
                onClick={activeEpicEncounter}
                className="p-2 bg-[#353B4B] rounded-md cursor-pointer"
                data-tooltip-id="epic-encounter-price-suggestion" data-tooltip-content="Suggested Rates USD 50-100"
              >
                {" "}
                <Tooltip id="epic-encounter-price-suggestion" />
                <RiPencilFill />{" "}
              </span>
            </div>
          ) : (
            <div className="flex items-center">
              <Input
                onChange={onEpicEncounterChange}
                value={packages.epicEncounter?.value}
                placeholder={'Suggested Rates USD 50-100'}
                classes={"w-[80px] text-white"}
              />{" "}
              <span
                onClick={() => UpdatePayment('epicEncounter')}
                className="ml-1 p-2 bg-[#353B4B] rounded-md cursor-pointer"
              >
                {" "}
                <IoCheckmark />{" "}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Charges.propTypes = {};

export { Charges };
