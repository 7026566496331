import { create } from "zustand";
import { CreateVideoRecord } from "../../service/video/video";
import { useAuthStore } from "../auth/auth";

export const useVideoStore = create((set, get) => ({
     createVideoRecord: async (data) => {
        let { getAccountInfo } = useAuthStore.getState()

        try {
            await CreateVideoRecord(data)
            await getAccountInfo()
        } catch (error) {
            console.log(error)
        }
     }
}))