import React, { useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { FaChevronRight } from "react-icons/fa6";
import { FaChevronLeft } from "react-icons/fa6";
import { EmptyData } from "../EmptyData/EmptyData";
import Skeleton from "react-loading-skeleton";

const Table = ({
  headers,
  meetings,
  currentPage,
  totalPages,
  loading,
  onNextPage,
  onPreviousPage,
  footer = true,
}) => {
  const [state, setState] = useState();
  return (
    <div className="my-4 min-w-[700px] overflow-x-auto">
      <div className="grid grid-cols-12">
        {headers.map((header, index) => {
          return (
            <div
              style={{ gridColumn: `span ${header.cols}/span ${header.cols}` }}
              className={`text-[#AAAAAA] `}
              key={index}
            >
              <div className="text-base font-normal">{header.label}</div>
            </div>
          );
        })}
      </div>
      <div className="my-5">
        <Separator />
      </div>
      {!loading ? (
        <div className="grid grid-cols-12 gap-y-4">
          {meetings.length > 0 ? (
            meetings.map((meeting, index) => {
              return Object.keys(meeting).map((eachMeetingKey) => (
                <>
                  <div
                    style={{
                      gridColumn: `span ${
                        headers.find((header) => header.id == eachMeetingKey)
                          .cols
                      }/span ${
                        headers.find((header) => header.id == eachMeetingKey)
                          .cols
                      }`,
                    }}
                    className={`col-span-${
                      headers.find((header) => header.id == eachMeetingKey).cols
                    } text-white cursor-pointer`}
                    key={index}
                  >
                    <div className="font-normal text-xs">
                      {meeting[eachMeetingKey]}
                    </div>
                  </div>
                </>
              ));
            })
          ) : (
            <div className="col-span-12 flex justify-center items-center">
              <EmptyData />
            </div>
          )}
        </div>
      ) : (
        <div className="h-[400px] w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col gap-y-2">
            {Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={index}
                width={"100%"}
                height={24}
                className="mb-2"
              />
            ))}
          </div>
        </div>
      )}
      {footer && (
        <>
          <div className="my-5">
            <Separator />
          </div>
          <div className="flex justify-end items-center">
            <div className="flex items-center gap-2 text-[#f6f6f6] text-sm">
              <FaChevronLeft
                onClick={onPreviousPage}
                className={`${
                  currentPage == 1 ? "text-[#AAAAAA]" : "cursor-pointer"
                }`}
              />{" "}
              <span>
                {currentPage}/{totalPages}
              </span>{" "}
              <FaChevronRight
                onClick={onNextPage}
                className={`${
                  currentPage == totalPages
                    ? "text-[#AAAAAA]"
                    : "cursor-pointer"
                }`}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

Table.propTypes = {};

export { Table };
