import React, { useState } from "react";
import PropTypes from "prop-types";
import Avatar from "../../assets/png/CreatorPicture.png";
import { CreatorAvatar } from "../CreatorAvatar/CreatorAvatar";
import CreatorProfilePicture from "../../assets/png/CreatorPicture.png";
import { useNavigate } from "react-router-dom";

const CreatorCard = (props) => {
  const [state, setState] = useState();
  
  const navigate = useNavigate();

  function handleNavigator(accountId){
    navigate(`/creator/${accountId}`);
  }

  let handleCreatorDescription = () => {
    if (props?.Description.length > 60) return props?.Description.slice(0, 60) + "...";
    return props?.Description;
  };

  let handleTags = (skill) =>{
    return skill.split('').filter(d=>(d !== ' ')).join('').toString()
  }

  return (
    <div className="">
      <div onClick={() => handleNavigator(props.Creator._id)} className="rounded-xl cursor-pointer overflow-hidden w-full min-w-[275px] h-[350px] bg-[#252836]">
        <CreatorAvatar
          Theme={props.Theme}
          CoverPhoto={props.CoverPhoto}
          Rating={props.Rating}
          FirstName={props.FirstName}
          LastName={props.LastName}
          CreatorCard = {true}
          Creator={props.Creator}
        />
        <div className="">
          <div className="name-container m-3 my-3 text-white">
            <div className="mb-5 text-[#B7B9D2]">
              <div className=" font-semibold font-[400] text-[14px] capitalize text-white">
                {props.FirstName} {props.LastName ? props.LastName:''}
              </div>
              <div className="flex items-center flex-wrap text-[11px]">
                {props.Skills.slice(0, 5).map((skill, i) => (
                  <div>#{handleTags(skill)} &nbsp;</div>
                ))}
                {props.Skills.length>5 ? ". . .":null}
              </div>
            </div>

            {/* need to work on description length */}
            <div className="text-md font-[500] mb-5">
              {handleCreatorDescription()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreatorCard };
