export const ChargesTypeEnums = {
    MEETING: 1
}

export const StripePayoutStatusConstants = {
    paid: 'Paid'   
}

export const ChargesDurationEnums = {
    HALF_HOUR: 1,
    HOUR: 2,
    NINETY_MINUTES: 3
}
export const DurationToChargesMapping = {
    1: '30' ,
    2: '60' ,
    3: '90'
}

export const ChargesToTypeMapping = {
    swiftSession: 1,
    hourlyHustle: 2,
    epicEncounter: 3
}

export const TypeToChargeMapping = {
    1: 'Swift Session' ,
    2: 'Hourly Hustle',
    3: 'Epic Encounter'
}
