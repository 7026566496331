import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Separator = props => {
const [state, setState] = useState();
    return <div className="w-full h-[1px] bg-[#353B4B]"></div>;
};

Separator.propTypes = {};

export { Separator };