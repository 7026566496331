import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { BsExclamationCircle } from 'react-icons/bs';

const NoDataFoundCard = ({text="Let's get started! Begin tracking your learning activities today and see your progress soar!"}) => {
    return <div className='h-[100%] flex flex-col gap-y-5 justify-center items-center w-full bg-[#252836] rounded-lg p-5'>
        <div className="w-fit mx-auto text-[#7E5DEC] text-[90px]">
            <BsExclamationCircle />
        </div>
        <div className="text-center text-sm text-white">
            {text}
        </div>
    </div>;
};

NoDataFoundCard.propTypes = {};

export { NoDataFoundCard };