import React, { useState } from "react";
import PropTypes from "prop-types";
import { EarnComponent } from "../../components/EarnComponent";

const Earn = (props) => {
  const [state, setState] = useState();
  return <div>
    <EarnComponent />
  </div>;
};

Earn.propTypes = {};

export { Earn };
