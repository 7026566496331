import { create } from "zustand";
import { CancelDiscussionNotifier, CancelNotifier, CreateNotifier, GetDiscussionNotifier } from "../../service/notify/notify";
import { NotifyTypeConstants } from "../../config/constants/notify";

export const useNotifyStore = create((set, get) => ({
    discussionNotifiers: [],
    
    async createNotifier(data){
        try {
            await CreateNotifier(data)
            if(data.EntityType == NotifyTypeConstants.DISCUSSION){
                await get().getEntityNotifier(data.EntityId)
            }
        } catch (error) {
            console.log(error)
        }
    },

    async cancelNotifier(id){
        try {
            let cancelNotifierResponse = await CancelNotifier(id)
        } catch (error) {
            console.log(error)
        }
    },

    async cancelDiscussionNotifier(id){
        try {
            let cancelNotifierResponse = await CancelDiscussionNotifier(id)
            get().getEntityNotifier(id)
        } catch (error) {
            console.log(error)
        }
    },

    async getEntityNotifier(discussionId){
        try {
            let notifiers = await GetDiscussionNotifier(discussionId)
            set({discussionNotifiers: notifiers})
        } catch (error) {
            console.log(error)
        }
    }
}))