import { create } from "zustand";
import { CreateRating, FetchCreatorRating } from "../../service/rating/rating";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { FaRegStar, FaRegUser } from "react-icons/fa6";
import * as moment from "moment";
import { FetchQuizCategories } from "../../service/quizCategory/quizCategory";
import { FetchQuizByCategory } from "../../service/quiz/quiz";

export const useQuizStore = create((set, get) => ({
    quiz: [],
    quizCategory: null,
    quizMaterial: null,

    async fetchQuizByCategory(id){
        try {
            let quiz = await FetchQuizByCategory(id)
            set(()=>({ quiz: quiz.Quizzes, quizCategory: quiz.QuizCategory}))
            return quiz
        } catch (error) {
            console.log(error)
        }
    },

    setQuizCategory: (quizCategory) => set(() => ({ quizCategory }))
}))