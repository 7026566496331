import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { QuizProfileCard } from "../QuizProfileCard/QuizProfileCard";
import { QuizLeaderboard } from "../QuizLeaderboard/QuizLeaderboard";
import { useQuizPerformanceStore } from "../../store/quizPerformance/quizPerformance";
import { QuizProfileCardType } from "../../config/constants/quizProfileCardType";
import { useQuizReportStore } from "../../store/quizAnswer/quizAnswer";
import { ActivityCalendar } from "activity-calendar-react";
import {
  changeFormat,
  inMinutes,
  monthsAndDaysFormat,
} from "../../config/helpers/moment-helper";
import { EmptyData } from "../EmptyData/EmptyData";
import { PlanningModal } from "../PlanningModal/PlanningModal";
import { Tag } from "../Tag/Tag";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthStore } from "../../store/auth/auth";
import Skeleton from "react-loading-skeleton";
import { PostTypeEnum } from "../../config/constants/linkedIn";
import { useAccountStore } from "../../store/account/account";

const WeekPerformance = (props) => {
  let { activityRecord, getActivityRecord } = useQuizReportStore();
  let { challengesReport, getQuizChallenges } = useQuizPerformanceStore();
  let { account } = useAuthStore();
  let { generateLinkedInPost } = useAccountStore();

  const [openPlanningModal, setOpenPlanningModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let code = searchParams.get("code");
    if (code && code.length) {
      let quizId = localStorage.getItem("QuizId");
      let postType = localStorage.getItem("PostType");
      let queryParams = {
        QuizId: 0,
        PostType: PostTypeEnum.BecomeACreator,
      };
      if (quizId && postType) {
        queryParams = {
          QuizId: quizId,
          PostType: postType,
        };
      }
      localStorage.removeItem("QuizId");
      localStorage.removeItem("PostType");
      generateLinkedInPost(code, queryParams);
      navigate(`/`);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setActivityLoading(true);
      setLoading(true);
      try {
        await fetchActivityRecord();
        await fetchQuizChallenges();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setTimeout(() => {
          setActivityLoading(false);
        }, 2000);
      }
    })();
  }, []);

  let fetchActivityRecord = async () => {
    await getActivityRecord();
  };

  let fetchQuizChallenges = async () => {
    await getQuizChallenges();
  };

  let colorCustomization = {
    activity0: "#161B22",
    activity1: "#9d33e3",
    activity2: "#953ae9",
    activity3: "#8b4ce0",
    activity4: "#B210FD",
  };

  const sampleData = [
    {
      day: "2024-07-27",
      activity: 5,
    },
    {
      day: "2024-08-02",
      activity: 1,
    },
  ];

  let totalActivities = () => {
    return activityRecord?.reduce((acc, curr) => {
      return acc + curr.count;
    }, 0);
  };
  return (
    <div className="max-w-[1280px] relative mx-auto grid grid-cols-3 lg:grid-cols-4 overflow-x-hidden px-4 md:px-10 gap-5">
      <PlanningModal
        handleCloseModal={setOpenPlanningModal}
        isOpen={openPlanningModal}
      />
      <div className="hidden md:flex flex-col gap-y-5 col-span-4 md:col-span-1">
        <div className="overflow-hidden rounded-xl bg-[#252836]">
          <QuizProfileCard type={QuizProfileCardType.REPORT} />
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 col-span-3 md:col-span-2 lg:col-span-3 gap-x-5 gap-y-5 items-start">
        {/* Activity bar here. */}
        <div className="col-span-2 sm:col-span-2 lg:col-span-3">
          {/* xl:w-[775px]  max-w-[775px] */}
          <div className="rounded-xl mx-auto overflow-x-auto p-4 bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] w-[100%] scroll-bar">
            <div className="flex justify-center flex-col">
              <div className="text-sm font-semibold">
                {!loading ? (
                  `${totalActivities()} sessions in the this year`
                ) : (
                  <Skeleton width={180} />
                )}
              </div>
              <div className="flex justify-between items-center">
                {!loading ? (
                  <ActivityCalendar
                    sampleData={activityRecord.map((data) => ({
                      day: data.day,
                      activity: data.activity,
                    }))}
                    colorCustomization={colorCustomization}
                    showMonth={true}
                  />
                ) : (
                  <div className="w-full">
                    <Skeleton width={"100%"} height={120} />
                  </div>
                )}
                <div className="text-center flex-grow flex justify-center items-center flex-col pr-4">
                  {!loading ? (
                    <>
                      <div className="text-5xl font-bold">
                        {activityRecord.length}
                      </div>{" "}
                      <div className="text-sm font-semibold">Appearances</div>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {!loading ? (
            <>
              {challengesReport?.length > 0 ? (
                challengesReport.map((data) => (
                  <>
                    <div className="rounded-lg mx-auto my-4 p-4 bg-[#252836] w-full  shadow-lg flex flex-col justify-between">
                      {/* Header with Date Range and Efficiency */}
                      <div className="flex justify-between items-baseline mb-3">
                        <div className="">
                          <div className="text-white text-lg sm:text-2xl font-semibold">
                            {monthsAndDaysFormat(data.StartDate)} -{" "}
                            {monthsAndDaysFormat(data.EndDate)}
                          </div>
                          <div className="flex items-center gap-x-2 mt-2 text-xs sm:text-sm text-white font-semibold mb-1">
                            {data.QuizCategoriesTitle.map((data) => (
                              <Tag
                                text={data}
                                active={true}
                                classes="text-xs"
                              />
                            ))}
                          </div>
                        </div>
                        <div className="flex justify-end items-center">
                          <div className="text-3xl sm:text-5xl font-bold text-[#B310FD]">
                            {data.Efficiency}
                          </div>
                        </div>
                      </div>

                      {/* Stats */}
                      <div className="grid grid-cols-2 sm:grid-cols-3 gap-x-6 gap-y-2">
                        {/* Time Spent */}
                        <div className="hidden sm:flex col-span-1 flex-col items-start">
                          <div className="text-sm text-gray-400">
                            Time Spent
                          </div>
                          <div className="text-lg font-semibold text-white">
                            {inMinutes(data.TotalTimeOnLearning)} Minutes
                          </div>
                        </div>

                        {/* Sessions Completed */}
                        <div className="col-span-1 flex flex-col items-start">
                          <div className="text-sm text-gray-400">
                            Sessions Completed
                          </div>
                          <div className="text-lg font-semibold text-white">
                            {data.SessionsCompleted} / {data.TotalSessions}
                          </div>
                        </div>

                        {/* Knockouts */}
                        <div className="col-span-1 flex flex-col items-start">
                          <div className="text-sm text-gray-400">Knockouts</div>
                          <div className="text-lg font-semibold text-white">
                            {data.KnockOuts}
                          </div>
                        </div>
                      </div>

                      <div className="flex items-end gap-x-2">
                        {/* Progress Bar */}
                        <div className="flex-grow">
                          <div className="text-sm text-gray-400">Progress</div>
                          <div className="w-full bg-gray-700 rounded-full h-3 mt-2">
                            <div
                              className="h-3 rounded-full"
                              style={{
                                width: data.Efficiency,
                                background:
                                  "linear-gradient(to right, #D69CFF, #B310FD)",
                              }}
                            ></div>
                          </div>
                        </div>

                        {/* Updated "View More" Button */}
                        <div className="hidden md:flex justify-end mt-4">
                          <Link
                            to={`/weekly-performance/${account?._id}?startDate=${data?.StartDate}&endDate=${data?.EndDate}`}
                            className="text-sm bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] text-white px-5 py-2 rounded-lg shadow-lg hover:scale-105 transform transition duration-300 ease-in-out"
                          >
                            View More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="mt-5 flex justify-center flex-col items-center xl:w-[775px] max-w-[775px] mx-auto">
                  <div className="text-2xl font-bold text-center">
                    Your Journey Starts with Weekly Challenges!
                  </div>
                  <div className="text-medium my-4 text-center">
                    Plan your week and set yourself up for success. Our 4-week
                    plan sharpens both your tech and soft skills from coding to
                    communication, leadership to problem-solving. With every
                    challenge, you’ll track your growth, stay accountable, and
                    build a profile that recruiters can’t ignore. It’s more than
                    learning; it’s about showcasing your future potential to
                    employers.
                  </div>
                  <div
                    onClick={() => setOpenPlanningModal(true)}
                    className="w-[150px] cursor-pointer text-center text-sm font-semibold bg-[#fff] text-black py-1 rounded-full shadow-sm hover:shadow-lg transition"
                  >
                    Plan Your Success!
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="w-full my-4">
                <Skeleton width={"100%"} height={190} />
              </div>
              <div className="w-full my-4">
                <Skeleton width={"100%"} height={190} />
              </div>
            </>
          )}
        </div>
        {/* <ActivityCalendar
          theme={{
            light: ["hsl(0, 0%, 92%)", "firebrick"],
            dark: ["#333", "rgb(214, 16, 174)"],
          }}
          color="#0DAEFF"
          data={[
            {
              count: 0,
              date: "2021-01-01",
              level: 0,
            },
            {
              count: 0,
              date: "2021-01-02",
              level: 0,
            },
          ]}
        /> */}
      </div>
    </div>
  );
};

WeekPerformance.propTypes = {};

export { WeekPerformance };
