import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "../Button/Button";
import { PackageCard, PlatformPackageCard } from "../PackageCard/PackageCard";
import { useChargesStore } from "../../store/charges/charges";
import { Error } from "../../config/helpers/toast-helper";

let SelectedPackageConstants = {
    BRONZE: "BRONZE",
    SILVER: "SILVER",
    GOLD: "GOLD"
}

export default function CheckoutForm() {
    let { chargeYourWallet } = useChargesStore()
    const [selectedPackage, setSelectedPackage]  = useState(SelectedPackageConstants.SILVER)
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

//   const [clientSecret, setClientSecret] = useState("sk_test_51Ooj13E9g5lMqEuaZrWXcCG6wOucFFKK4K4Wx2aFAFsDoT9xzbZ4b2aUXleAeWUiHMYNN1Pabf7pwMtGNoyzsIPe002iJayVqG");
  const stripe = useStripe();
  const elements = useElements();

  const cardStyle = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        border: "1px solid #FFFFFF",
        fontSize: "16px",
        "::placeholder": {
          color: "#ffffff",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async () => {
    setLoading(true)
    setProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${'test'} ${'last name'}`,
          email: 'hemmo@gmail.com'
        }
      });

      let payload = {
        Amount: (selectedPackage == SelectedPackageConstants.BRONZE ? 10 : selectedPackage == SelectedPackageConstants.SILVER ? 15 : 30),
        PaymentMethod: paymentMethod.id
      }

      await chargeYourWallet(payload)
      setLoading(false)
    if (error) {
      setLoading(false)
      Error(`Payment failed ${error.message}`);
      setProcessing(false);
    } else {
      setLoading(false)
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  return (
    <>
        <div className="my-3">
            <div className="">Select package</div>
            <div className="my-6 flex items-center gap-2 overflow-x-auto">
                <PlatformPackageCard isActive={selectedPackage == SelectedPackageConstants.BRONZE} amount={10} onClick={()=>setSelectedPackage(SelectedPackageConstants.BRONZE)} name={'Bronze'} />
                <PlatformPackageCard isActive={selectedPackage == SelectedPackageConstants.SILVER} amount={15} onClick={()=>setSelectedPackage(SelectedPackageConstants.SILVER)} name={'Silver'} />
                <PlatformPackageCard isActive={selectedPackage == SelectedPackageConstants.GOLD} amount={30} onClick={()=>setSelectedPackage(SelectedPackageConstants.GOLD)} name={'Gold'} />                    
            </div>
        </div>
        <form id="payment-form">
            <div className="border border-1 border-white p-3 rounded">
                <CardElement
                    id="card-element"
                    options={cardStyle}
                    onChange={handleChange}
                    />
            </div>
            <div className=" my-3">
                <Button onClick={handleSubmit} classes={'w-full font-semibold'} isActive={!processing} isDisabled={loading} buttonText={'Pay Now'} />
            </div>
        {error && (
            <div className="card-error" role="alert">
            {error}
            </div>
        )}
        {/* Show a success message upon completion */}
        {/* <p className={succeeded ? "result-message" : "result-message hidden"}>
            Payment succeeded, see the result in your
            <a href={`https://dashboard.stripe.com/test/payments`}>
            {" "}
            Stripe dashboard.
            </a>{" "}
            Refresh the page to pay again.
        </p> */}
        </form>
    </>
  );
}
