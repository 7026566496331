import { create } from "zustand";
import { CreateRating, FetchCreatorRating } from "../../service/rating/rating";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { FaRegStar, FaRegUser } from "react-icons/fa6";
import * as moment from "moment";
import { FetchQuizCategories } from "../../service/quizCategory/quizCategory";
import { FetchQuizByCategory } from "../../service/quiz/quiz";
import { FetchQuestionsByQuiz } from "../../service/question/question";
import { useQuizStore } from "../quiz/quiz";
import { FetchQuizAttemptsStats, FetchQuizComparisonByCategory } from "../../service/analytics/analytics";

export const useAnalyticsStore = create((set, get) => ({
    quizComparison: null,
    userPerformance: null,
    avgUserPerformance: null,
    quizAttempts: null,
    answerCountByLevelMode: null,

    async fetchQuizComparisonByCategory(categoryId){
        try {
            let {UserPerformance, AvgPerformance} = await FetchQuizComparisonByCategory(categoryId)
            set(()=>({userPerformance: UserPerformance, avgUserPerformance: AvgPerformance}))
            // Access and update the quizCategory from the quiz store
            // useQuizStore.getState().setQuizCategory(Category);
            return {
                UserPerformance: UserPerformance,
                AvgPerformance: AvgPerformance
            }
        } catch (error) {
            console.log(error)
        }
    },
    
    async fetchQuizAttemptsStats(){
        try {
            let { levelPercentages, levelCounts } = await FetchQuizAttemptsStats()
            set(()=>({quizAttempts:levelPercentages, answerCountByLevelMode: levelCounts}))
            
            return { levelPercentages, levelCounts }
        } catch (error) {
            console.log(error)
        }
    }
}))