import { Delete, Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const GetQuizProfile = async() => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quizProfile/`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const GetQuizRanking = async(body) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quizProfile/rankings/` ,Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}