import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FaCalendarAlt, FaFacebookF, FaYoutube } from 'react-icons/fa';
import { FaInstagram, FaLinkedin, FaLinkedinIn, FaSquareInstagram } from 'react-icons/fa6';
import { FiYoutube } from 'react-icons/fi';
import { useAccountStore } from '../../store/account/account';
import { useNavigate, useParams } from 'react-router-dom';
import { DurationToChargesMapping, TypeToChargeMapping } from '../../config/constants/charges';
import { SetToLocalStorage } from '../../config/helpers/local-storage-accessor-helper';
import { SocialAccountTypes } from '../../config/constants/socialAccounts';
import { SiDiscord } from "react-icons/si";
import { BsTwitterX } from "react-icons/bs";
import { ExperienceCard } from '../ExperienceCard/ExperienceCard';
import { EmptyData } from '../EmptyData/EmptyData';
import { EducationCard } from '../EducationCard/EducationCard';
import { CreatorCategoryConstants } from '../../config/constants/account';
import { GiCheckeredDiamond, GiGoldBar } from 'react-icons/gi';

let Card = ({ amount, duration }) => {
    let navigate = useNavigate()
    let {id} = useParams()

    let handleDuration = () => {
        return DurationToChargesMapping[duration]
    }

    let handlePlanName = () => {
        return TypeToChargeMapping[duration]
    }

    let handleNavigation = () => {
        SetToLocalStorage({meetingType: duration, creatorAccount: id})
        let isAccountLogin = localStorage.getItem('accountId')
        if(isAccountLogin){
            navigate(`/creator/${id}`)
        } else {
            navigate(`/auth/login`)
        }
    }

    return <div className='col-span-3 md:col-span-1 bg-[#252836] rounded-2xl px-5 py-5 min-w-[250px]'>
        <div className="text-white font-bold text-xl">
            {handleDuration()}-{handlePlanName()}
        </div>
        <div className="mt-10">
            <div onClick={handleNavigation} className="rounded-xl bg-[#202225] hover:bg-[#8254F8] cursor-pointer ease-in-out duration-300 flex justify-between items-center p-4 ">
                <div className="flex gap-x-2">
                    <FaCalendarAlt className='text-[#f1f1f1] text-[40px]' />
                    <div className="">
                        <div className="text-lg font-bold">{handleDuration()} mins</div>
                        <div className="font-light text-xs mt-[-2px]">Video Meeting</div>  
                    </div>
                </div>
                <div className="text-[#f1f1f1] text-lg font-bold">${amount}</div>
            </div>
        </div>
    </div>
}

const CreatorInfoComponent = props => {
    let { getCreatorInfo, creatorInfo } = useAccountStore()

    let {id} = useParams()

    useEffect(()=>{
        handleFetchData()
    }, [])

    let handleFetchData = async () => {
        await getCreatorInfo(id)
    }

    let handleNavigator = async (data) => {
        try {
            let url = data.Url.includes('https://') ? data.Url : `https://${data.Url}`;
            let a = document.createElement('a');
            a.href = url;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
          console.log(error);
        }
      };
    return <div className='text-white'>
        <div className="grid grid-cols-7">
            <div className="col-span-7 md:col-span-2 bg-[#202225] h-[fit-content] w-full md:min-h-[100vh] md:h-[100%] flex flex-col justify-start items-center md:items-start px-10 py-10 md:py-[70px]">
                {creatorInfo?.User?.ProfilePicture ? <div className="relative rounded-full h-[90px] w-[90px] md:h-[200px] md:w-[200px] border-white border-2 fit-content flex justify-center items-center">
                    <img className='h-[80px] w-[80px] md:h-[180px] md:w-[180px] rounded-full' src={creatorInfo?.User?.ProfilePicture} alt="" />
                    {creatorInfo?.Account && <div className='absolute top-[-10px] md:top-1 right-[-15px] md:right-[-20px] bg-[#242730] p-3  text-sm rounded-full flex justify-center items-center gap-1'>
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.PLATINUM && <GiGoldBar className='text-2xl md:text-5xl text-[#FFD700]' />}
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.GOLD && <GiGoldBar className='text-2xl md:text-5xl text-[#e5e4e2]' />}
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.DIAMOND && <GiCheckeredDiamond className='text-2xl md:text-5xl text-white' />}
                    </div>}
                </div>: <div className={`relative rounded-full h-[90px] w-[90px] md:h-[200px] md:w-[200px] border-white border-2 flex justify-center items-center text-[40px] md:text-[60px] font-bold bg-[${creatorInfo?.User?.Theme}]`}>
                    {creatorInfo?.User?.FirstName?.charAt(0)}
                    {creatorInfo?.Account && <div className='absolute top-[-10px] md:top-1 right-[-15px] md:right-[-20px] bg-[#242730] p-3  text-sm rounded-full flex justify-center items-center gap-1'>
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.GOLD && <GiGoldBar className='text-2xl md:text-5xl text-[#FFD700]' />}
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.PLATINUM && <GiGoldBar className='text-2xl md:text-5xl text-[#e5e4e2]' />}
                        {creatorInfo?.Account?.CreatorCategory === CreatorCategoryConstants.DIAMOND && <GiCheckeredDiamond className='text-2xl md:text-5xl text-white' />}
                    </div>}
                </div>}
                <div className="text-white font-bold text-[25px] mt-5 capitalize text-center md:text-left">
                    {(creatorInfo?.User?.FirstName|| '') + ' ' + (creatorInfo?.User?.LastName || '')+'.'}
                </div>
                {/* <div className="text-white font-semibold text-[16px] mt-5 text-center md:text-left">
                I broke into data with a non-technical background. Let me help you do the same.
                </div> */}
            </div>
            <div className="col-span-7 md:col-span-5 pt-[30px] md:pt-[70px] px-5 md:px-[60px] md:h-[100vh] overflow-y-scroll">
                <div className="font-semibold text-[25px] mb-3">
                    Plan
                </div>
                <div className="">
                    <div className="grid grid-cols-3 gap-4">
                        {creatorInfo?.Charges?.map(data => (
                            <Card amount={data.Amount} duration={data.Duration} />
                        ))}
                    </div>
                </div>
                <div className="mt-5 text-white">
                    <div className="font-semibold text-[25px]">
                        About me
                    </div>
                    <div className="my-3 flex justify-start items-center gap-x-3">
                        {
                            creatorInfo?.SocialAccounts.filter(data => data.Url)?.map(data => (
                                <div onClick={()=>handleNavigator(data)}>
                                    <div className="p-3 bg-[#252836] rounded-xl">
                                        {data.Url !== '' && data.Type === SocialAccountTypes.instagram && <FaInstagram className='text-[30px] cursor-pointer' />}
                                        {data.Url !== '' && data.Type === SocialAccountTypes.linkedIn && <FaLinkedinIn className='text-[30px] cursor-pointer' />}
                                        {data.Url !== '' && data.Type === SocialAccountTypes.youtube && <FaYoutube className='text-[30px] cursor-pointer' />}
                                        {data.Url !== '' && data.Type === SocialAccountTypes.discord && <SiDiscord className='text-[30px] cursor-pointer' />}
                                        {data.Url !== '' && data.Type === SocialAccountTypes.facebook && <FaFacebookF className='text-[30px] cursor-pointer' />}
                                        {data.Url !== '' && data.Type === SocialAccountTypes.twitter && <BsTwitterX className='text-[30px] cursor-pointer' />}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className="text-lg mb-5">
                        {creatorInfo?.User?.Description}
                    </div>
                    {creatorInfo?.Experience.length > 0 && <>
                    <div className="font-semibold text-[25px] mb-5">
                        Experience
                    </div>
                    <div className='grid grid-cols-1 gap-4 mb-5'>
                    {creatorInfo?.Experience.length > 0 && 
                        creatorInfo?.Experience.map((experience, index) => (
                        <ExperienceCard
                            Data={experience}
                            key={experience._id}
                            Organization={experience?.Organization}
                            Designation={experience?.Designation}
                            Location={experience?.Location}
                            StartDate={experience?.StartDate}
                            EndDate={experience?.EndDate}
                            IsCurrentJob={!experience?.EndDate}
                            JobDescription={experience?.JobDescription}
                        />
                        ))
                    }
                    </div>
                    </>}
                    {creatorInfo?.Education.length > 0 && <>
                    <div className="font-semibold text-[25px] mb-5">
                        Education
                    </div>
                    <div className='grid grid-cols-1 gap-4 mb-5'>
                    {creatorInfo?.Education.length > 0 && 
                        creatorInfo?.Education.map((data, index) => (
                            <EducationCard
                                Data={data}
                                School={data.School}
                                Degree={ data.Degree}
                                FieldOfStudy={ data.FieldOfStudy}
                                StartDate={data.StartDate}
                                EndDate={data.EndDate}
                                Description={data.Description}
                                ShowUpdate = {false}
                          />
                        ))
                    }
                    </div>
                    </>}
                </div>
            </div>
        </div>
    </div>;
};

CreatorInfoComponent.propTypes = {};

export { CreatorInfoComponent };