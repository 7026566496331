import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Charges } from "../Charges/Charges";
import { SyncCalendar } from "../SyncCalendar/SyncCalendar";
import { Separator } from "../Separator/Separator";
import { Button } from "../Button/Button";
import StripeSvg from "../../assets/svg/Stripe.svg";
import AsyncSelect from "react-select/async";
import { useChargesStore } from "../../store/charges/charges";
import { LiaCheckCircle } from "react-icons/lia";
import { PiCheckBold } from "react-icons/pi";
import { useAuthStore } from "../../store/auth/auth";
import { BecomePartner } from "../BecomeCreator/BecomeCreator";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Error, Success } from "../../config/helpers/toast-helper";
import { Expertise } from "../../pages/Expertise/Expertise";
import { useInterestStore } from "../../store/interest/interest";
import { CreatorScreening } from "../CreatorScreening/CreatorScreening";
import { useAccountStore } from "../../store/account/account";
import axios from "axios";
import { useVideoStore } from "../../store/video/video";
import { VideoStatusConstants, VideoTypeConstants } from "../../config/constants/video";
import { stripeCountries } from "../../config/constants/stripeCountries";

const AccountSettingTabs = {
  EXPERTISE: 0,
  SCREENING: 1,
  CHARGES: 2,
  CALENDAR: 3,
  STRIPE: 4,
};

const AccountSettingComponent = (props) => {
  let { connectWithStripe } = useChargesStore();
  let { getSyncAccountApps, account } = useAuthStore();
  let { updatedExpertise } = useInterestStore();
  let { getUploadUrl, getObjectUrl } = useAccountStore()
  let { createVideoRecord } = useVideoStore()

  const [region, setRegion] = useState({});
  const [currentTab, setCurrentTab] = useState(AccountSettingTabs.EXPERTISE);
  const [chargesStatus, setChargesStatus] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);
  const [selected, setSelected] = useState({});
  const [video, setVideo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    getAccountInfo();
    if (searchParams.get("configure") == AccountSettingTabs.CALENDAR) {
      setCurrentTab(AccountSettingTabs.CALENDAR);
    }
    if (searchParams.get("configure") == AccountSettingTabs.STRIPE) {
      setCurrentTab(AccountSettingTabs.STRIPE);
    }
  }, []);

  let getAccountInfo = async () => {
    let accountResponse = await getSyncAccountApps();
    setAccountInfo(accountResponse?.AccountInfo);
  };

  let syncWithStripe = async () => {
    try {
      if (!region.value) {
        Error("Kindly select your bank region");
        return;
      }
      setDisabled(true)
      let payload = {
        BusinessRegion: region.value,
      };
      await connectWithStripe(payload);
      setDisabled(false)
    } catch (error) {
      console.log(error);
    }
  };

  const filterColors = (inputValue) => {
    return stripeCountries.filter((data) =>
      data.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  let handleNextClick = async () => {
    setLoading(true);
    if (currentTab == AccountSettingTabs.EXPERTISE) {
      if (isButtonActive()) {
        handleContinue();
        setCurrentTab(() => AccountSettingTabs.SCREENING);
      }
      setLoading(false)
      return;
    }
    if (currentTab == AccountSettingTabs.SCREENING) {
      if (isButtonActive()) {
        if(video){
          let file = new File([video], `assessment-video-${account._id}-${new Date().toISOString()}`, { type: video.type,    lastModified: Date.now() })
          let s3ObjectKey = `assessment-video-${account._id}-${new Date().toISOString()}`
          let url = await getUploadUrl(s3ObjectKey)
          try {
            const response = await axios.put(url, file, {
              headers: {
                'Content-Type': file.type ?? 'multipart/form-data',
              },
            });

            let payload = {
              S3ObjectKey: s3ObjectKey,
              Type: VideoTypeConstants.ASSESSMENT
            }
            await createVideoRecord(payload)
            await getAccountInfo()
            Success('Video under review. Feedback soon')
            navigate("/account/setting")
            return
          } catch (error) {
            console.log(error.response.data)
          }
        }
        setCurrentTab(() => AccountSettingTabs.CHARGES);
      }
      setLoading(false)
      return;
    }
    if (currentTab == AccountSettingTabs.CHARGES) {
      if (isButtonActive()) {
        setCurrentTab(AccountSettingTabs.CALENDAR);
      }
      setLoading(false)
      return;
    }
    if (currentTab == AccountSettingTabs.CALENDAR) {
      if (isButtonActive()) {
        setCurrentTab((prev) => prev + 1);
      }
      setLoading(false)
      return;
    }
    if (currentTab == AccountSettingTabs.STRIPE) {
      setCurrentTab(AccountSettingTabs.EXPERTISE);
      navigate("/account/setting");
      setLoading(false)
      return;
    }
  };

  let handlePrevClick = () => {
    if (currentTab == AccountSettingTabs.EXPERTISE) {
      // handleContinue()
      // setCurrentTab((prev) => prev + 1);
      navigate("/account/setting");

      return;
    }

    if (currentTab == AccountSettingTabs.SCREENING) {
      setCurrentTab((prev) => prev - 1);
      return;
    }

    if (currentTab == AccountSettingTabs.CHARGES) {
      setCurrentTab((prev) => prev - 1);
      return;
    }

    if (currentTab == AccountSettingTabs.CALENDAR) {
      setCurrentTab((prev) => prev - 1);

      return;
    }
    if (currentTab == AccountSettingTabs.STRIPE) {
      setCurrentTab((prev) => prev - 1);
      return;
    }
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterColors(inputValue));
    // filterColors(inputValue)
  };

  function isButtonActive() {
    if (currentTab == AccountSettingTabs.EXPERTISE) {
      return (
        Object.keys(selected).length > 0 && Object.keys(selected).length <= 5
      );
    }
    if (currentTab == AccountSettingTabs.SCREENING) {
      return (video || account.Screening === VideoStatusConstants.ACCEPTED) ? true : false;
    }
    if (currentTab == AccountSettingTabs.CHARGES) {
      return chargesStatus;
    }
    if (currentTab == AccountSettingTabs.CALENDAR) {
      return accountInfo?.IsCalendarSync;
    }
    if (currentTab == AccountSettingTabs.STRIPE) {
      return accountInfo.IsStripeSync;
    }
  }

  let handleContinue = async () => {
    let payload = {
      Skills: Object.keys(selected).map((data) => data),
    };
    await updatedExpertise(account._id, payload);
  };

  return (
        <>
          <div className="flex items-center justify-center gap-x-5 w-[1000px] my-5 mt-[70px] mx-auto">
            <div className="flex gap-x-1 items-center">
              <div
                className={`rounded-full w-[30px] h-[30px] flex justify-center items-center ${
                  currentTab >= AccountSettingTabs.EXPERTISE
                    ? "bg-[#8254F8]"
                    : "bg-white"
                } `}
              >
                {" "}
                {currentTab > AccountSettingTabs.EXPERTISE ? (
                  <PiCheckBold />
                ) : (
                  <span className="font-semibold text-sm"> 1 </span>
                )}
              </div>
              <div className="text-white">Skills</div>
            </div>
            <div
              className={`h-[1px] flex-grow ${
                currentTab >= AccountSettingTabs.SCREENING
                  ? "bg-[#8254F8]"
                  : "bg-white"
              } `}
            ></div>
            <div className="flex gap-x-1 items-center">
              <div
                className={`rounded-full w-[30px] h-[30px] flex justify-center items-center ${
                  currentTab >= AccountSettingTabs.SCREENING
                    ? "bg-[#8254F8]"
                    : "bg-white"
                } `}
              >
                {" "}
                {currentTab > AccountSettingTabs.SCREENING ? (
                  <PiCheckBold />
                ) : (
                  <span
                    className={`font-semibold text-sm ${
                      currentTab == AccountSettingTabs.SCREENING
                        ? "text-white"
                        : "text-[#8254F8]"
                    } `}
                  >
                    {" "}
                    2{" "}
                  </span>
                )}
              </div>
              <div className="text-white">Screening</div>
            </div>
            <div
              className={`h-[1px] flex-grow ${
                currentTab >= AccountSettingTabs.CHARGES
                  ? "bg-[#8254F8]"
                  : "bg-white"
              } `}
            ></div>

            <div className="flex gap-x-1 items-center">
              <div
                className={`rounded-full w-[30px] h-[30px] flex justify-center items-center ${
                  currentTab >= AccountSettingTabs.CHARGES
                    ? "bg-[#8254F8]"
                    : "bg-white"
                } `}
              >
                {" "}
                {currentTab > AccountSettingTabs.CHARGES ? (
                  <PiCheckBold />
                ) : (
                  <span
                    className={`font-semibold text-sm ${
                      currentTab == AccountSettingTabs.CHARGES
                        ? "text-white"
                        : "text-[#8254F8]"
                    } `}
                  >
                    {" "}
                    3{" "}
                  </span>
                )}
              </div>
              <div className="text-white">Charges</div>
            </div>
            <div
              className={`h-[1px] flex-grow ${
                currentTab >= AccountSettingTabs.CALENDAR
                  ? "bg-[#8254F8]"
                  : "bg-white"
              } `}
            ></div>
            <div className="flex gap-x-1 items-center">
              <div
                className={`rounded-full w-[30px] h-[30px] flex justify-center items-center ${
                  currentTab >= AccountSettingTabs.CALENDAR
                    ? "bg-[#8254F8]"
                    : "bg-white"
                }`}
              >
                {currentTab > AccountSettingTabs.CALENDAR ? (
                  <PiCheckBold />
                ) : (
                  <span
                    className={`font-semibold text-sm ${
                      currentTab == AccountSettingTabs.CALENDAR
                        ? "text-white"
                        : "text-[#8254F8]"
                    } `}
                  >
                    {" "}
                    4{" "}
                  </span>
                )}
              </div>
              <div className="text-white">Sync Calendar</div>
            </div>
            <div
              className={`h-[1px] flex-grow ${
                currentTab >= AccountSettingTabs.STRIPE
                  ? "bg-[#8254F8]"
                  : "bg-white"
              } `}
            ></div>

            <div className="flex gap-x-1 items-center">
              <div
                className={`rounded-full w-[30px] h-[30px] flex justify-center items-center ${
                  currentTab >= AccountSettingTabs.STRIPE
                    ? "bg-[#8254F8]"
                    : "bg-white"
                }`}
              >
                {currentTab > AccountSettingTabs.STRIPE ? (
                  <PiCheckBold />
                ) : (
                  <span className={`font-semibold text-sm ${
                    currentTab == AccountSettingTabs.STRIPE
                      ? "text-white"
                      : "text-[#8254F8]"
                  } `}>
                    {" "}
                    5{" "}
                  </span>
                )}
              </div>
              <div className="text-white">Connect Stripe</div>
            </div>
            {/* <div className="rounded-full w-[40px] h-[40px] bg-white"></div>
      <div className="rounded-full w-[40px] h-[40px] bg-white"></div> */}
          </div>
          {currentTab == AccountSettingTabs.EXPERTISE ? (
            <Expertise
              handleContinue={handleContinue}
              selected={selected}
              setSelected={setSelected}
            />
          ) : null}
          {currentTab == AccountSettingTabs.SCREENING ? (
            <CreatorScreening
              setVideo={setVideo}
            />
          ) : null}
          {currentTab == AccountSettingTabs.CHARGES ? (
            <Charges setChargesStatus={setChargesStatus} />
          ) : null}
          {currentTab == AccountSettingTabs.CALENDAR ? <SyncCalendar /> : null}
          {currentTab == AccountSettingTabs.STRIPE ? (
            <div className="bg-[#181818] text-white p-5 rounded-lg my-5">
              <div className="text-lg">Stripe Connect Account Setup:</div>
              <div className="my-6 text-sm font-light">
                By seamlessly integrating your Discoursefy account with Stripe
                Connect, you gain the ability to effortlessly manage your
                payment workflows. This powerful integration simplifies the
                process of receiving earnings for your valuable contributions,
                allowing you to concentrate fully on delivering top-notch
                content to your audience. Should you encounter any inquiries or
                require guidance during the setup phase, our dedicated support
                team stands ready to provide assistance and ensure a smooth
                experience.
              </div>

              <div className="my-6">
                <Separator />
              </div>

              <div className="w-[280px]">
                {/* select bank region */}
                <AsyncSelect
                  theme={(theme) => ({
                    ...theme,
                    borderWidth: "0px",
                    marginTop: "10px",
                    colors: {
                      // ...theme.colors,
                      text: "orangered",
                      primary25: "hotpink",
                      primary: "white",
                    },
                  })}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderWidth: "0px",
                      borderColor: "#353B4B",
                      backgroundColor: "#353B4B",
                      border: "1px solid #353B4B",
                      boxShadow: 0,
                      color: "#ffffff",
                      fontSize: "13px",
                      marginBottom: "1px",
                      ":active": {
                        border: "1px solid #353B4B",
                      },
                      ":hover": {
                        border: "1px solid #353B4B",
                      },
                    }),
                    option: () => ({
                      cursor: "pointer",
                      backgroundColor: "#353B4B",
                      fontSize: "13px",
                      padding: "3px 6px",
                      color: "#ffffff",
                      ":hover": {
                        backgroundColor: "#8254F8",
                      },
                      ":active": {
                        backgroundColor: "#8254F8",
                      },
                    }),
                    menu: () => ({
                      marginTop: "0px",
                      background: "#353B4B",
                    }),
                  }}
                  // value={""}
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions = {stripeCountries}
                  onChange={(selectedOption) => setRegion(selectedOption)}
                  className="bg-black text-white"
                />

                <div className="my-5">
                  <Button
                    onClick={syncWithStripe}
                    classes={"text-sm font-semibold w-full w-[280px]"}
                    isImage={true}
                    isDisabled={disabled}
                    buttonText={"Connect with Stripe"}
                    image={StripeSvg}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex justify-end items-center my-4 gap-x-4">
            <Button
              buttonText={
                currentTab == AccountSettingTabs.EXPERTISE ? "Cancel" : "Back"
              }
              classes={"w-[100px]"}
              onClick={handlePrevClick}
            />
            <Button
              isActive={isButtonActive()}
              disabled={!isButtonActive() || loading}
              buttonText={
                currentTab == AccountSettingTabs.STRIPE ? "Done" : "Next"
              }
              classes={"w-[100px]"}
              onClick={handleNextClick}
            />
          </div>
    </>
  );
};

AccountSettingComponent.propTypes = {};

export { AccountSettingComponent };
