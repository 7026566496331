import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Banner } from "../Banner/Banner";
import { CreatorInfoCard } from "../CreatorInfoCard/CreatorInfoCard";
import { Education } from "../Education/Education";
import { Experience } from "../Experience/Experience";
import { Interest } from "../Interest/Interest";
import { CreateMeeting } from "../CreateMeeting/CreatingMeeting";
import { useMeetingStore } from "../../store/meeting/meeting";
import { useParams } from "react-router-dom";
import { useExperienceStore } from "../../store/experience/experience";
import { useEducationStore } from "../../store/education/education";
import { useInterestStore } from "../../store/interest/interest";
import { Separator } from "../Separator/Separator";
import { Button } from "../Button/Button";
import Loader from '../../assets/gif/buttonLoader.gif'


let CreatorProfileTabs = {
  Education: "Education",
  Experience: "Experience",
  Interest: "Interest",
};

const Creator = (props) => {
  let { fetchCreatorInfo, creatorInfo } = useMeetingStore();
  let { getCreatorExperience } = useExperienceStore();
  let { getCreatorEducation } = useEducationStore();
  let { getInterest } = useInterestStore();

  const [state, setState] = useState();
  const [tab, setTab] = useState("Education");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    let isMeetingTypeThere = localStorage.getItem("meetingType"); // if meeting type is there then we need to open modal and clear it from localStorage
    if (isMeetingTypeThere) {
      setShowModal(true);
      localStorage.removeItem("meetingType");
      localStorage.removeItem("creatorAccount");
    }
    getCreatorInfo();
  }, [id]);

  useEffect(() => {
    fetchTabData();
  }, [id, tab]);

  async function getCreatorInfo() {
    setLoading(true);
    await fetchCreatorInfo(id);
    setLoading(false);
  }

  async function fetchTabData() {
    if (tab == CreatorProfileTabs.Experience) {
      await getCreatorExperience(id);
    } else if (tab == CreatorProfileTabs.Education) {
      await getCreatorEducation(id);
    } else if (tab == CreatorProfileTabs.Interest) {
      await getInterest(id);
    }
  }

  function handleTabChange(value) {
    setTab(value);
  }

  function handleTab() {
    switch (tab) {
      case CreatorProfileTabs.Education:
        return <Education />;
      case CreatorProfileTabs.Experience:
        return <Experience />;
      case CreatorProfileTabs.Interest:
        return <Interest />;
    }
  }
  return !loading ? (
    <div className="mx-5">
      <CreateMeeting
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        isUpdate={false}
      />
      <>
        <Banner />
        <CreatorInfoCard
          scheduleCall={() => setShowModal(true)}
          selectedTab={tab}
          handleTabChange={handleTabChange}
        ></CreatorInfoCard>
        <div className="bg-[#181818] px-6 py-3 mt-6 rounded-xl mb-5">
          <div className="text-white font-normal text-[25px]">{tab}</div>
          <div className="my-4">
            <Separator />
          </div>
          <div className="">{handleTab()}</div>
        </div>
      </>
    </div>
  ) : (
    <div
      style={{ height: "calc(100vh - 62px)" }}
      className="flex justify-center items-center"
    >
      <img src={Loader} />
    </div>
  );
};

export default Creator;
