import { Delete, Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const FetchQuizByCategory = async(id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/quiz/getQuizByCategoryId/${id}` ,Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}