import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import LanguagesDropdown from "./LanguagesDropdown";
const CodeEditorWindow = ({
  onChange,
  language,
  code,
  theme,
  defaultValue,
  onSelectChange,
  role = "",
}) => {
  const [value, setValue] = useState(code || "");
  const handleEditorChange = (newValue) => {
    if (role == "Navigator") {
      return;
    }
    setValue(newValue);
    onChange("code", newValue);
  };

  useEffect(() => {
    console.log(defaultValue);
    // Reset value whenever defaultValue changes
    setValue(defaultValue || "");
  }, [defaultValue]);

  const roleStyles = {
    Driver: "font-light",
    Navigator: "bg-gradient-to-r from-green-300 to-green-600",
  };

  const currentStyle = role.length ? roleStyles[role] : "";

  return (
    <div className="rounded-md overflow-hidden w-full bg-[#1B2B34]">
      <div className="flex justify-between items-center font-semibold bg-gray-700 py-2 px-3">
        <span>Code Editor</span>
        <div className="flex items-center gap-x-3">
          <div className={`role-container font-light`}>
            {role.length ? (
              <span className="role-text animate-pulse">{role}</span>
            ) : (
              <></>
            )}
          </div>
          <LanguagesDropdown
            onSelectChange={onSelectChange}
            language={language}
          />
        </div>
      </div>
      <div className="">
        <Editor
          height="50vh"
          width={`100%`}
          language={language?.value}
          value={value}
          theme={theme}
          onChange={handleEditorChange}
          options={{
            readOnly: role == "Navigator", // Disable editor based on prop
            cursorBlinking: true,
            automaticLayout: true,
            value: value,
          }}
        />
      </div>
    </div>
  );
};

export default CodeEditorWindow;
