import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { VideoRecorder } from "../VideoRecorder/VideoRecorder";
import { useAuthStore } from "../../store/auth/auth";
import { VideoStatusConstants } from "../../config/constants/video";
import { useInterestStore } from "../../store/interest/interest";

const CreatorScreening = ({ setVideo }) => {
  let { getExpertise, expertise } = useInterestStore();
  let { getSyncAccountApps, account } = useAuthStore();

  useEffect(() => {
    fetchInterest();
  }, []);

  let fetchInterest = async () => {
    await getExpertise(account._id);
  };

  function formatSkills() {
    let skills = expertise.map((interest) => interest.Label);
    if (skills.length === 0) {
      return "";
    } else if (skills.length === 1) {
      return skills[0];
    } else {
      return (
        skills.slice(0, -1).join(", ") + ", and " + skills[skills.length - 1]
      );
    }
  }

  return (
    <div className="bg-[#181818] text-white p-5 rounded-lg my-5">
      <div className="text-lg">Tell Us About Yourself</div>
      <div className="my-3">
        <Separator />
      </div>
      <div className="my-6 text-md font-light text-left">
        In this section, we want to get to know you better. Please record a
        video highlighting your achievements and share your expertise in{" "}
       <b> {formatSkills()} </b>. Focus on your key accomplishments, experiences, and
        any unique qualities that set you apart. This is your chance to make a
        great first impression!
      </div>
      <>
        {!account.Screening && (
          <div className="flex justify-center items-center">
            <VideoRecorder setVideo={setVideo} />
          </div>
        )}
        {account.Screening === VideoStatusConstants.PENDING && (
          <div className="font-bold">
            Your video is currently under review. We appreciate your patience
            and will get back to you with feedback soon.
          </div>
        )}
        {account.Screening === VideoStatusConstants.ACCEPTED && (
          <div className="font-bold">
            Congratulations! Your video has been reviewed and accepted. Thank
            you for sharing your achievements and expertise. We are excited to
            have you on board!
          </div>
        )}
        {account.Screening === VideoStatusConstants.REJECTED && (
          <div className="font-bold">
            Unfortunately, your video did not meet our requirements at this
            time. Please review the guidelines and try again. You can reapply
            after 30 days. We look forward to seeing your resubmission and
            learning more about your achievements and expertise.
          </div>
        )}
      </>
    </div>
  );
};

CreatorScreening.propTypes = {};

export { CreatorScreening };
