import React, { useState } from "react";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import PasswordEye from "../../assets/svg/PasswordEye.svg";
import { Error } from "../../config/helpers/toast-helper";
import { useAuthStore } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";
import { AccountStatusConstants } from "../../config/constants/account";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    setLoading(true);
    if (!handleDisableButton()) {
      setLoading(false);
      return;
    }
    if (password !== confirmPassword) {
      Error("Passwords do not match");
      setLoading(false);
      return;
    }
    let data = {
      FirstName: name,
      Email: email,
      Password: password,
    };
    let { user, account } = await useAuthStore.getState().signUp(data);
    setLoading(false);

    if (!account) return;
    navigate("/auth/account/activation");
    // if (account.Status == AccountStatusConstants.INACTIVE) {
    // } else {
    //   navigate("/");
    // }
  };

  let handleDisableButton = () => {
    if (
      !name.length ||
      !email.length ||
      !password.length ||
      !confirmPassword.length
    ) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <form handleSubmit={handleSubmit} class="flex flex-col gap-4">
        <Input
          placeholder={"Name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          classes={"py-3 text-sm"}
        />
        <Input
          placeholder={"Email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type={"email"}
          classes={"py-3 text-sm"}
        />
        <Input
          placeholder={"Password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          image={PasswordEye}
          onImageClick={() => setShowPassword(!showPassword)}
          classes={"py-3 text-sm"}
        />
        <Input
          placeholder={"Confirm Password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type={showConfirmPassword ? "text" : "password"}
          image={PasswordEye}
          onImageClick={() => setShowConfirmPassword(!showConfirmPassword)}
          classes={"py-3 text-sm"}
        />
        <Button
          classes={"text-sm font-semibold px-7"}
          isDisabled={loading}
          onClick={handleSubmit}
          fullWidth={true}
          isActive={handleDisableButton()}
          buttonText={"Sign Up"}
        />
      </form>
    </div>
  );
};

export default SignUp;
