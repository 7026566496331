import { create } from "zustand";
import {
  CreatePlanSubscription,
  FetchPaidAccountPlans,
  FetchPlans,
  GetInvoiceInfoById,
  IsPlanSubscribed,
  UpdateCardInfo,
} from "../../service/plans/plans";
import moment from "moment/moment";
import { Link } from "react-router-dom";

export const usePlanStore = create((set, get) => ({
  plans: [],
  paidAccountPlans: [],
  currentPlan: null,
  selectedInvoice: null,

  async fetchPlans() {
    try {
      let Plans = await FetchPlans();
      set(() => ({ plans: Plans }));
      return Plans;
    } catch (error) {
      console.log(error.message);
    }
  },

  async createPlanSubscription(data) {
    try {
      return await CreatePlanSubscription(data);
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  },

  async updateCardInfo(data) {
    try {
      return await UpdateCardInfo(data);
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  },

  async isPlanSubscribed() {
    try {
      return await IsPlanSubscribed();
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  },

  // invoices
  async fetchPaidAccountPlans() {
    try {
      let PaidAccountPlans = await FetchPaidAccountPlans();

      let planInfo = PaidAccountPlans.invoiceData.map((data) => ({
        plan: (
          <div className="cursor-default">
            <Link
              to={`/account/billing/invoice/${data._id}`}
              className="cursor-pointer font-semibold"
            >
              {" "}
              {data.planInfo.Name}{" "}
            </Link>{" "}
          </div>
        ),
        price: (
          <div className="cursor-default">
            {" "}
            ${data.planInfo.Price.toFixed(2)}{" "}
          </div>
        ),
        startDate: (
          <div className="cursor-default">
            {" "}
            {moment(data.StartDate).format("MMM-DD-YYYY")}{" "}
          </div>
        ),
        endDate: (
          <div className="cursor-default">
            {" "}
            {moment(data.EndDate).format("MMM-DD-YYYY")}{" "}
          </div>
        ),
      }));

      set(() => ({
        paidAccountPlans: planInfo,
        currentPlan: PaidAccountPlans.currentPlan,
      }));
      return planInfo;
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  },

  async getInvoiceInfoById(id) {
    try {
      let Invoice = await GetInvoiceInfoById(id);
      set(() => ({ selectedInvoice: Invoice }));
    } catch (error) {
      console.log(error.message);
      throw new Error(error.message);
    }
  },
}));
