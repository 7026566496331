import { Get, baseUrl } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export async function GetAccountSocialLinks(id){
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/social_account/getByAccount/${id}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}
