import { create } from "zustand";
import { GetAllSkills, GetCreatorExpertise, GetNestedSkills } from "../../service/skill/skills";
import { GetAccountSocialLinks } from "../../service/socialAccounts/socialAccounts";

export const useSocialAccountStore = create((set, get) => ({
    userSocialAccounts: [],
    creatingDiscussion: false,
  
    async getSocialAccountInfo (accountId) {
      try {
          let socialAccounts = await GetAccountSocialLinks(accountId)
          set({userSocialAccounts: socialAccounts})
          return socialAccounts
      } catch (error) {
          console.log(error)
      }
    }
  }));