import { Get, Post, baseUrl } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const CreateQuizChallenge = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/quizPerformance`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetQuizChallenges = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/quizPerformance`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetQuizPerformanceReport = async (queryParams) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/quizPerformance/report`,
      Authorization,
      queryParams
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetQuizPerformanceWeeklyInsights = async (
  accountId,
  startTime,
  endTime
) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/quizPerformance/weeklyInsights?accountId=${accountId}&startTime=${startTime}&endTime=${endTime}`,
      Authorization
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const IsWeekAlreadyTracing = async () => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(
      `${baseUrl}/quizPerformance/isWeekAlreadyTracing`,
      Authorization
    );
  } catch (error) {
    console.log(error);
    throw error;
  }
};
