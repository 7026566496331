import { create } from "zustand";
import { CreateRating, FetchCreatorRating } from "../../service/rating/rating";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { FaRegStar, FaRegUser } from "react-icons/fa6";
import * as moment from "moment";

export const useRatingStore = create((set, get) => ({
    creatorRatings: [],
    
    async createRating(data){
        try {
            let Rate = await CreateRating(data)
            return Rate
        } catch (error) {
            console.log(error)
        }
    },

    async fetchCreatorRating(data){
        try {
            let AccountRatings = await FetchCreatorRating(data)
            let ratingsObject = AccountRatings.map(data => ({
                userInfo: <div className="text-sm font-semibold flex gap-x-1 items-center"><span className="w-[30px] min-w-[30px] h-[30px] bg-[#7E5DEC] rounded-full flex justify-center items-center"> <FaRegUser /> </span> <span> {data.User.FirstName} {data.User.LastName ? data.User.LastName : ''}</span> </div>,
                entityTitle: <MeetingCell title={data.AssociatedMeetings.Title} desc={data.AssociatedMeetings.Description} />,
                rate: <div className="flex items-center gap-x-1">{data.Rate} <FaRegStar className="text-lg" /> </div>,
                date: <div>{moment(data.createdAt).format("DD-MMM-YYYY")}</div>
            }))
            set(()=>({creatorRatings: ratingsObject}))
            return AccountRatings
        } catch (error) {
            console.log(error)
        }
    }
}))