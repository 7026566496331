import { create } from "zustand";
import { GetAllSkills, GetCreatorExpertise, GetNestedSkills } from "../../service/skill/skills";

export const useSkillStore = create((set, get) => ({
    fetchedSkills: [],
    creatingDiscussion: false,
  
    async getAllSkills () {
      try {
          let skills = await GetAllSkills()
          let availableSkills = skills.map((skill)=> ({label: skill.Label, value: skill._id}))
          set({fetchedSkills: availableSkills})
          return availableSkills
      } catch (error) {
          console.log(error)
      }
    },

    async getCreatorExpertise () {
      try {
          let skills = await GetCreatorExpertise()
          let availableSkills = skills.map((skill)=> ({label: skill.Label, value: skill._id}))
          set({fetchedSkills: availableSkills})
          return availableSkills
      } catch (error) {
          console.log(error)
      }
    },

    async getNestedSkills(skillId){
      try {
        let skills = await GetNestedSkills(skillId)
        return skills
      } catch (error) {
        console.log(error)
      }
    }
  }));