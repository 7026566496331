import React, { useEffect, useRef, useState } from "react";
import { Table } from "../Table/Table";
import { useMeetingStore } from "../../store/meeting/meeting";
import { Button } from "../Button/Button";
import { MeetingFilters } from "../../config/constants/meeting";
import { Input } from "../Input/Input";

let MeetingCell = () => {
  let handleDescription = (desc) => {
    if (desc.length < 60) return desc;
    return desc.slice(0, 60) + "...";
  };
  return (
    <div>
      <div className="flex gap-2">
        {/* <div className="w-[240px] h-[130px] bg-[#80BCBD]"></div> */}
        <div className="text-sm text-white">
          <div className="font-semibold"> Meeting Title</div>{" "}
          <div className="text-xs text-[#AAAAAA]">
            {handleDescription(
              "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ducimus odio autem rerum eius nam obcaecati facilis delectus minus nesciunt, possimus similique vitae aliquid adipisci non quidem accusantium fugit! Alias assumenda cupiditate asperiores fuga modi quo quae adipisci ab qui est, nisi at ipsa in, doloremque repellat quaerat magni. Ipsam rerum repellat rem quas minima molestias illum odit ducimus aperiam nemo ab repudiandae, nostrum quibusdam reiciendis labore porro impedit commodi quis. Commodi consequatur aliquid eveniet nemo corrupti provident laudantium, sunt cumque repellat. Tempora blanditiis aut, totam eveniet modi repellendus, magni maxime quasi nobis itaque libero voluptate quo cum ad qui quod."
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MeetingComponent = (props) => {
  let { fetchAccountMeeting, accountMeetings, totalItems } = useMeetingStore();
  

  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(false)
  const [pageConfig, setPageConfig] = useState({
    currentPage: 1,
    limit: 10,
    sortBy: "ScheduleTime",
    sortOrder: 1,
    filteredBy: null,
    searchText: "",
    Upcoming: true
  });


  useEffect(() => {
    getAccountMeeting();
  }, [JSON.stringify(pageConfig)]);

  async function getAccountMeeting() {
    setLoading(true)
    await fetchAccountMeeting({...pageConfig, searchText: searchText});
    setLoading(false)
  }

  async function handleFilter(value) {
    setPageConfig({ ...pageConfig, filteredBy: value, currentPage: 1 });
  }

  async function handleNextPage() {
    if(Math.max(Math.ceil(totalItems / pageConfig.limit), 1) == pageConfig.currentPage){
      return
    }
    let nextPage = pageConfig.currentPage + 1;
    setPageConfig({ ...pageConfig, currentPage: nextPage });
  }

  async function handlePreviousPage() {
    if(pageConfig.currentPage == 1){
      return
    }
    let previousPage = pageConfig.currentPage - 1;
    setPageConfig({ ...pageConfig, currentPage: previousPage });
  }

  let timeoutId = useRef(null);

  const onChange = async (e) => {
    setSearchText(e.target.value);
    clearTimeout(timeoutId.current);
    if(e.target.value==''){
      await fetchAccountMeeting({...pageConfig})
    }

    // Set a new timeout to trigger the API call after 2 seconds
    timeoutId.current = setTimeout(async () => {
      if (e.target.value !== searchText) {

        await fetchAccountMeeting({...pageConfig, searchText: e.target.value})
      }
      setSearchText(e.target.value);
    }, 1000);
  };

  let headers = [
    {
      label: "Meetings",
      cols: 6,
      id: "meetings",
    },
    {
      label: "Plan",
      cols: 2,
      id: "plan",
    },
    {
      label: "Status",
      cols: 2,
      id: "status",
    },
    {
      label: "Schedule Date",
      cols: 2,
      id: "scheduleDate",
    },
  ];

  return (
    <div className="w-[100%] md:w-full rounded-lg bg-[#181818] py-5 px-2 md:p-5">
      <div className="text-lg"> Meeting's </div>

      <div className="w-[80%] md:w-[410px] my-3">
        <Input classes={'text-sm'} onChange={ onChange } value={searchText} placeholder={'Search with title'} />
      </div>

      <div className="flex justify-between flex-col md:flex-row">
        <div className="my-3 flex items-center gap-2 md:flex-wrap overflow-x-auto">
          {MeetingFilters.map((filter) => (
            <Button
              onClick={() => handleFilter(filter.value)}
              buttonText={filter.Title}
              isActive={pageConfig.filteredBy == filter.value}
              classes={"text-xs py-[5px] rounded-md"}
            />
          ))}
        </div>
        <div className="">
        <Button
            onClick={() => setPageConfig((prev => ({...prev, Upcoming: !prev.Upcoming})))}
            buttonText={'Upcoming'}
            isActive={pageConfig.Upcoming}
            classes={"text-xs py-[5px] rounded-md"}
          />
        </div>
      </div>
      <div className="overflow-auto w-[100%]">
        <Table
          headers={headers}
          meetings={accountMeetings}
          currentPage={pageConfig.currentPage}
          totalPages={Math.ceil(totalItems / pageConfig.limit) || 1}
          loading={loading}
          onNextPage={handleNextPage}
          onPreviousPage={handlePreviousPage}
          />
        </div>
    </div>
  );
};

MeetingComponent.propTypes = {};

export { MeetingComponent };
