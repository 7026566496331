import React, { useEffect, useRef, useState } from "react";
import SearchBar from "../../components/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import { useInterestStore } from "../../store/interest/interest";
import { useAuthStore } from "../../store/auth/auth";
import { Button } from "../../components/Button/Button";
import { Separator } from "../../components/Separator/Separator";
import { useSkillStore } from "../../store/skill/skills";
import Loader from '../../assets/gif/buttonLoader.gif'

const Expertise = ({setSelected, selected, handleContinue}) => {
    let { platformSkills, skills, updatedExpertise, getExpertise, interests, updateSkills } = useInterestStore()
    let { getNestedSkills } = useSkillStore()
    let { account, getAccountInfo } = useAuthStore()
  
  
    const [searchBar, setSearchBar] = useState("");
    const [searchText, setSearchText] = useState('')
    // const [selected, setSelected] = useState({})
    let [intrustedTopics, setIntrustedTopics] = useState([])
    const [loading, setLoading]  = useState(true)
    
    const navigate = useNavigate();
  
    useEffect(() => {
      fetchData()
    }, [account?._id])
  
    // useEffect(() => {
    //   if(account?._id){
    //     console.log('this line execute.')
    //     handleInterests()
    //   }
    // }, [])

    let fetchData = async ()=>{
      await getAccountInfo()
      await fetchPlatformSkills()
      if(account?._id){
        await handleInterests()
      }
      setLoading(false)
    }
  
    let handleInterests = async  () => {
      let userInterests = await getExpertise(account._id)
      let resp = {}
      userInterests.forEach(data => {
        resp[data._id]= true
      })
      setSelected(resp)
      updateSkills(-1, userInterests.map(data => ({name: data.Label, id: data._id})))
    }
  
    let timeoutId = useRef(null);
  
    const onChange = async (e) => {
      setSearchBar(e.target.value);
      clearTimeout(timeoutId.current);
      if(e.target.value==''){
        // await fetchPlatformSkills()
      }
  
      // Set a new timeout to trigger the API call after 2 seconds
      timeoutId.current = setTimeout(async () => {
        if (e.target.value !== searchText) {
          // Call your API here using the value
          await platformSkills(e.target.value)
        }
        setSearchText(e.target.value);
      }, 1000);
    };
  
    let fetchPlatformSkills = async () => { 
      await platformSkills()
    }
  
    const selectedOptions = async (topic, index) => {
      if(account?.Screening){
        return 
      }
      // Check if the topic is already selected
      if (selected[topic]) {
          // If selected, remove it from the selected topics
          const updatedSelected = { ...selected };
          delete updatedSelected[topic];
          setSelected(updatedSelected);
      } else {
        if(searchText.length>0){
          let currentTopic = skills.find(data => data.id == topic)
          setSelected({ ...selected, [topic]: true });
          updateSkills(-1, [currentTopic])
        } else {
          let newSkills = await getNestedSkills(topic)
            // If not selected, add it to the selected topics
          setSelected({ ...selected, [topic]: true });
          updateSkills(index, newSkills.map(data=>({name: data.Label, id: data._id})))
        }
      }
  };
  
    let isTopicIntrusted = (topic) => {
      // setSelected({...selected, [topic]: false})
      return selected[topic] == true ? true : false
    }
  return (
    <div className="bg-[#181818] text-white p-5 rounded-lg my-5">
      <div className="text-lg">Pick Your Expertise!</div>

      <div className="my-3">
        <Separator />
      </div>
      <div className="my-6 text-md font-light text-center">
        Select all those that you are confident about.
      </div>

      <div className="flex justify-center items-center flex-col text-center">
        <div className="search-bar m-auto flex justify-center w-full">
          <SearchBar
            placeholder="Javascript, php, gif, etc..."
            iconPosition="left"
            data={searchBar}
            onChange={onChange}
          />
        </div>
        {!loading ? <div className="flex flex-wrap md:w-[70%] gap-2 mt-10 justify-center mb-10">
          {skills.map((item, index) => (
            <div className="relative">
              <p
                onClick={() => selectedOptions(item.id, index)}
                className={`w-max justify-center mapped-items p-1.5 rounded-lg text-[14px] items-center ${
                  (isTopicIntrusted(item.id) ? "bg-[#8b71d1]" : "bg-[#353440]")
                } ${account?.Screening ? '' : 'hover:bg-[#8b71d1] cursor-pointer' }`}
              >
                {item.name}
              </p>
            </div>
          ))}
        </div>: <div style={{height: '200px'}} className="flex justify-center items-center">
          <img src={Loader} />
      </div> }
        <div className="btn">
        <div className="text-sm text-[#AAAAAA]">{Object.keys(selected).length > 5 ? 'You can select upto 5 skills' :''}</div>
      </div>
      </div>
    </div>
  );
};

Expertise.propTypes = {};

export { Expertise };
