import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";

const Select = ({
  handleAttendees,
  loadOptions,
  defaultOptions = [],
  disabled = false,
}) => {
  const [state, setState] = useState();
  return (
    <div>
      {/* need to work here. */}
      <AsyncSelect
        isDisabled={disabled}
        menuPosition="fixed" // Ensure the menu is positioned absolutely in the viewport
        menuPlacement="bottom" // Adjust if you need the menu to open above the control
        minMenuHeight={300}
        theme={(theme) => ({
          ...theme,
          borderWidth: "0px",
          marginTop: "10px",
          colors: {
            text: "orangered",
            primary25: "hotpink",
            primary: "white",
          },
        })}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderWidth: "0px",
            borderColor: "#353B4B",
            backgroundColor: "#353B4B",
            border: "1px solid #353B4B",
            boxShadow: 0,
            color: "#ffffff",
            fontSize: "13px",
            marginBottom: "1px",
            ":active": {
              border: "1px solid #353B4B",
            },
            ":hover": {
              border: "1px solid #353B4B",
            },
          }),
          option: () => ({
            cursor: "pointer",
            backgroundColor: "#353B4B",
            fontSize: "13px",
            padding: "3px 6px",
            color: "#ffffff",
            ":hover": {
              backgroundColor: "#8254F8",
            },
            ":active": {
              backgroundColor: "#8254F8",
            },
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            marginTop: "0px",
            background: "#353B4B",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Ensure the menu appears above other content
          }),
        }}
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions={defaultOptions}
        className="bg-black text-white"
        onChange={(choice) => handleAttendees(choice)}
        menuPortalTarget={document.body} // Ensure the menu is appended to the body
      />
    </div>
  );
};

Select.propTypes = {};

export { Select };
