import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OTPInput from "react-otp-input";
import { Button } from "../../components/Button/Button";
import { useAuthStore } from "../../store/auth/auth";
import { useNavigate } from "react-router-dom";
import { AccountStatusConstants } from "../../config/constants/account";
import { Error } from "../../config/helpers/toast-helper";
import { useReferralStore } from "../../store/referral/referral";
import { usePlanStore } from "../../store/plans/plans";

const AccountActivation = (props) => {
  const { getAccountVerification, verifyOTPCode } = useAuthStore();
  let { isReferralExist, isAccountHasReferral } = useReferralStore();
  let { isPlanSubscribed } = usePlanStore();

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabledResetButton, setDisabledResetButton] = useState(true);

  let navigate = useNavigate();

  useEffect(() => {
    // sendCode()
    setTimeout(() => {
      setDisabledResetButton(false);
    }, 60000);
  }, []);

  let sendCode = async () => {
    try {
      await getAccountVerification();
    } catch (error) {
      console.log(error);
    }
  };

  let resendCode = async () => {
    if (!disabledResetButton) return;
    await sendCode();
  };

  let handleSubmit = async () => {
    try {
      if (code.toString().length !== 4) return;
      setLoading(true);
      let payload = {
        Code: code,
      };
      await verifyOTPCode(payload);
      setLoading(false);
      let planSubscribed = await isPlanSubscribed();
      if (!planSubscribed) {
        navigate("/plans");
        return;
      }
      navigate("/");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className={`text-white flex flex-col max-w-[325px]`}>
      <div className="text-center my-4">
        We've sent a verification code to your email. Please check your inbox.
      </div>
      <div className="flex-grow">
        <OTPInput
          className={`bg-red-300`}
          inputStyle={{
            background: "#1F1D2B",
            border: "0.5px solid white",
            borderRadius: "10px",
            width: "100%",
            height: "60px",
            margin: "10px",
          }}
          value={code}
          onChange={setCode}
          numInputs={4}
          // renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
      </div>
      <div className="">
        <Button
          onClick={handleSubmit}
          fullWidth={true}
          isDisabled={loading}
          isActive={code.length === 4}
          buttonText={"Verify"}
        />
      </div>
      <div className="mt-3">
        <Button
          onClick={resendCode}
          fullWidth={true}
          isActive={!disabledResetButton}
          buttonText={"Resend code"}
        />
      </div>
    </div>
  );
};

AccountActivation.propTypes = {};

export { AccountActivation };
