import { useEffect, useMemo, useRef, useState } from "react";
import Logo from "../../assets/png/icon.png";
import Work from "../../assets/svg/Work.svg";
import { Input } from "../../components/Input/Input";
import { Uploader } from "uploader"; // Installed by "react-uploader".
import { UploadButton } from "react-uploader";
import { Button } from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/auth/auth";
import countryList from "react-select-country-list";
import { EmptyData } from "../../components/EmptyData/EmptyData";
import { CreateEducationModal } from "../../components/Education/CreateEducationModal";
import { useEducationStore } from "../../store/education/education";
import { EducationCard } from "../../components/EducationCard/EducationCard";
import { useExperienceStore } from "../../store/experience/experience";
import { CreateExperienceModal } from "../../components/Experience/CreateExperienceModal";
import { ExperienceCard } from "../../components/ExperienceCard/ExperienceCard";
import { useUserStore } from "../../store/user/user";
import { useAccountStore } from "../../store/account/account";
import axios from "axios";
import { SocialAccountTypes } from "../../config/constants/socialAccounts";
import { useSocialAccountStore } from "../../store/socialAccount/socialAccount";

// Initialize once (at the start of your app).
const uploader = Uploader({
  apiKey: "free", // Get production API keys from Bytescale
});

const options = { multi: true };
const UserInfo = () => {
  let { updateUser }  = useUserStore()
  let { account, user, getAccountInfo, userProfilePicture } = useAuthStore();
  let { education, getCreatorEducation, selectedForUpdate } = useEducationStore();
  let { experiences, getCreatorExperience } = useExperienceStore();
  let { getUploadUrl, getObjectUrl } = useAccountStore()
  let { getSocialAccountInfo, userSocialAccounts } = useSocialAccountStore()

  const [socialAccounts, setSocialAccounts] = useState({linkedIn: '', instagram: '', facebook: '', twitter: '', discord: '', youtube: ''})
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState('') 
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [educationList, setEducationList] = useState([]);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [loading, setLoading]  = useState(false)
  const fileInputRef = useRef(null);

  const navigate = useNavigate();

  const [file, setFile] = useState("");
  const [fileObject, setFileObject] = useState("");

  // const 
  const [experienceList, setExperienceList] = useState([
    {
      title: "",
      employmentType: "",
      companyName: "",
      companyLocation: "",
      locationType: "",
      industry: "",
      description: "",
      profileHeadline: "",
    },
  ]);

  const CountryOptions = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let { account, user } = await getAccountInfo();

    if(user.ProfilePicture){
      let objectUrl = await getObjectUrl(user.ProfilePicture)
      setFile(objectUrl)
    }
    
    getCreatorEducation(account._id);
    getCreatorExperience(account._id);
    setFirstName(user.FirstName)
    setLastName(user.LastName || '')
    setLocation(user.Location)
    setEmail(user.Email)
    setDescription(user.Description || '')
    let socialAccounts = await getSocialAccountInfo(account._id)
    setSocialAccounts({
      linkedIn: socialAccounts?.find(data => data.Type == SocialAccountTypes.linkedIn)?.Url,
      instagram: socialAccounts?.find(data => data.Type == SocialAccountTypes.instagram)?.Url,
      facebook: socialAccounts?.find(data => data.Type == SocialAccountTypes.facebook)?.Url,
      twitter: socialAccounts?.find(data => data.Type == SocialAccountTypes.twitter)?.Url,
      discord: socialAccounts?.find(data => data.Type == SocialAccountTypes.discord)?.Url,
      youtube: socialAccounts?.find(data => data.Type == SocialAccountTypes.youtube)?.Url,
    })
  }
  // Full Configuration:
  // https://www.bytescale.com/docs/upload-widget#configuration
  const options = {
    apiKey: "free", // Get API keys from: www.bytescale.com
    maxFileCount: 1,
    styles: {
      colors: {
        primary: "#7e5eec",
      },
    },
  };

  const employmentTypes = [
    "full-time",
    "part-time",
    "self-employed",
    "freelance",
    "contract",
    "internship",
    "apprenticeship",
    "seasonal",
  ];
  // Function to handle adding a new education entry
  const addEducation = () => {
    // setEducationList([
    //   ...educationList,
    //   {
    //     school: "",
    //     degree: "",
    //     fieldOfStudy: "",
    //     cityWhereStudy: "",
    //     attendedMonth: "",
    //     attendedYear: "",
    //   },
    // ]);
  };
  const addExperience = () => {
    setExperienceList([
      ...experienceList,
      {
        title: "",
        employmentType: "",
        companyName: "",
        companyLocation: "",
        locationType: "",
        industry: "",
        description: "",
        profileHeadline: "",
      },
    ]);
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperienceList = [...experienceList];
    updatedExperienceList[index][field] = value;
    setExperienceList(updatedExperienceList);
  };

  // Function to handle updating education entry fields
  const handleEducationChange = (index, field, value) => {
    const updatedEducationList = [...educationList];
    updatedEducationList[index][field] = value;
    setEducationList(updatedEducationList);
  };

  // Function to generate an array of years from 1950 to current year
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = 1950; year <= currentYear; year++) {
      years.push(year);
    }
    return years;
  };


  async function handleSubmit(){
    // get upload url
    // upload image to s3
    // const blob = await fetch(file).then((res) => res.blob());
    setLoading(true);
    let S3ObjectKey = new Date().getTime()
    let url=''
    if(fileObject){
      url = await getUploadUrl(S3ObjectKey)
      
      try {
        const response = await axios.put(url, fileObject, {
          headers: {
            'Content-Type': fileObject.type ?? 'multipart/form-data',
          },
        });
      } catch (error) {
        console.log(error.response.data)
        setLoading(false);
      }
    }


    let data = {
      FirstName: firstName,
      LastName: lastName,
      ...(url && { ProfilePicture: S3ObjectKey}),
      // Location: location // need to work on it.
      TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      Location: location,
      Description: description,
      SocialAccounts: Object.keys(socialAccounts).map(data => ({AccountId: account._id, Type: SocialAccountTypes[data], Url: socialAccounts[data]}))
    }
    await updateUser(user._id, data)
    navigate('/')
  }

  const handleCountryChange = (event) => {
    setLocation(event.target.value)
  };

  const handleButtonClick = () => {
    // Trigger click on the hidden file input element
    fileInputRef.current.click();
  };

  const handleRemovePhoto = () => {
    // Trigger click on the hidden file input element
    setFileObject(null)
    setFile(null)
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    console.log('file: ',file)
    setFileObject(file)
    // Handle the selected file here
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="p-8 flex justify-center items-center text-center flex-col text-white">
      <CreateEducationModal
        onRequestClose={() => {
          selectedForUpdate(null)
          setShowEducationModal(false);
        }}
        show={showEducationModal}
      />
      <CreateExperienceModal onRequestClose={() => {
          setShowExperienceModal(false);
        }}
        show={showExperienceModal}
       />
      <div className="flex gap-5 flex-row items-center">
        <img src={Logo} alt="" className="w-[61px] h-[61px]" />
        <h2 className="text-3xl font-[600] ">Discoursefy</h2>
      </div>
      <div className="mt-10 ">
        {/* <p className="text-[#9C9AA5] text-[14px] my-3"> 2/2</p>{" "} */}
        <h2 className="font-[600] text-[20px] md:text-[30px]">
          Customize your Profile Picture
        </h2>
        <p className="text-[#9C9AA5] py-3 text-[18px] md:text-[24px] font-[400] ">
          Setup your picture for users to recognize you.
        </p>
        <div
          className={`w-[145px] h-[145px] bg-[#ffffff] m-auto flex justify-center items-center text-center border-radius-50 outline-border border-4`}
        >
          <img
            src={file ? file : Work}
            alt=""
            style={{objectFit:'cover'}}
            className={` ${
              file
                ? "w-[145px] h-[140px] border-radius-50"
                : "w-[50px] h-[50px]"
            }  `}
          />
        </div>
        <div className="mt-6">
           <div className="flex justify-center items-center gap-x-3">
              {/* Hidden file input element */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {/* Button to trigger file input click */}
              <Button onClick={handleButtonClick} buttonText={file ? 'Re Upload Picture' : 'Upload Picture'} isActive={true} />
          </div>

          {/* uploading picture end */}
        </div>
      </div>
      <div className="info-main w-[80%] md:w-[40%] ">
        <div className=" flex flex-col gap-3">
          <div className="flex items-center">
            <h2 className="text-[18px] my-4">Basic Information</h2>
          </div>
          <div className="grid md:grid-cols-2 gap-x-3 gap-y-3">
            <Input
              id="firstName"
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <Input
              id="lastName"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <Input
            disabled={true}
            id="email"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <select
            placeholder="Select your country"
            className="w-full border-none focus:outline-none focus:shadow-outline bg-[#353440] py-2 px-3 rounded"
            onChange={handleCountryChange}
            value={location}
          >
            {CountryOptions.map((data) => (
              <option value={data.label}>{data.label}</option>
            ))}
          </select>

          <Input
            disabled={true}
            id="email"
            type="textarea"
            placeholder="About Your Self"
            value={description}
            classes={'text-white'}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className=" flex flex-col gap-3 my-7">
          <div className="flex justify-start items-center">
            <h2 className="text-[18px] my-4">Social Links</h2>
          </div>
          <div className="grid md:grid-cols-2 gap-x-3 gap-y-3 text-left">
            <Input
              label={'LinkedIn'}
              disabled={false}
              id="linkedIn"
              type="text"
              placeholder="LinkedIn"
              value={socialAccounts.linkedIn}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, linkedIn: e.target.value}))}
            />
            <Input
              label={'Twitter'}
              disabled={false}
              id="twitter"
              type="text"
              placeholder="Twitter"
              value={socialAccounts.twitter}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, twitter: e.target.value}))}
            />
            <Input
              label={'Instagram'}
              disabled={false}
              id="instagram"
              type="text"
              placeholder="Instagram"
              value={socialAccounts.instagram}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, instagram: e.target.value}))}
            />
            <Input
              label={'Facebook'}
              disabled={false}
              id="facebook"
              type="text"
              placeholder="Facebook"
              value={socialAccounts.facebook}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, facebook: e.target.value}))}
            />
            <Input
              label={'Discord'}
              disabled={false}
              id="discord"
              type="text"
              placeholder="Discord"
              value={socialAccounts.discord}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, discord: e.target.value}))}
            />
            <Input
              label={'Youtube'}
              disabled={false}
              id="youtube"
              type="text"
              placeholder="Youtube"
              value={socialAccounts.youtube}
              onChange={(e) => setSocialAccounts((prev) => ({...prev, youtube: e.target.value}))}
            />
          </div>
        </div>
        <div className=" flex flex-col gap-3 my-7">
          <div className="flex justify-between items-center">
            <h2 className="text-[18px] my-4">Education</h2>
            <Button
              isActive={true}
              buttonText="Add"
              onClick={() => setShowEducationModal(true)}
            />
          </div>
          {education.length > 0 ? (
            education.map((data, index) => (
              <>
                <EducationCard
                  Data={data}
                  key={data._id}
                  School={data.School}
                  Degree={data.Degree}
                  Description={data.Description}
                  FieldOfStudy={data.FieldOfStudy}
                  EndDate={data.EndDate}
                  StartDate={data.StartDate}
                  ShowUpdate = {true}
                />
              </>
            ))
          ) : (
            <EmptyData />
          )}
        </div>

        <div className=" flex flex-col gap-3 my-7 text-left">
          <div className="flex justify-between items-center">
            <h2 className="text-[18px] my-4">Experience</h2>
            <Button buttonText="Add" isActive={true} onClick={()=>(setShowExperienceModal(true))} />
          </div>
          {experiences.length > 0 ? (
            experiences.map((experience, index) => (
              <ExperienceCard
                Data={experience}
                key={experience._id}
                Organization={experience?.Organization}
                Designation={experience?.Designation}
                Location={experience?.Location}
                StartDate={experience?.StartDate}
                EndDate={experience?.EndDate}
                IsCurrentJob={!experience?.EndDate}
                JobDescription={experience?.JobDescription}
                ShowUpdate = {true}
               />
            ))
          ) : (
            <EmptyData />
          )}

          <div className=" m-auto my-4">
            <Button
              buttonText="Continue"
              isDisabled={loading}
              fullWidth
              type="outlined"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
