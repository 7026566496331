import React, { useState } from "react";
import PropTypes from "prop-types";
import { CreatorCard } from "../../components/CreatorCard/CreatorCard";
import { HomePageComponent } from "../../components/HomePageComponent/HomePageComponent";

const Home = (props) => {
  const [state, setState] = useState();
  return (
    <div>
      <HomePageComponent />
    </div>
  );
};

Home.propTypes = {};

export { Home };
