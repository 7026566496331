import React, { useState } from "react";
import PropTypes from "prop-types";
import { PlanCard } from "../Plan/PlanCard";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import CheckoutForm from "../PaymentCard/CheckoutForm";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PlanningModal } from "../PlanningModal/PlanningModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { usePlanStore } from "../../store/plans/plans";
import { useEffect } from "react";
import { AddPaymentMethod } from "./AddPaymentMethod";

const PricingSubscription = (props) => {
  let { plans, fetchPlans } = usePlanStore();

  const [loading, setLoading] = useState();
  const [plan, setPlan] = useState();

  let navigate = useNavigate();
  const { pathname } = useLocation();
  let { id } = useParams();

  useEffect(() => {
    getPricingPlans();
  }, []);

  let getPricingPlans = async () => {
    setLoading(true);
    if (!plans.length) {
      let Plans = await fetchPlans();
      setPlan(Plans.find((plan) => plan._id === id));
    } else {
      setPlan(plans.find((plan) => plan._id === id));
    }
    setLoading(false);
  };

  const promise = loadStripe(
    "pk_live_51PDrCpRpw1gyVqw5dq6e3u87RFWQKBaP8Wfu5KGk3fX9d9yVgJYIXKVwgvsuF3CTDAfF52KjGNsHrvzGKmcRG9HY00BB66r1Vp"
  );
  const cardStyle = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        border: "1px solid #FFFFFF",
        fontSize: "16px",
        "::placeholder": {
          color: "#ffffff",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const handleRoute = () => {
    if (pathname.includes("/account/billing/subscription/")) {
      navigate("/account/billing/plans");
    } else {
      navigate("/plans");
    }
  };

  return (
    <div className="h-[100vh] lg:overflow-hidden flex justify-center items-center">
      <div className="max-w-[1280px] m-auto text-white bg-gradient-to-r from-[#1E1E2F] to-[#3E1E5F] h-fit rounded-lg py-5 px-5">
        <div className="grid grid-cols-3 gap-x-5 lg:max-w-[80%] mx-auto my-10">
          <div className="p-3 lg:p-6 col-span-3 lg:col-span-1 flex flex-col justify-between items-start gap-y-4 text-white rounded-lg transition-all duration-300 h-[100%]">
            <div className="flex flex-col items-start gap-y-4">
              <h4 className="font-bold text-white">
                <span
                  onClick={handleRoute}
                  className="text-gray-500 font-semibold hover:underline cursor-pointer"
                >
                  Plans
                </span>{" "}
                / {plan?.Name}
              </h4>
              <div className="flex items-end">
                <h3 className="text-4xl font-semibold text-white">
                  ${plan?.Price}/
                </h3>
                <span className="text-lg text-gray-300">
                  {plan?.Price == 0
                    ? "Forever"
                    : plan?.Type == 1
                    ? "Month"
                    : "Yearly"}
                </span>
              </div>
              <div className="text-white">
                <span className="font-semibold">Includes:</span>
                <div className="flex flex-col gap-y-2 mt-2 text-sm text-gray-300">
                  {plan?.Features.map((data) => (
                    <div className="flex items-start gap-x-2">
                      <IoMdCheckmarkCircleOutline className="text-lg w-fit" />
                      <span className="w-fit">{data}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex p-3 lg:p-6 flex-col justify-end gap-y-10 col-span-3 lg:col-span-2">
            <div className="text-lg font-light text-gray-300">
              <h3 className="font-semibold text-2xl text-white">
                {" "}
                {plan?.Benefit?.Heading}{" "}
              </h3>
              <span>{plan?.Benefit?.Description}</span>
            </div>
            <div className="">
              <div className=" ">
                <Elements stripe={promise}>
                  <AddPaymentMethod />
                </Elements>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PricingSubscription.propTypes = {};

export { PricingSubscription };
