import { createBrowserRouter } from "react-router-dom";
import { BecomePartner } from "../components/BecomeCreator/BecomeCreator";
import { Invoice } from "../components/Invoice/Invoice";
import { VideoRecorder } from "../components/VideoRecorder/VideoRecorder";
import { AccountActivation } from "../pages/AccountActivation/AccountActivation";
import { AccountSetting } from "../pages/AccountSetting/AccountSetting";
import Auth from "../pages/Auth/Auth";
import { Billing } from "../pages/Billing/Billing";
import { Creator } from "../pages/Creator/Creator";
import { CreatorInfo } from "../pages/CreatorInfo/CreatorInfo";
import { Discussion } from "../pages/Discussion/Discussion";
import { DiscussionDetail } from "../pages/DiscussionDetail/DiscussionDetail";
import { Earn } from "../pages/Earn/Earn";
import { Home } from "../pages/Home/Home";
import { Layout } from "../pages/Layout/Layout";
import { MeetingDetail } from "../pages/MeetingDetail/MeetingDetail";
import { Meetings } from "../pages/Meetings/Meetings";
import { Payout } from "../pages/Payout/Payout";
import PeerInvitation from "../pages/PeerInvitation/PeerInvitation";
import { PeerSession } from "../pages/PeerSession/PeerSession";
import { Plan } from "../pages/Plan/Plan";
import { PricingSubscription } from "../pages/PricingSubscription/PricingSubscription";
import { Problem } from "../pages/Problem/Problem";
import { Problems } from "../pages/Problems/Problems";
import { QuestionPage } from "../pages/Question/Question";
import { Quiz } from "../pages/Quiz/Quiz";
import { QuizCategory } from "../pages/QuizCategory/QuizCategory";
import { Rating } from "../pages/Ratings/Rating";
import { Referral } from "../pages/Referral/Referral";
import { SettingsLayout } from "../pages/SettingsLayout/SettingsLayout";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import UserCustomize from "../pages/UserCustomize/UserCustomize";
import UserInfo from "../pages/UserInfo/UserInfo";
import { Verify } from "../pages/Verify/Verify";
import { WeeklyInsights } from "../pages/WeeklyInsights/WeeklyInsights";
import { WeeklyPerformance } from "../pages/WeeklyPerformace/WeeklyPerformace";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    name: "Home",
    children: [
      {
        path: "/",
        element: <WeeklyPerformance />,
      },
      {
        path: "/weekly-performance/:id",
        element: <WeeklyInsights />,
      },
      {
        path: "/quick-bites",
        element: <QuizCategory />,
      },
      {
        path: "/quiz/:id",
        element: <Quiz />,
      },
      {
        path: "/questions/:id",
        element: <QuestionPage />,
      },
      {
        path: "/problems/:id",
        element: <Problem />,
      },
      {
        path: "/problems",
        element: <Problems />,
      },
      {
        path: "/creators",
        // children:
        //   [
        //     {
        //       path: "/pink",
        //       element: <div className='text-white'>Hi</div>
        //     }
        //   ]
        // ,
        element: <Home />,
      },
      {
        path: "/creator/:id",
        element: <Creator />,
      },
    ],
  },
  {
    path: "/peerSession/:id",
    element: <PeerSession />,
  },
  {
    path: "/peerInvitation/:id",
    element: <PeerInvitation />,
  },
  {
    path: "/subscription/:id",
    element: <PricingSubscription />,
  },
  {
    path: "/plans",
    element: <Plan />,
  },
  {
    path: "/:id",
    element: <CreatorInfo />,
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "account/activation",
        element: <AccountActivation />,
      },
    ],
  },
  {
    path: "/verify/:code",
    element: <Verify />,
  },
  {
    path: "/referrals",
    element: <Referral />,
  },
  {
    path: "/user-customize",
    element: <UserCustomize />,
  },
  {
    path: "/user-info",
    element: <UserInfo />,
  },
  {
    path: "account",
    element: <SettingsLayout />,
    children: [
      {
        path: "billing",
        element: <Billing />,
      },
      {
        path: "billing/subscription/:id",
        element: <PricingSubscription />,
      },
      {
        path: "billing/invoice/:id",
        element: <Invoice />,
      },
      {
        path: "billing/plans",
        element: <Plan />,
      },
      {
        path: "setting/configure",
        element: <AccountSetting />,
      },
      {
        path: "setting/payoutSetup",
        element: <Payout />,
      },
      {
        path: "setting",
        element: <BecomePartner />,
      },
      {
        path: "meeting/:id",
        element: <MeetingDetail />,
      },
      {
        path: "meeting",
        element: <Meetings />,
      },
      {
        path: "discussion/:id",
        element: <DiscussionDetail />,
      },
      {
        path: "discussion",
        element: <Discussion />,
      },
      {
        path: "ratings",
        element: <Rating />,
      },
      {
        path: "earn",
        element: <Earn />,
      },
    ],
  },
  {
    path: "test",
    element: <VideoRecorder />,
  },
]);
