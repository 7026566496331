import React, { useState } from "react";
import PropTypes from "prop-types";
import { IoCheckmarkSharp } from "react-icons/io5";

const InterestCard = ({ Skill }) => {
  const [state, setState] = useState();
  return (
    <>
      <div className="flex items-center gap-3">
        <div>
          <div className="text-[14px] md:text-[18px] bg-[#7A5CEE] p-1 md:p-2 rounded-full">
            <IoCheckmarkSharp />{" "}
          </div>
        </div>
        <div className="text-white font-normal text-[18px] md:text-lg">{Skill}</div>
      </div>
      <div className="w-full h-[1px] bg-[#353B4B]"></div>
    </>
  );
};

export { InterestCard };
