import React, { useState } from "react";
import PropTypes from "prop-types";
import './BecomeCreator.css'
import { BecomeCreatorStepCard } from "../BecomeCreatorStepCard/BecomeCreatorStepCard";
import { CiBadgeDollar } from "react-icons/ci";
import { IoCalendarOutline } from "react-icons/io5";
import { FaStripeS } from "react-icons/fa";
import { GoRocket } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/auth/auth";
import { useChargesStore } from "../../store/charges/charges";
import { useEffect } from "react";
import { ChargesDurationEnums } from "../../config/constants/charges";
import { PiPlugsConnectedBold, PiStripeLogo } from "react-icons/pi";
import { Button } from "../Button/Button";
import { useInterestStore } from "../../store/interest/interest";
import { TfiVideoCamera } from "react-icons/tfi";
import { VideoStatusConstants } from "../../config/constants/video";

const BecomePartner = (props) => {
  let { getSyncAccountApps, account } = useAuthStore()
  let {fetchAccountCharges} = useChargesStore()
  let { getExpertise, expertise } = useInterestStore()


  const [accountInfo, setAccountInfo] = useState(null)
  const [chargesStatus, setChargesStatus] = useState(null)


  let navigate = useNavigate()
  let handleClick = () => {
    navigate("/account/setting/configure")
  }

  useEffect(()=>{
    getAccountInfo()
  }, [])

  let getAccountInfo = async ()=> {
    let accountResponse = await getSyncAccountApps()
    await getExpertise(accountResponse.AccountInfo._id)
    setAccountInfo(accountResponse?.AccountInfo)
    await getAccountCharges(accountResponse?.AccountInfo?._id)
  }

  let getAccountCharges = async (accountId) => {
    // if(!accountInfo){
    //   let AccountResp = await getAccountInfo()
    //   account = AccountResp.
    // }
    let Charges = await fetchAccountCharges(accountId)
    let swiftSessionCharges = Charges.find(data => data.Duration == ChargesDurationEnums.HALF_HOUR)?.Amount || 0
    let hourlyHustleCharges = Charges.find(data => data.Duration == ChargesDurationEnums.HOUR)?.Amount || 0
    let epicEncounterCharges = Charges.find(data => data.Duration == ChargesDurationEnums.NINETY_MINUTES)?.Amount || 0

    setChargesStatus(swiftSessionCharges && hourlyHustleCharges && epicEncounterCharges)
  }

  let handleButtonText = () => {
    return (expertise.length && account?.Screening == VideoStatusConstants.ACCEPTED && accountInfo?.IsCalendarSync && accountInfo?.IsCalendarSync && accountInfo?.IsStripeSync) ? 'Update Your Info' : 'Become A Creator' 
  }
  return (
    <div className="bg-[#181818] text-white p-5 rounded-lg my-5">
      <div className="text-xl">Become A Creator</div>
      <div className="my-6 text-sm font-light text-lg">
            As a Creator on <b className="font-semibold"> Discoursefy</b>, you have the opportunity to share your expertise with a global audience and make a meaningful impact on learners worldwide. By joining our Creator community, you'll enjoy numerous benefits:
            <br/>
            <br/>
            <ol className="w-[90%] flex flex-col gap-y-3">
                <li className="flex flex-col">
                 <b className="font-semibold text-[16px]"> Share Your Knowledge: </b> Share your expertise, insights, and experiences with learners who are eager to expand their understanding in your field.
                </li>
                {/* <li>
                  2. Build Your Brand: Gain visibility and credibility as a thought leader in your domain by hosting engaging discussions and providing valuable insights.
                </li> */}
                <li className="flex flex-col">
                 <b className="font-semibold text-[16px]"> Earn Revenue: </b> Monetize your expertise by setting rates for your discussions and meetings. Earn income while sharing your passion with others.
                </li>
                <li className="flex flex-col">
                <b className="font-semibold text-[16px]"> Expand Your Network: </b> Connect with like-minded individuals and industry peers who share your interests and passion for learning.
                </li>
                <li className="flex flex-col">
                <b className="font-semibold text-[16px]"> Flexible Schedule: </b> Enjoy the flexibility of setting your own schedule and hosting discussions at times that work best for you.
                </li>
                <li className="flex flex-col">
                <b className="font-semibold text-[16px]"> Personal Growth: </b> Enhance your communication, teaching, and leadership skills as you engage in meaningful discussions and mentorship opportunities.
                </li>
            </ol>
            <br/>
            <br/>

      </div>

{/*  onClick={handleClick} */}
      <div className="grid grid-cols md:grid-cols-2 xl:grid-cols-4 min-[1600px]:grid-cols-5 gap-y-4">  
        <BecomeCreatorStepCard isInfoAdded={expertise.length} title={'Select Your Skills'} description={'Select all those topics that you are confident about.'} step={1} icon={<GoRocket className='text-[120px]' />} />
        <BecomeCreatorStepCard isInfoAdded={account?.Screening == VideoStatusConstants.ACCEPTED} title={'Screening'} description={'Record a video to introduce yourself and highlight your expertise.'} step={2} icon={<TfiVideoCamera className='text-[100px]' />} />
        <BecomeCreatorStepCard isInfoAdded={chargesStatus} title={'Package Info'} description={'Set Your Meeting Rates and Start Earning!'} step={3} icon={<CiBadgeDollar className='text-[120px]' />} />
        <BecomeCreatorStepCard cardClass={`min-[1600px]:hidden`} isInfoAdded={accountInfo?.IsCalendarSync && accountInfo?.IsStripeSync} title={'Sync With Us'} description={'Manage schedules and payments by syncing Google Calendar and Stripe.'} step={4} icon={<PiPlugsConnectedBold className='text-[120px] font-light' />} />
        <BecomeCreatorStepCard cardClass={`hidden min-[1600px]:block`} isInfoAdded={accountInfo?.IsCalendarSync} title={'Google Calendar'} description={'Sync Your Google Calendar and Manage your schedule'} step={4} icon={<IoCalendarOutline className='text-[120px] font-light' />} />
        <BecomeCreatorStepCard cardClass={`hidden min-[1600px]:block`} isInfoAdded={accountInfo?.IsStripeSync} title={'Stripe'} description={'Create your stripe account to received funds'} step={5} icon={<PiStripeLogo className='text-[120px]' />} />
      </div>
      <div className="flex justify-center items-center mt-6">
        <Button buttonText={handleButtonText()} onClick={handleClick} isActive={true} />
      </div>
    </div>
  );
};

BecomePartner.propTypes = {};

export { BecomePartner };
