import { create } from "zustand";
import { CreateEducation, GetCreatorEducation, UpdateEducation } from "../../service/education/education";

export const useEducationStore = create((set, get) => ({
    education: [],
    educationCardGoingToUpdate: null,

    getCreatorEducation: async (accountId) => {
        try {
            let education = await GetCreatorEducation(accountId)   
            set((state) => ({ education }))
        } catch (error) {
            console.log(error)
        }
    },

    createEducation: async (accountId, data) => {
        try {
            let education = await CreateEducation(accountId, data)
            return true   
        } catch (error) {
            console.log(error)
        }
    },

    selectedForUpdate: (data) => {
        set({educationCardGoingToUpdate: data})
    },

    updateEducation: async (accountId, data) => {
        try {
            let education = await UpdateEducation(accountId, data)
            set((state) => ({ educationCardGoingToUpdate: null }))
            return true   
        } catch (error) {
            console.log(error)
        }
    },
}))