import React, { useState } from "react";
import PropTypes from "prop-types";
import { WeeklyInsights as WeeklyInsightsComponents } from "../../components/WeeklyInsights/WeeklyInsights";
import { SkeletonTheme } from "react-loading-skeleton";

const WeeklyInsights = (props) => {
  return (
    <div>
      {/* another try */}
      {/* just rerun deployment */}
      <WeeklyInsightsComponents />
    </div>
  );
};

WeeklyInsights.propTypes = {};

export { WeeklyInsights };
