import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { resourceTypeColorMapping, resourceTypeConstantMapping } from '../../config/constants/quiz';

const ReferenceTag = ({type}) => {
    return <div style={{background: resourceTypeColorMapping[type]}} className='text-white text-sm px-2 h-[22px] flex items-center rounded font-semibold'>
        {resourceTypeConstantMapping[type]}
    </div>;
};

ReferenceTag.propTypes = {};

export { ReferenceTag };