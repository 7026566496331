import React, { useState } from "react";
import PropTypes from "prop-types";
import { Plan as PlanComponent } from "../../components/Plan/Plan";

const Plan = (props) => {
  return (
    <div>
      <PlanComponent />
    </div>
  );
};

Plan.propTypes = {};

export { Plan };
