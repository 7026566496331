import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "../Table/Table";
import { usePlanStore } from "../../store/plans/plans";

const InvoiceListing = (props) => {
  let { fetchPaidAccountPlans, paidAccountPlans } = usePlanStore();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInvoices();
  }, []);

  let getInvoices = async () => {
    setLoading(true);
    await fetchPaidAccountPlans();
    setLoading(false);
  };

  let headers = [
    {
      label: "Plan",
      cols: 4,
      id: "plan",
    },
    {
      label: "Price",
      cols: 4,
      id: "price",
    },
    {
      label: "Start Date",
      cols: 2,
      id: "startDate",
    },
    {
      label: "End Date",
      cols: 2,
      id: "endDate",
    },
  ];

  return (
    <div className="w-[100%] md:w-full rounded-lg bg-[#181818] py-5 px-2 md:p-5">
      <div className="text-lg"> Invoice's </div>

      <div className="overflow-auto w-[100%]">
        <Table
          headers={headers}
          meetings={paidAccountPlans}
          currentPage={1}
          // Math.ceil(totalItems / pageConfig.limit) || 1
          totalPages={1}
          loading={loading}
          onNextPage={() => {}}
          onPreviousPage={() => {}}
        />
      </div>
    </div>
  );
};

InvoiceListing.propTypes = {};

export { InvoiceListing };
