import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "../Table/Table";
import { useRatingStore } from "../../store/rating/rating";

let headers = [
  {
    label: "User Info",
    cols: 4,
    id: "userInfo",
  },
  {
    label: "Entity Title",
    cols: 4,
    id: "entityTitle",
  },
  {
    label: "Rate",
    cols: 2,
    id: "rate",
  },
  {
    label: "Date",
    cols: 2,
    id: "date",
  },
  // {
  //   label: "Schedule Date",
  //   cols: 2,
  //   id: "scheduleDate",
  // },
];

const RatingComponent = (props) => {
  let { creatorRatings, fetchCreatorRating } = useRatingStore();

  const [loading, setLoading] = useState(true);
  const [pageConfig, setPageConfig] = useState({
    currentPage: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: -1,
    filteredBy: null,
    searchText: "",
    Upcoming: true,
  });

  useEffect(() => {
    getAccountRatingInfo();
  }, [JSON.stringify(pageConfig)]);

  async function getAccountRatingInfo() {
    setLoading(true);
    await fetchCreatorRating({...pageConfig});
    setLoading(false);
  }

  async function handleNextPage() {
    let nextPage = pageConfig.currentPage + 1;
    setPageConfig({ ...pageConfig, currentPage: nextPage });
  }

  async function handlePreviousPage() {
    let previousPage = pageConfig.currentPage - 1;
    setPageConfig({ ...pageConfig, currentPage: previousPage });
  }

  return (
    <div className="w-full rounded-lg bg-[#181818] p-5">
      <div className="text-lg"> Rating's </div>

      {/* <div className="w-[410px] my-3">
    <Input classes={'text-sm'} onChange={ onChange } value={searchText} placeholder={'Search with title'} />
  </div> */}

      <div className="flex justify-between">
        {/* <div className="my-3 flex items-center gap-2 flex-wrap">
      {MeetingFilters.map((filter) => (
        <Button
          onClick={() => handleFilter(filter.value)}
          buttonText={filter.Title}
          isActive={pageConfig.filteredBy == filter.value}
          classes={"text-xs py-[5px] rounded-md"}
        />
      ))}
    </div> */}
        <div className="">
          {/* <Button
        onClick={() => setPageConfig((prev => ({...prev, Upcoming: !prev.Upcoming})))}
        buttonText={'Upcoming'}
        isActive={pageConfig.Upcoming}
        classes={"text-xs py-[5px] rounded-md"}
      /> */}
        </div>
      </div>
      <Table
        headers={headers}
        meetings={creatorRatings}
        currentPage={1}
        totalPages={1}
        loading={loading}
        onNextPage={handleNextPage}
        onPreviousPage={handlePreviousPage}
      />
    </div>
  );
};

RatingComponent.propTypes = {};

export { RatingComponent };
