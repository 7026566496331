import { Get, Post, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const CreateRating = async (data) => {
    try {
        let {Authorization} = UserCredentials()
        return await Post(`${baseUrl}/rating/create`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const FetchCreatorRating = async (pageConfig) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/rating/accountRatings?Page=${pageConfig.currentPage}&Limit=${pageConfig.limit}&SortBy=${pageConfig.sortBy}&SortOrder=${pageConfig.sortOrder}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}