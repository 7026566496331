import React, { useState } from "react";
import PropTypes from "prop-types";
import { PricingSubscription as PricingSubscriptionComponent } from "../../components/PricingSubscription/PricingSubscription";

const PricingSubscription = (props) => {
  return (
    <div>
      <PricingSubscriptionComponent />
    </div>
  );
};

PricingSubscription.propTypes = {};

export { PricingSubscription };
