import React, { useState } from "react";
import PropTypes from "prop-types";
import { MeetingDetailComponent } from "../../components/MeetingDetailComponent/MeetingDetailComponent";

const MeetingDetail = (props) => {
  const [state, setState] = useState();
  return <div>
    <MeetingDetailComponent />
  </div>;
};

MeetingDetail.propTypes = {};

export { MeetingDetail };
