import React, { useState } from "react";
import PropTypes from "prop-types";
import { CreatorProfile } from "../CreatorProfile/CreatorProfile";
import CreatorPicture from "../../assets/png/CreatorPicture.png";
import { Tag } from "../Tag/Tag";
import { Button } from "../Button/Button";
import { BiStar } from "react-icons/bi";
import { FaStar } from "react-icons/fa6";
import { useMeetingStore } from "../../store/meeting/meeting";
import { Avatar } from "../Avatar/Avatar";

const CreatorInfoCard = ({
  selectedTab,
  handleTabChange,
  scheduleCall,
  showTags = true,
  children,
  buttonText = "Request a Call",
  showButton = true,
}) => {
  let { fetchCreatorInfo, creatorInfo } = useMeetingStore();

  let handleTags = (skill) => {
    return skill
      .split("")
      .filter((d) => d !== " ")
      .join("")
      .toString();
  };
  return creatorInfo ? (
    <div
      className={`w-full min-h-[200px] rounded-xl grid grid-cols-10 items-start text-white bg-[#181818] my-10 px-3 lg:px-5 py-6`}
    >
      {creatorInfo && (
        <div className="grid grid-cols-12 col-span-10 lg:col-span-9 my-auto">
          <div className="rounded-full col-span-12 lg:col-span-1 mx-auto overflow-hidden w-[85px] h-[85px] flex justify-center items-center p-[5px] border-solid border-white border-[1px]">
            {/* need to work on creator info api */}
            <Avatar
              userProfilePicture={creatorInfo.User.ProfilePicture}
              classes={`w-[75px] h-[75px] text-[40px]  text-[35px]`}
            />
          </div>

          <div className="text-white mx-5 col-span-12 lg:col-span-11 text-center lg:text-left">
            <div className="text-[18px] lg:text-[23px] font-normal capitalize">
              {`${creatorInfo.User.FirstName} ${
                !!creatorInfo.User.LastName ? creatorInfo.User.LastName : ""
              }`}
            </div>
            <div className="text-[13px] font-light text-[#AAAAAA]">
              {creatorInfo.User.Description}
            </div>
            <div className="flex items-center lg:items-start flex-col lg:flex-row">
              <div className="text-[13px] w-[85px] font-light">
                Talks about :
              </div>
              <span className="flex justify-center lg:justify-start items-center flex-wrap">
                {creatorInfo.Skills.map((skill, i) => (
                  <div className="text-[13px] font-light">
                    #{handleTags(skill.Label) + " "} &nbsp;
                  </div>
                ))}
              </span>
            </div>
            {showTags ? (
              <div className="flex justify-center lg:justify-start items-center gap-2 mt-5 overflow-x-auto">
                <Tag
                  text={"Education"}
                  selectedTab={selectedTab}
                  onClick={() => handleTabChange("Education")}
                />
                <Tag
                  text={"Experience"}
                  selectedTab={selectedTab}
                  onClick={() => handleTabChange("Experience")}
                />
                <Tag
                  text={"Interest"}
                  selectedTab={selectedTab}
                  onClick={() => handleTabChange("Interest")}
                />
              </div>
            ) : null}
          </div>
        </div>
      )}

      {showButton && (
        <div className="flex mt-3 lg:mt-0 col-span-10  lg:col-span-1 justify-center items-center flex-col my-auto">
          {/* <div className="text-[30px] font-bold flex justify-center items-center gap-1"><span> 4.5 </span> <FaStar /> </div> */}

          {!children ? (
            <Button
              buttonText={buttonText}
              isActive={true}
              onClick={scheduleCall}
            />
          ) : (
            children
          )}
        </div>
      )}
    </div>
  ) : null;
};

export { CreatorInfoCard };
