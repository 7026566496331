import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { usePlanStore } from "../../store/plans/plans";

const CurrentPlanCard = (props) => {
  let { currentPlan } = usePlanStore();
  let navigate = useNavigate();

  const handleRoute = () => {
    navigate("/account/billing/plans");
  };
  return (
    <div className="flex justify-between bg-[#181818] items-center font-semibold p-5 rounded-md">
      <span>
        Your application is currently on the{" "}
        {currentPlan?.planInfo?.Name || "Basic"} Plan
      </span>
      <button
        onClick={handleRoute}
        className="text-xs px-3 w-fit bg-gradient-to-r from-[#B310FD] to-[#9D0ECB] text-white py-2 rounded transition duration-200"
      >
        View Plans
      </button>
    </div>
  );
};

CurrentPlanCard.propTypes = {};

export { CurrentPlanCard };
