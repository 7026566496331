import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CreatorCard } from "../CreatorCard/CreatorCard";
import CoverPhoto from "../../assets/png/CreatorCoverPhoto.png";
import { DiscussionCard } from "../DiscussionCard/DiscussionCard";
import DockerBanner from "../../assets/png/DockerBanner.png";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { useMeetingStore } from "../../store/meeting/meeting";
import { DiscussionSlider } from "../DiscussionSlider/DiscussionSlider";
import Loader from "../../assets/gif/buttonLoader.gif";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAccountStore } from "../../store/account/account";
import QuizPromotionalBanner from "../../assets/png/QuizBanner.png";
import { PostTypeEnum } from "../../config/constants/linkedIn";

const HomePageComponent = (props) => {
  let { getSuggestedDiscussions, suggestedDiscussions } = useDiscussionStore();
  let { fetchRelatedCreators, creators } = useMeetingStore();
  let { generateLinkedInPost } = useAccountStore();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setLoading(true);
    let isCreatorSelected = localStorage.getItem("creatorAccount");
    if (isCreatorSelected) {
      navigate(`/creator/${isCreatorSelected}`);
    }
    // let code = searchParams.get("code");
    // if (code && code.length) {
    //   let quizId = localStorage.getItem("QuizId");
    //   let postType = localStorage.getItem("PostType");
    //   let queryParams = {
    //     QuizId: 0,
    //     PostType: PostTypeEnum.BecomeACreator,
    //   };
    //   if (quizId && postType) {
    //     queryParams = {
    //       QuizId: quizId,
    //       PostType: postType,
    //     };
    //   }
    //   localStorage.removeItem("QuizId");
    //   localStorage.removeItem("PostType");
    //   generateLinkedInPost(code, queryParams);
    //   navigate(`/`);
    // }
    fetchSuggestedDiscussions();
    fetchCreators();
  }, []);

  async function fetchSuggestedDiscussions() {
    try {
      await getSuggestedDiscussions();
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCreators() {
    try {
      await fetchRelatedCreators();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="max-w-[1280px] mx-auto px-5 lg:px-10">
      <div className="cursor-pointer h-[300px] lg:h-[250px] rounded-xl gap-y-3 text-white bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 flex flex-col justify-center items-center relative overflow-hidden">
        {/* <img className="h-[250px] w-full" src={QuizPromotionalBanner} /> */}
        <div className="text-[40px] md:text-[60px] font-normal seaweed">
          Quick Bites!
        </div>
        <div className="w-[90%] sm:w-[80%] md:w-[70%] mx-auto text-xs leading-5 md:text-[16px] text-center">
          Unlock your potential with Quick Bites! Learn new skills in just
          5-minute sessions, track your progress, and get detailed performance
          insights. Stand out to recruiters by showcasing your growth and
          dedication. Start learning smarter today—your future is waiting!
        </div>
        {/* Shining Effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-20 animate-shine"></div>

        {/* Button */}
        <div
          onClick={() => navigate("/quick-bites")}
          className="absolute bottom-4 right-4 px-4 py-1 sm:px-6 sm:py-2 bg-white text-[#8254F8] font-semibold rounded-full shadow-lg hover:bg-[#8254F8] hover:text-white transition-all duration-300 ease-in-out"
        >
          Learn More
        </div>
      </div>
      {suggestedDiscussions?.length > 0 ? (
        <section className="my-5 overflow-x-hidden">
          <>
            <div className=" text-[20px] md:text-[30px] text-white font-[600] my-5">
              Suggested Discussions
            </div>
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 min-[1600px]:grid-cols-4 gap-6 gap-y-10">
              {suggestedDiscussions?.length > 0
                ? suggestedDiscussions.map((discussion) => (
                    <DiscussionCard
                      key={discussion._id}
                      FirstName={discussion.User.FirstName}
                      LastName={discussion.User.LastName}
                      Skills={discussion.Skills.map((data) => data.Label)}
                      Title={discussion.Title}
                      CoverPhoto={discussion.CoverPhoto}
                      Theme={discussion.Theme}
                      Description={discussion.Description}
                      Date={discussion.ScheduleTime}
                      Discussion={discussion}
                    />
                  ))
                : null}
            </div>
          </>
        </section>
      ) : null}

      {!loading ? (
        <section className="my-5 overflow-x-hidden">
          <div className="text-[20px] md:text-[30px] text-white font-[600] my-5">
            Suggested Educators
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 min-[1600px]:grid-cols-4  gap-6 gap-y-10">
            {creators?.length
              ? creators.map((creator) => (
                  <CreatorCard
                    FirstName={creator.User.FirstName}
                    LastName={creator.User.LastName}
                    Skills={creator.Skills.map((data) => data.Label)}
                    Description={creator.User.Description}
                    CoverPhoto={""}
                    Rating={4.3}
                    Theme={"#80BCBD"}
                    Creator={creator}
                  />
                ))
              : null}
          </div>
        </section>
      ) : (
        <div
          style={{ height: "calc(100vh - 62px)" }}
          className="flex justify-center items-center"
        >
          <img src={Loader} />
        </div>
      )}
    </div>
  );
};

export { HomePageComponent };
