import React, { useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Header } from "../../components/Header/Header";
import { Outlet } from "react-router";
import { SettingBar } from "../../components/SettingBar/SettingBar";

const SettingsLayout = (props) => {
  const [state, setState] = useState();
  return <div className='text-white flex'>
  <Sidebar />
  {/* need to subtract it from calc(100% - 62px) fn */}
  <div className="ml-[62px] w-[82%] md:w-full flex">
        <SettingBar />
    <div className="md:ml-[270px] mx-1 md:mx-6 w-[100%] w-[100%]">
        <Header />
        <section className="section relative mt-[60px] w-[100%] h-[100%]">
            <Outlet />
        </section>
    </div>
  </div>
</div>;
};

SettingsLayout.propTypes = {};

export { SettingsLayout };
