import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { Button } from "../Button/Button";
import CoverPhoto from "../../assets/png/CreatorCoverPhoto.png";
import Avatar from "../../assets/png/CreatorPicture.png";
import Check from "../../assets/svg/Check.svg";
import { IoMdClose } from "react-icons/io";
import { Input } from "../Input/Input";
import { MdModeEdit, MdOutlineCancel } from "react-icons/md";
import DatePicker from "react-datepicker";
import * as moment from "moment";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { Separator } from "../Separator/Separator";

import AsyncSelect from "react-select/async";
import { IoCheckmark } from "react-icons/io5";
import { isValidTimeFormat } from "../../config/constants/regix";
import { Error } from "../../config/helpers/toast-helper";
import { Select } from "../Select/Select";

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { useMeetingStore } from "../../store/meeting/meeting";
import { useUserStore } from "../../store/user/user";
import { PackageCard } from "../PackageCard/PackageCard";
import { useChargesStore } from "../../store/charges/charges";
import { useParams } from "react-router-dom";
import { TypeToChargeMapping } from "../../config/constants/charges";
import { useAuthStore } from "../../store/auth/auth";
import { GiConsoleController } from "react-icons/gi";
import { useNotifyStore } from "../../store/notify/notify";
import { NotifyTypeConstants } from "../../config/constants/notify";

const selectedAttendees = [
  { label: "mudassir.siddiqui@retrocausal.ai" },
  { label: "laiba.shahid@retrocausal.ai" },
  { label: "adnan.sameer@retrocausal.ai" },
  { label: "mudassir.muhammad@retrocausal.ai" },
];

const customMobileStyles = {
  content: {
    top: "10%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    // transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "calc(100% - 62px)",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const customStyles = {
  content: {
    top: "80%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "35%",
    height: "80%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

let statusOptions = [
  { value: "Requested", label: "Requested" },
  { value: "Cancelled", label: "Cancelled" },
  { value: "Scheduled", label: "Scheduled" },
  { value: "Ongoing", label: "Ongoing" },
  { value: "Success", label: "Success" },
];

const CreateMeeting = ({
  isOpen = false,
  isUpdate = false,
  onRequestClose = () => {},
}) => {
  let { creatorInfo, createMeeting, analyzeMeeting, updateMeeting } =
    useMeetingStore();
  let { fetchPlatformUsers } = useUserStore();
  let { charges, fetchAccountCharges } = useChargesStore();
  let { account, user } = useAuthStore();
  let { createNotifier, cancelNotifier } = useNotifyStore();

  const [descriptionView, setDescriptionView] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Requested");
  const [attendees, setAttendees] = useState([]);
  const [newAttendees, setNewAttendees] = useState([]); // incase of update we have attendees to list participants and also we have new attendees that will track our new attendees then we will hit create notify api parallel
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [selectedAttendee, setSelectedAttendee] = useState("");
  const [duration, setDuration] = useState(1);
  const [loading, setLoading] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    if (creatorInfo?.User) {
      setTitle(`Meeting With ${creatorInfo?.User?.FirstName}`);
    }
    if (isUpdate && analyzeMeeting) {
      setTitle(analyzeMeeting?.Title);
      setDescription(analyzeMeeting?.Description);
      setStartDate(analyzeMeeting?.ScheduleTime);
      setStartTime(moment(analyzeMeeting?.ScheduleTime).format("hh:mm A"));
      setAttendees(
        analyzeMeeting?.Participants?.map((participant) => ({
          label: participant?.User?.FirstName,
          value: participant?._id,
          meta: {
            accountId: participant?._id,
            notifyId: participant?.NotifyId,
          },
        }))
      );
      fetchAccountCharges(analyzeMeeting?.OrganizerAccount);
    } else {
      fetchAccountCharges(id);
    }
  }, [JSON.stringify(creatorInfo), JSON.stringify(analyzeMeeting)]);

  const toggleDescriptionView = () => {
    setDescriptionView((prev) => !prev);
  };

  let handleTime = (e) => {
    const { value } = e.target;
    setStartTime(value);
  };

  let handleDuration = (duration) => {
    if (isUpdate) return; // do not update
    setDuration(duration);
  };

  const filterColors = async (inputValue) => {
    // return selectedAttendees.filter((data) =>
    //   data.label.toLowerCase().includes(inputValue.toLowerCase())
    // );
    if (!inputValue) {
      return [];
    }
    const response = await fetchPlatformUsers(inputValue);
    return response;
  };

  const loadOptions = async (inputValue, callback) => {
    // setTimeout(() => {
    //   callback(filterColors(inputValue));
    // }, 5000);
    if (!inputValue) {
      return callback([]);
    }
    const response = (await fetchPlatformUsers(inputValue)).filter(
      (data) => ![creatorInfo.User._id, user._id].includes(data.value)
    );
    return callback(response);
  };

  const toggleTitleEditing = () => {
    if (!title.length) return;
    setIsEditingTitle((prev) => !prev);
  };

  const handleAttendees = (attendee) => {
    let isAttendeeExist = attendees.find(
      (data) => data.label.toLowerCase() == attendee.label.toLowerCase()
    );
    if (!isAttendeeExist) {
      setAttendees((prev) => [...prev, attendee]);
      if (isUpdate) {
        let isAttendeeExist = newAttendees.find(
          (data) => data.label.toLowerCase() == attendee.label.toLowerCase()
        );
        if (!isAttendeeExist) {
          setNewAttendees((prev) => [...prev, attendee]);
        }
      }
    }
  };

  const removeAttendee = async (accountId) => {
    let newlyCreated = newAttendees.find((data) => data.value == accountId);
    if (isUpdate && !newlyCreated) {
      let notifyId = attendees.find((data) => data.value == accountId).meta
        .notifyId;
      await cancelNotifier(notifyId);
      setNewAttendees((prev) =>
        prev.filter((attendee) => attendee.value !== accountId)
      );
    }
    // return
    setAttendees((prev) =>
      prev.filter((attendee) => attendee.value !== accountId)
    );
    setSelectedAttendee("");
  };

  const handleCreateMeeting = async () => {
    if (!title.length || !description.length || !startTime.length) {
      return Error("Title, Description and Time are required fields");
    }

    if (!isValidTimeFormat(startTime)) {
      return Error("Invalid Time Format");
    }
    setLoading(true);
    let date = new moment(startDate).format("DD/MM/YYYY");

    let scheduleDate =
      moment(startDate, "DD/MM/YYYY").format("YYYY-MM-DD") + " " + startTime;

    // Format the combined date and time to ISO 8601 string
    let isoString = moment(scheduleDate, "YYYY-MM-DD hh:mm A").toISOString();

    let plan = charges?.find((data) => data.Duration == duration)._id;

    if (id == account._id) {
      Error("You cannot schedule a meeting by yourself");
      setLoading(false);
      return;
    }
    // check isUpdate if true then update meeting notifier parallely
    if (isUpdate) {
      let mappingNewAttendees = newAttendees.map((attendee) => {
        let payload = {
          AccountId: attendee.meta.accountId,
          EntityId: analyzeMeeting._id,
          EntityType: NotifyTypeConstants.MEET,
        };
        return createNotifier(payload);
      });
      await Promise.allSettled(mappingNewAttendees);
    }

    let data = {
      ...(!isUpdate && { OrganizerAccount: id }),
      ...(!isUpdate && { CreatorAccount: account._id }),
      Title: title,
      Description: description,
      ScheduleTime: isoString,
      ...(!isUpdate && {
        Participants: [
          ...attendees.map((data) => data.value),
          creatorInfo.User._id,
          user._id,
        ],
      }), // organizer n=and meeting creator is by default participants
      ...(!isUpdate && { Plan: plan }),
    };
    // return
    if (isUpdate) {
      await updateMeeting(analyzeMeeting._id, data);
    } else {
      await createMeeting(data);
    }
    setLoading(false);
    onRequestClose();
  };

  function handleCreatorName() {
    if (isUpdate) {
      if (!analyzeMeeting) return;
      let Creator = analyzeMeeting?.Participants.find(
        (data) => data !== analyzeMeeting.OrganizerAccount
      ).User;
      return `${Creator.FirstName} ${Creator.LastName ? Creator.LastName : ""}`;
    }
    return `${creatorInfo?.User?.FirstName} ${
      creatorInfo?.User?.LastName ? creatorInfo?.User?.LastName : ""
    }`;
  }

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  return (
    <>
      <ReactModal
        style={getScreenStyle()}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="Example Modal"
        parentSelector={() => document.querySelector("#root")}
      >
        <div className="text-white flex flex-col h-full">
          {!isEditingTitle ? (
            <div className="flex justify-between items-start gap-3">
              <div className="text-lg font-normal">{title}</div>
              <div
                onClick={toggleTitleEditing}
                className="p-2 border-[1px] cursor-pointer border-solid border-[#353B4B] rounded-md bg-[#353B4B]"
              >
                <MdModeEdit />
              </div>
            </div>
          ) : (
            <div className="flex justify-between items-center gap-3">
              <Input
                classes={"w-[100%] text-white"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <div
                onClick={toggleTitleEditing}
                className={`p-2 border-[1px] cursor-pointer border-solid border-[#353B4B] rounded-md ${
                  title.length ? "bg-[#8254F8]" : "bg-[#353B4B]"
                } `}
              >
                <IoCheckmark />
              </div>
            </div>
          )}

          <div className="my-5 flex-1">
            <div className="grid grid-cols-3">
              <div className="font-normal text-sm text-[#AAAAAA]">Creator</div>
              <div className="font-normal col-span-2 text-sm text-white capitalize">
                {handleCreatorName()}
              </div>
            </div>
            <div className="grid grid-cols-3 my-3 items-center">
              <div className="font-normal text-sm text-[#AAAAAA]">
                Meeting Date*
              </div>
              <div className="font-normal col-span-1 text-sm text-black">
                <DatePicker
                  className="bg-[#353B4B] rounded text-sm text-white py-1 px-3 border-none active:border-none w-content"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="grid grid-cols-3 mb-3 items-center">
              <div className="font-normal text-sm text-[#AAAAAA]">
                Meeting Time*
              </div>
              <div className="font-normal col-span-2 w-[150px] text-sm text-black">
                {/* <DatePicker  className="bg-[#353B4B] rounded-md text-white py-1 px-3 border-none active:border-none w-content" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                <Input
                  id={"title"}
                  onChange={handleTime}
                  placeholder={"08:00 PM"}
                  value={startTime}
                  classes={
                    "bg-[#353B4B] text-sm py-1 px-3 w-[150px] text-white"
                  }
                />
              </div>
            </div>

            {/* <div className="grid grid-cols-3 mb-3 items-center">
              <div className="font-normal text-sm text-[#AAAAAA]">
                Status
              </div>
              <div className="font-normal col-span-2 w-[150px] text-sm text-black">
                <Dropdown disabled={true} menuClassName='bg-black text-white' options={statusOptions} onChange={handleStatus} value={status} placeholder="Select an option" />
              </div>
            </div> */}

            <Separator />
            <div className="my-3">
              <div className="font-normal text-sm text-[#AAAAAA]">
                Plan Selection*
              </div>
              <div className="my-3 gap-2 flex items-center">
                {charges?.length &&
                  charges.map((charge) => (
                    <PackageCard
                      onClick={handleDuration}
                      name={TypeToChargeMapping[charge.Duration]}
                      price={charge.Amount}
                      isActive={duration === charge.Duration}
                      duration={charge.Duration}
                    />
                  ))}
              </div>
            </div>

            <Separator />

            <div className="my-4">
              <Input
                id={"description"}
                label="Description"
                placeholder={
                  "What You want to discuss in meeting, like: mention your questions"
                }
                classes={
                  "bg-[#353B4B] text-sm py-1 px-3 w-full rounded text-white"
                }
                type="textarea"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </div>

            <Separator />

            <div className="my-4">
              {/* <Input id={"participants"} label="Invite Participants" placeholder={'Participants Email'} classes={'bg-[#353B4B] text-sm py-1 px-3 w-full rounded'} /> */}
              <div className="font-normal text-sm text-[#AAAAAA]">
                Participants
              </div>
              <div className="text-xs text-[#AAAAAA] mb-2">
                Please feel free to choose three more participants
              </div>
              <AsyncSelect
                placeholder={"Find users by name or email address"}
                theme={(theme) => ({
                  ...theme,
                  borderWidth: "0px",
                  marginTop: "10px",
                  colors: {
                    // ...theme.colors,
                    text: "orangered",
                    primary25: "hotpink",
                    primary: "white",
                  },
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderWidth: "0px",
                    borderColor: "#353B4B",
                    backgroundColor: "#353B4B",
                    border: "1px solid #353B4B",
                    boxShadow: 0,
                    color: "#ffffff",
                    fontSize: "13px",
                    marginBottom: "1px",
                    ":active": {
                      border: "1px solid #353B4B",
                    },
                    ":hover": {
                      border: "1px solid #353B4B",
                    },
                  }),
                  option: () => ({
                    cursor: "pointer",
                    backgroundColor: "#353B4B",
                    fontSize: "13px",
                    padding: "3px 6px",
                    color: "#ffffff",
                    ":hover": {
                      backgroundColor: "#8254F8",
                    },
                    ":active": {
                      backgroundColor: "#8254F8",
                    },
                  }),
                  menu: () => ({
                    marginTop: "0px",
                    background: "#353B4B",
                  }),
                }}
                isDisabled={attendees.length >= 3}
                value={""}
                cacheOptions
                loadOptions={loadOptions}
                defaultOptions={[]}
                className="bg-black text-white"
                onChange={(choice) => handleAttendees(choice)}
              />
            </div>
            <div className="flex items center flex-wrap gap-2">
              {attendees.map((data) => (
                <div className="text-sm rounded-full py-1 pl-3 pr-2 cursor-pointer bg-[#353B4B] flex items-center gap-2">
                  {data.label}{" "}
                  <MdOutlineCancel onClick={() => removeAttendee(data.value)} />{" "}
                </div>
              ))}
            </div>
          </div>
          <div className="py-5 flex justify-end">
            <Button
              buttonText={"Create"}
              isDisabled={loading}
              onClick={handleCreateMeeting}
              isActive={true}
            />
          </div>
        </div>
        {/* <div className="h-[500px] w-full rounded-lg">
              <Input id={"title"} label={'Title'} placeholder={'Title'} value={''} onChange={()=>{}} />
          </div> */}
      </ReactModal>
    </>
  );
};

CreateMeeting.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export { CreateMeeting };
