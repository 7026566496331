export const MeetingStatusConstantsMapping = {
    1: 'Requested',
    2: 'Schedule',
    3: 'Inprogress',
    4: 'Completed',
    5: 'Cancelled',
    6: 'Waiting For Payment'
  }

  export const MeetingStatusConstants = {
    Requested: 1,
    Schedule: 2,
    Inprogress: 3,
    Completed: 4,
    Cancelled: 5,
    WAITING_FOR_PAYMENT: 6,
  }

export const MeetingFilters = [
  {
    Title: 'All',
    value: null
  },
  {
    Title: 'Requested',
    value: 1
  },
  {
    Title: 'Schedule',
    value: 2
  },
  {
    Title: 'Inprogress',
    value: 3
  },
  {
    Title: 'Completed',
    value: 4
  },
  {
    Title: 'Cancelled',
    value: 5
  },
  {
    Title: 'Hold',
    value: 6
  },
]