import React, { useState } from "react";
import { Problems as ProblemsComponent } from "../../components/Problems/Problems";

const Problems = (props) => {
  return (
    <div>
      <ProblemsComponent />
    </div>
  );
};

Problems.propTypes = {};

export { Problems };
