import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { RatingComponent } from '../../components/RatingComponent/RatingComponent';
import { useRatingStore } from '../../store/rating/rating';

const Rating = props => {
    return <div>
        <RatingComponent />
    </div>;
};

Rating.propTypes = {};

export { Rating };