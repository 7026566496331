import React, { useState } from "react";
import Recorder from 'react-video-recorder-18'

const VideoRecorder = ({setVideo}) => {

  const onRecordingComplete = (videoBlob) => {
    // Do something with the video...
    console.log('videoBlob', videoBlob)
    setVideo(videoBlob)
  }
  return <div className="w-[900px] h-[600px] p-10">
    <div className="rounded-xl h-full overflow-hidden">  
      <Recorder
          onRecordingComplete={onRecordingComplete}
          />
    </div>
  </div>;
};

VideoRecorder.propTypes = {};

export { VideoRecorder };