import { Get, baseUrl } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export async function GetAllSkills(){
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/skills/get`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function GetCreatorExpertise(){
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/skills/getCreatorExpertise`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function GetNestedSkills(skillId){
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/skills/getSubSkills/${skillId}`, Authorization)
    } catch (error) {
       console.log(error)
       throw error 
    }
}