import { Get, Post, baseUrl } from "..";
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper";

export const FetchAccountCharges = async (id) => {
  try {
    let { Authorization } = UserCredentials();
    return await Get(`${baseUrl}/charges/account/${id}`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const CreateOrUpdateCharges = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/charges/create`, data, Authorization);
  } catch (error) {
    throw error;
  }
};

export const ConnectWithStripe = async (data) => {
  try {
    let { Authorization } = UserCredentials();
    return await Post(`${baseUrl}/account/sync`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ChargeYourWallet = (data) => {
  try {
    let { Authorization } = UserCredentials();
    return Post(`${baseUrl}/payment/chargeYourWallet`, data, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetCurrentBalance = () => {
  try {
    let { Authorization } = UserCredentials();
    return Get(`${baseUrl}/wallet/get`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetPlatformEarnings = () => {
  try {
    let { Authorization } = UserCredentials();
    return Get(`${baseUrl}/payment/platform-earn`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetAccountPayouts = () => {
  try {
    let { Authorization } = UserCredentials();
    return Get(`${baseUrl}/payment/payouts`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const GetPaymentMethod = () => {
  try {
    let { Authorization } = UserCredentials();
    return Get(`${baseUrl}/payment/card-info`, Authorization);
  } catch (error) {
    console.log(error);
    throw error;
  }
};
