import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import PeerInvitation from "./PeerInvitation";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "0%",
    transform: "translate(-50%, -50%)",
    padding: 0,
    // background: "#1F1D2B",
    border: "0px",
    width: "fit-content",
    height: "fit-content",
    maxWidth: "fit-content",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.1)",
  },
};

const customMobileStyles = {
  content: {
    top: "10%",
    left: "62px",
    right: "auto",
    bottom: "auto",
    // transform: "translate(-50%, -80%)",
    background: "#1F1D2B",
    border: "none",
    width: "calc(100% - 62px)",
    height: "90%",
    maxWidth: "1260px",
    borderRadius: "10px",
  },
  overlay: {
    background: "rgba(0,0,0,0.2)",
  },
};

const PeerInvitationModal = ({ isOpen, onRequestClose, discussion, id }) => {
  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return customStyles;
    } else {
      return customMobileStyles;
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={true}
      style={getScreenStyle()}
      contentLabel="Example Modal"
      parentSelector={() => document.querySelector("#root")}
    >
      {/* Hello */}
      <PeerInvitation id={id} />
    </ReactModal>
  );
};

PeerInvitationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export { PeerInvitationModal };
