import React, { useEffect, useState } from "react";
import Select from "react-select";
import { customStyles } from "../../config/constants/customStyles";
import { languageOptions } from "../../config/constants/languageOptions";
import { useProblemStore } from "../../store/problem/problem";

const LanguagesDropdown = ({ onSelectChange, language }) => {
  let { languages } = useProblemStore();
  let [progLanguageOptions, setProgLanguageOptions] = useState(languageOptions);

  useEffect(() => {
    setProgLanguageOptions(languages);
  }, [JSON.stringify(languages)]);

  return (
    <Select
      placeholder={`Filter By Category`}
      options={progLanguageOptions}
      styles={customStyles}
      value={language}
      defaultValue={language}
      onChange={(selectedOption) => onSelectChange(selectedOption)}
    />
  );
};

export default LanguagesDropdown;
