import { SkeletonTheme } from 'react-loading-skeleton';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.css';
import { router } from './router/router';

import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const AppWrapper = () => {
  return (
    <div className="scroll-bar">
      <SkeletonTheme baseColor="#391E57" highlightColor="#4B2B68">
        <ToastContainer />
        <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
};

export default AppWrapper;
