import { Get, Post, Put, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const CreateDiscussion = async (data) => {
    try { 
        let {Authorization} = UserCredentials()
        return Post(`${baseUrl}/discussion/create`, data, Authorization)
    } catch (error) {
        throw error
    }
}

export const GetAccountDiscussions = async (pageConfig) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/discussion/account?Page=${pageConfig.currentPage}&Limit=${pageConfig.limit}&SortBy=${pageConfig.sortBy}&SortOrder=${pageConfig.sortOrder}${pageConfig.filteredBy && `&Status=${pageConfig.filteredBy}`}${pageConfig.searchText && `&Search=${pageConfig.searchText}`}${`&Upcoming=${pageConfig.Upcoming}`}`, Authorization)
    } catch (error) {
        throw error
    }
}

export const GetDiscussionInfo = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return await Get(`${baseUrl}/discussion/get/${id}`, Authorization)
    } catch (error) {
        throw error
    }
}

export const UpdateDiscussion = async (id, data) => {
    try {
        let {Authorization} = UserCredentials()
        return Put(`${baseUrl}/discussion/update/${id}`, data, Authorization)
    } catch (error) {
        throw error
    }
}

export const GetSuggestedDiscussions = async () => {
    try {
        let {Authorization} = UserCredentials()
        return Get(`${baseUrl}/discussion/getInterestedDiscussion`, Authorization)
    } catch (error) {
        throw error
    }
}

export const StartDiscussion = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return Put(`${baseUrl}/discussion/start/${id}`, null, Authorization)
    } catch (error) {
        throw error
    }
}

export const EndDiscussion = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return Put(`${baseUrl}/discussion/end/${id}`, null, Authorization)
    } catch (error) {
        throw error
    }
}

export const CancelDiscussion = async (id) => {
    try {
        let {Authorization} = UserCredentials()
        return Put(`${baseUrl}/discussion/cancel/${id}`, null, Authorization)
    } catch (error) {
        throw error
    }
}