import { create } from "zustand";
import { BecomeParticipants } from "../../service/participants";

export const useParticipantsStore = create((set, get) => ({
    participantInfo: null,
    sessionToken: null,

    async becomeParticipant(data){
        try {
            let Participant = await BecomeParticipants(data)
            set((state) => ({participantInfo: Participant.ParticipantInfo, sessionToken: Participant.SessionToken}))
            return Participant
        } catch (error) {
            console.log(error)
        }
    },

    resetSessionToken ()  {
        set((state) => ({sessionToken: null}))
    }

}))