import { useState } from "react";
import React from "react";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import PasswordEye from "../../assets/svg/PasswordEye.svg";
import { useAuthStore } from "../../store/auth/auth";
import { AccountStatusConstants } from "../../config/constants/account";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  let { login, account } = useAuthStore();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const handleSubmit = async () => {
    // e.preventDefault();
    setLoading(true)
    let data = {
      Email: email,
      Password: password,
    };
    let { user, account } = await login(data);
    setLoading(false)

    if (!account) return;
    if (account.Status == AccountStatusConstants.INACTIVE) {
      navigate("/auth/account/activation");
    } else {
      navigate("/");
    }
  };

  let handleDisableButton=()=>{
    if(!email.length || !password.length){
      return false
    }
    return true
  }

  return (
    <div>
      <form action="" class="flex flex-col gap-y-4">
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"Email"}
          type="email"
          classes={"py-3 text-sm"}
        />
        <Input
          onChange={(e) => setPassword(e.target.value)}
          placeholder={"Password"}
          onImageClick={() => setShowPassword(!showPassword)}
          type={showPassword ? "text" : "password"}
          classes={"py-3 text-sm"}
          image={PasswordEye}
        />
        <div class="relative"></div>
        <Button
          onClick={handleSubmit}
          fullWidth={true}
          isDisabled={loading}
          isActive={handleDisableButton()}
          classes={"text-sm font-semibold px-7"}
          buttonText={"Login"}
        />
      </form>
    </div>
  );
};

export default SignIn;
