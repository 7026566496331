import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQuizProfileStore } from "../../store/quizProfile/quizProfile";
import { useAuthStore } from "../../store/auth/auth";
import {
  formatTime,
  inMinutes,
  monthsAndDaysFormat,
} from "../../config/helpers/moment-helper";
import { FaRegUser } from "react-icons/fa6";
import { QuizProfileCardType } from "../../config/constants/quizProfileCardType";
import { useQuizPerformanceStore } from "../../store/quizPerformance/quizPerformance";
import { useSearchParams } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "react-tooltip";

const QuizProfileCard = ({ type = QuizProfileCardType.QUICK_BITES }) => {
  let { getQuizProfile, quizProfile } = useQuizProfileStore();
  let { getQuizPerformanceReport, currentWeekReport } =
    useQuizPerformanceStore();
  let { account, user, getAccountInfo, userProfilePicture } = useAuthStore();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    fetchQuizProfile();
  }, []);

  let fetchQuizProfile = async () => {
    try {
      let startDate = searchParams.get("startDate");
      let endDate = searchParams.get("endDate");
      if (type == QuizProfileCardType.REPORT) {
        let duration = {
          StartDate: startDate || "",
          EndDate: endDate || "",
        };
        await getQuizPerformanceReport(duration);
      } else {
        await getQuizProfile();
      }
      // await getQuizProfile();
    } catch (error) {
      console.log(error);
    }
  };
  const calculateEfficiency = (correctAnswers, incorrectAnswers) => {
    const totalAnswers = correctAnswers + incorrectAnswers;
    if (totalAnswers === 0) return "0%"; // To avoid division by zero

    let efficiency = (correctAnswers / totalAnswers) * 100;
    // Check if the efficiency is a whole number
    if (Number.isInteger(efficiency)) {
      return efficiency + "%"; // Return as integer
    } else {
      return efficiency.toFixed(1) + "%"; // Return with one decimal
    }
  };
  return (
    <div className="relative rounded-xl bg-[#252836] w-full h-full py-3 overflow-hidden">
      {/* Gradient Border */}
      {/* <div className="absolute inset-0 rounded-xl border-[3px] border-transparent bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border"></div> */}

      {/* Card Content */}
      <div className="relative flex flex-col justify-between items-center text-white">
        {userProfilePicture ? (
          <div className="w-[60px] h-[60px] rounded-full mx-auto overflow-hidden border-[3px] bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border">
            <img className="w-full h-full" src={userProfilePicture} />
          </div>
        ) : (
          <div
            className={`w-[60px] h-[60px] flex justify-center items-center rounded-full bg-gradient-to-r from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border text-3xl`}
          >
            <FaRegUser className={``} />
          </div>
        )}
        <div className="text-center mt-2 font-bold">{user?.FirstName}</div>
      </div>

      {type == QuizProfileCardType.QUICK_BITES ? (
        <div className="relative flex-row px-5 my-5 text-white">
          <div className="flex justify-between">
            <div className="text-sm flex items-center gap-x-1">
              Knockouts{" "}
              <span
                data-tooltip-id="knockout-info"
                data-tooltip-content="Sessions with 100% efficiency"
              >
                <Tooltip id="knockout-info" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.KnockOuts}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Correct Answers</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.CorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Incorrect Answers</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.InCorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between my-2">
            <div className="text-sm">Efficiency</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.CorrectAnswers && quizProfile?.InCorrectAnswers
                ? calculateEfficiency(
                    quizProfile?.CorrectAnswers,
                    quizProfile?.InCorrectAnswers
                  )
                : "0"}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-sm">Total Time</div>
            <div className="text-xl lg:text-3xl font-bold">
              {quizProfile?.TotalTimeInSeconds &&
                formatTime(quizProfile?.TotalTimeInSeconds)}
            </div>
          </div>
        </div>
      ) : (
        <div className="relative flex-row px-5 my-5 text-white">
          <div className="flex justify-center items-end">
            {currentWeekReport?.StartDate !== "" &&
            currentWeekReport?.EndDate !== "" ? (
              <div className="font-semibold italic text-gray-400">
                {monthsAndDaysFormat(currentWeekReport?.StartDate)} -{" "}
                {monthsAndDaysFormat(currentWeekReport?.EndDate)}
              </div>
            ) : null}
          </div>
          <div className="flex justify-between items-end">
            <div className="text-sm flex items-center gap-x-1">
              Sessions{" "}
              <span
                data-tooltip-id="profile-card-total-sessions"
                data-tooltip-content="Number of sessions you have planned"
              >
                <Tooltip id="profile-card-total-sessions" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.TotalSessions}
            </div>
          </div>
          <div className="flex justify-between items-end my-2">
            <div className="text-sm flex items-center gap-x-1">
              Completed{" "}
              <span
                data-tooltip-id="profile-card-completed-sessions"
                data-tooltip-content="Number of sessions you have completed"
              >
                <Tooltip id="profile-card-completed-sessions" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.SessionsCompleted}
            </div>
          </div>
          <div className="flex justify-between items-end my-2">
            <div className="text-sm flex items-center gap-x-1">
              Knockouts{" "}
              <span
                data-tooltip-id="knockout-info"
                data-tooltip-content="Sessions with 100% efficiency"
              >
                <Tooltip id="knockout-info" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.KnockOuts}
            </div>
          </div>
          <div className="flex justify-between items-end my-2">
            <div className="text-sm flex items-center gap-x-1">
              Learning Time{" "}
              <span
                data-tooltip-id="profile-card-learning-time"
                data-tooltip-content="Your learning time"
              >
                <Tooltip id="profile-card-learning-time" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.TotalTimeOnLearning
                ? inMinutes(currentWeekReport?.TotalTimeOnLearning)
                : "0"}
              <span className="text-sm"> Min </span>
            </div>
          </div>
          <div className="flex justify-between items-end my-2">
            <div className="text-sm flex items-center gap-x-1">
              Committed{" "}
              <span
                data-tooltip-id="profile-card-committed-time"
                data-tooltip-content="Time You have committed"
              >
                <Tooltip id="profile-card-committed-time" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.TimeCommittedOnLearning
                ? currentWeekReport?.TimeCommittedOnLearning
                : "0"}
              <span className="text-sm"> Min </span>
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div className="text-sm flex items-center gap-x-1">
              Correct{" "}
              <span
                data-tooltip-id="profile-card-correct-answers"
                data-tooltip-content="Correct answers"
              >
                <Tooltip id="profile-card-correct-answers" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.CorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div className="text-sm flex items-center gap-x-1">
              Incorrect{" "}
              <span
                data-tooltip-id="profile-card-incorrect-answers"
                data-tooltip-content="Incorrect answers"
              >
                <Tooltip id="profile-card-incorrect-answers" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span>
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.InCorrectAnswers}
            </div>
          </div>
          <div className="flex justify-between items-end">
            <div className="text-sm flex items-center gap-x-1">
              Efficiency{" "}
              {/* <span
                data-tooltip-id="profile-card-efficiency"
                data-tooltip-content="Correct answers"
              >
                <Tooltip id="profile-card-efficiency" />
                <IoIosInformationCircleOutline className="text-md cursor-pointer" />
              </span> */}
            </div>
            <div className="text-xl lg:text-2xl font-bold">
              {currentWeekReport?.Efficiency}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QuizProfileCard.propTypes = {};

export { QuizProfileCard };
