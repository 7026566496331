import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { Quiz as QuizComponent } from '../../components/Quiz/Quiz';

const Quiz = props => {
    return <div>
        <QuizComponent />
    </div>;
};

Quiz.propTypes = {};

export { Quiz };