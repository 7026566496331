import { create } from "zustand";
import {
  CreateQuizChallenge,
  GetQuizChallenges,
  GetQuizPerformanceReport,
  GetQuizPerformanceWeeklyInsights,
  IsWeekAlreadyTracing,
} from "../../service/quizPerformance/quizPerformance";

export const useQuizPerformanceStore = create((set, get) => ({
  currentWeekReport: null,
  challengesReport: [],
  currentWeekStatus: false,
  weeklyReport: {},

  async createQuizChallenge(payload) {
    try {
      let resp = await CreateQuizChallenge(payload);
      return resp;
    } catch (error) {
      console.log(error);
    }
  },

  async getQuizChallenges() {
    try {
      let resp = await GetQuizChallenges();
      let dummyCardData = [
        {
          StartDate: "2024-09-11T19:00:00.000Z",
          EndDate: "2024-09-19T18:59:59.999Z",
          KnockOuts: 1,
          QuizCategoriesTitle: ["JavaScript", "Docker"],
          TotalSessions: 13,
          SessionsCompleted: 10,
          TotalTimeOnLearning: 6000,
          Efficiency: "84.3%",
        },
      ];
      set({ challengesReport: resp });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },

  async getQuizPerformanceReport(duration) {
    // current week, sidebar
    try {
      let resp = await GetQuizPerformanceReport(duration);
      let dummyResponse = {
        StartDate: "2024-09-11T19:00:00.000Z",
        EndDate: "2024-09-19T18:59:59.999Z",
        KnockOuts: 1,
        QuizCategoriesTitle: ["JavaScript", "Docker"],
        TotalSessions: 25,
        SessionsCompleted: 21,
        TotalTimeOnLearning: 6000,
        TimeCommittedOnLearning: 105,
        CorrectAnswers: 25,
        InCorrectAnswers: 37,
        Efficiency: "84.3%",
      };
      set({ currentWeekReport: resp });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },

  async getQuizPerformanceWeeklyInsights(accountId, startDate, endDate) {
    // current week, sidebar
    try {
      let resp = await GetQuizPerformanceWeeklyInsights(
        accountId,
        startDate,
        endDate
      );
      let dummyData = {
        quizCategoriesMapping: {
          "66af3bb0cbd52eeb2f96e96a": "JavaScript",
          "66c617306d3bf08a7bc1d5de": "Docker",
        },
        CountQuizzesSolvedByLevelMode: [
          {
            count: 19,
            levelMode: 1,
          },
          {
            count: 6,
            levelMode: 2,
          },
          {
            count: 4,
            levelMode: 3,
          },
          {
            count: 2,
            levelMode: 4,
          },
        ],
        CorrectAndIncorrectAnswersByCategory: [
          {
            correctAnswers: 40,
            incorrectAnswers: 10,
            categoryId: "66af3bb0cbd52eeb2f96e96a",
          },
          {
            correctAnswers: 44,
            incorrectAnswers: 6,
            categoryId: "66c617306d3bf08a7bc1d5de",
          },
        ],
        ComparativeAnalysisByCategory: {
          "66af3bb0cbd52eeb2f96e96a": [
            {
              day: "2024-09-11",
              totalCorrectAnswers: 12,
            },
            {
              day: "2024-09-12",
              totalCorrectAnswers: 22,
            },
            {
              day: "2024-09-13",
              totalCorrectAnswers: 6,
            },
            {
              day: "2024-09-14",
              totalCorrectAnswers: 25,
            },
            {
              day: "2024-09-15",
              totalCorrectAnswers: 15,
            },
            {
              day: "2024-09-16",
              totalCorrectAnswers: 16,
            },
            {
              day: "2024-09-17",
              totalCorrectAnswers: 18,
            },
            {
              day: "2024-09-18",
              totalCorrectAnswers: 22,
            },
          ],
          "66c617306d3bf08a7bc1d5de": [
            {
              day: "2024-09-11",
              totalCorrectAnswers: 14,
            },
            {
              day: "2024-09-12",
              totalCorrectAnswers: 22,
            },
            {
              day: "2024-09-13",
              totalCorrectAnswers: 17,
            },
            {
              day: "2024-09-14",
              totalCorrectAnswers: 14,
            },
            {
              day: "2024-09-15",
              totalCorrectAnswers: 27,
            },
            {
              day: "2024-09-16",
              totalCorrectAnswers: 10,
            },
            {
              day: "2024-09-17",
              totalCorrectAnswers: 18,
            },
            {
              day: "2024-09-18",
              totalCorrectAnswers: 19,
            },
          ],
        },
        WeeklyAppearanceAndTimeCommitment: [
          {
            _id: "2024-09-11",
            count: 20,
            totalTimeTaking: 10,
            lastQuizTime: null,
          },
          {
            _id: "2024-09-12",
            count: 2,
            totalTimeTaking: 1.9166666666666667 * 12,
            lastQuizTime: "2024-09-12T11:19:34.375Z",
          },
          {
            _id: "2024-09-13",
            count: 1,
            totalTimeTaking: 1 * 30,
            lastQuizTime: "2024-09-13T19:23:45.938Z",
          },
          {
            _id: "2024-09-14",
            count: 5,
            totalTimeTaking: 10,
            lastQuizTime: null,
          },
          {
            _id: "2024-09-15",
            count: 5,
            totalTimeTaking: 1.7 * 12,
            lastQuizTime: "2024-09-15T10:09:57.528Z",
          },
          {
            _id: "2024-09-16",
            count: 2,
            totalTimeTaking: 3.8333333333333335 * 7,
            lastQuizTime: "2024-09-16T13:26:37.138Z",
          },
          {
            _id: "2024-09-17",
            count: 0,
            totalTimeTaking: 10,
            lastQuizTime: null,
          },
          {
            _id: "2024-09-18",
            count: 0,
            totalTimeTaking: 22,
            lastQuizTime: null,
          },
        ],
        TimeSpentOnEachCategory: [
          {
            totalTimeTaking: 4000,
            count: 6,
            lastQuizTime: "2024-09-12T11:19:34.375Z",
            categoryId: "66c617306d3bf08a7bc1d5de",
          },
          {
            totalTimeTaking: 2000,
            count: 4,
            lastQuizTime: "2024-09-12T19:50:09.770Z",
            categoryId: "66af3bb0cbd52eeb2f96e96a",
          },
        ],
      };
      set({ weeklyReport: { ...resp } });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },

  async isWeekAlreadyTracing() {
    try {
      let currentWeekStatus = await IsWeekAlreadyTracing();
      set({ currentWeekStatus: currentWeekStatus });
    } catch (error) {
      console.log(error);
    }
  },
}));
