import { create } from "zustand";
import { CreateRating, FetchCreatorRating } from "../../service/rating/rating";
import { MeetingCell } from "../../components/DiscussionComponent/DiscussionComponent";
import { FaRegStar, FaRegUser } from "react-icons/fa6";
import * as moment from "moment";
import { FetchQuizCategories } from "../../service/quizCategory/quizCategory";
import { FetchQuizByCategory } from "../../service/quiz/quiz";
import { FetchQuestionsByQuiz } from "../../service/question/question";
import { useQuizStore } from "../quiz/quiz";

export const useQuestionStore = create((set, get) => ({
    questions: [],
    quiz: null,
    quizMaterial: null,

    async fetchQuestionsByQuiz(id){
        try {
            let {Questions, Quiz, Category, QuizMaterial} = await FetchQuestionsByQuiz(id)
            set(()=>({questions: Questions, quiz: Quiz, quizMaterial: QuizMaterial}))

            // Access and update the quizCategory from the quiz store
            useQuizStore.getState().setQuizCategory(Category);
            return Questions
        } catch (error) {
            console.log(error)
        }
    }
}))