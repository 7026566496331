import React, { useState } from "react";
import PropTypes from "prop-types";

import { Input } from "../Input/Input";
import { Separator } from "../Separator/Separator";
import { MdOutlinePayments } from "react-icons/md";
import { Button } from "../Button/Button";
import { RiPencilFill } from "react-icons/ri";
import { IoCheckmark } from "react-icons/io5";
import GoogleSvg from '../../assets/svg/Google.svg'
import { baseUrl } from "../../service";

const SyncCalendar = (props) => {
  const [loading, setLoading] = useState(false);

  let handleSyncGoogleCalendar = async () => {
    try {
      setLoading(true)
      window.location.href = `${baseUrl}/auth/google-calendar-auth-redirect`;
      // await useAuthStore.getState().GoogleRedirect()
    } catch (error) {
      setLoading(false)
      console.log(error);
    }
  };

  return (
    <div className="bg-[#181818] text-white p-5 rounded-lg my-5">
      <div className="text-lg">Calendar Integration</div>
      <div className="my-6 text-sm font-light">
        Ensure seamless scheduling by syncing your Discoursefy account with
        Google Calendar. By connecting your calendar, you can effortlessly
        manage your availability and appointments directly from our platform.
        Stay organized and never miss a meeting with synchronized updates across
        both platforms.
      </div>

      <div className="my-6">
        <Separator />
      </div>

      <Button
        classes={"text-sm font-semibold px-7 w-[280px]"}
        isImage={true}
        buttonText={"Sync with Google Calendar"}
        image={GoogleSvg}
        isDisabled={loading}
        onClick={()=>handleSyncGoogleCalendar()}
      />
    </div>
  );
};

SyncCalendar.propTypes = {};

export { SyncCalendar };
