import React, { useState } from "react";
import PropTypes from "prop-types";
import { Billing as BillingComponent } from "../../components/Billing/Billing";
const Billing = (props) => {
  return (
    <div>
      <BillingComponent />
    </div>
  );
};

Billing.propTypes = {};

export { Billing };
