import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useAccountStore } from "../../store/account/account";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import LinkedInSvg from "../../assets/svg/linkedin.svg";

const Payout = (props) => {
  let { becomeCreator, getLinkedInAuthUrl } = useAccountStore();

  let navigate = useNavigate();
  useEffect(() => {
    becomeCreator();
  }, []);

  const fetchLinkedInAuthUrl = async () => {
    try {
      let url = await getLinkedInAuthUrl();
      // console.log('linkedIn Auth url: ',url)
      window.location.href = url;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full h-[100vh] flex justify-start items-center text-2xl flex-col gap-y-6">
      <div>Payouts Configured Successfully🎉</div>
      <span className="text-lg font-normal block max-w-[900px] text-center">
        Congratulations on becoming a Discoursefy creator! Your payout details
        have been successfully configured. Get ready to share your knowledge and
        start earning. <br /> 
        <span className="py-5">
        Share your exciting news with your network on LinkedIn!
        Let your connections know about this incredible opportunity. Click below
        to create a pre-written post.
        </span>
      </span>
      <Button
        classes={"font-semibold px-3 w-[280px]"}
        isImage={true}
        buttonText={"Celebrate Your Achievement"}
        image={LinkedInSvg}
        isDisabled={false}
        isActive={true}
        onClick={fetchLinkedInAuthUrl}
      />
    </div>
  );
};

Payout.propTypes = {};

export { Payout };
