import { Get, Post, Put, baseUrl } from ".."
import { UserCredentials } from "../../config/helpers/local-storage-accessor-helper"

export const GetCreatorEducation = async (accountId) => {
    try {
        let {Authorization} = UserCredentials()       
        return await Get(`${baseUrl}/education/getByAccount/${accountId}`, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const CreateEducation = async (account, data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Post(`${baseUrl}/education/create`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const UpdateEducation = async (id, data) => {
    try {
        let { Authorization } = UserCredentials()
        return await Put(`${baseUrl}/education/update/${id}`, data, Authorization)
    } catch (error) {
        console.log(error)
        throw error
    }
}