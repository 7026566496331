import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { EarningCard } from "./EarningCard/EarningCard";
import { Separator } from "./Separator/Separator";
import { useChargesStore } from "../store/charges/charges";
import getSymbolFromCurrency from "currency-symbol-map";
import { Table } from "./Table/Table";
import { Button } from "./Button/Button";
import { useNavigate } from "react-router-dom";
import Loader from '../assets/gif/buttonLoader.gif'

let headers = [
  {
    label: "Amount",
    cols: 3,
    id: "amount",
  },
  {
    label: "Initiated",
    cols: 3,
    id: "created",
  },
  {
    label: "Estimated Arrival",
    cols: 3,
    id: "arrivalDate",
  },
  {
    label: "Status",
    cols: 3,
    id: "status",
  },
];

const EarnComponent = (props) => {
  let {
    getPlatformEarnings,
    platformEarning,
    getAccountPayouts,
    accountPayouts,
  } = useChargesStore();

  const [loading, setLoading] = useState(true)

  let navigate = useNavigate();

  useEffect(() => {
    fetchPlatformEarning();
    return () => {};
  }, []);

  let fetchPlatformEarning = async () => {
    try {
      await getPlatformEarnings();
      await getAccountPayouts();
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  };

  let handleTotalPayments = () => {
    return parseFloat(
      platformEarning.availableBalance + platformEarning.totalPayoutAmount
    )?.toFixed(2);
  };

  let handleRedirectButton = () => {
    navigate('/account/setting')
  } 
  return (
    <div className={`w-full rounded-lg bg-[#181818] p-5`}>
      <div className="text-lg">Earnings Overview</div>
      <div className="my-6 text-sm font-light">
        Welcome to your Earnings Overview! This page provides a comprehensive
        summary of your earnings on Discoursefy. Below, you'll find details
        about your total balance, pending payouts, and available balance.{" "}
        {platformEarning
          ? `Please
        review the information below to stay informed about your earnings.`
          : ""}
      </div>
      <div className="my-6">
        <Separator />
      </div>
      {!loading ? <div>
      {platformEarning ? (
        <div className="">
          <div className="text-xl font-white">Account Balances</div>
          <div className="my-3">
            <div className="font-light text-sm">PAYMENTS</div>
            <div className="my-3">
              <Separator />
            </div>
            <div className="flex items-center gap-x-10">
              <div className="text-xl font-semibold">
                <div className="font-normal text-sm mb-3">
                  Available Balance
                </div>
                {getSymbolFromCurrency(platformEarning?.currency)}
                {platformEarning?.availableBalance?.toFixed(2)}{" "}
                <span className="font-light uppercase">
                  {" "}
                  {platformEarning?.currency}{" "}
                </span>
              </div>

              {/* <div className="text-xl font-semibold">
              <div className="font-normal text-sm mb-3">
                Pending Balance
              </div>
              {getSymbolFromCurrency(platformEarning?.currency)}{platformEarning?.pendingBalance?.toFixed(2)} <span className="font-light uppercase"> {platformEarning.currency} </span>
            </div> */}

              <div className="text-xl font-semibold">
                <div className="font-normal text-sm mb-3">Total Payout</div>
                {getSymbolFromCurrency(platformEarning.currency)}
                {platformEarning.totalPayoutAmount.toFixed(2)}{" "}
                <span className="font-light uppercase">
                  {" "}
                  {platformEarning.currency}{" "}
                </span>
              </div>

              <div className="text-xl font-semibold">
                <div className="font-normal text-sm mb-3">
                  Pending Transfers
                </div>
                ${platformEarning.pendingTransfer.toFixed(2)}{" "}
                <span className="font-light uppercase"> USD </span>
              </div>
            </div>
          </div>

          <div className="my-5">
            <Separator />
          </div>

          <div className="my-3">
            <div className="font-light text-sm">TOTAL VOLUME</div>
            <div className="my-3">
              <Separator />
            </div>
            <div className="flex items-center gap-x-10">
              <div className="text-xl font-semibold">
                <div className="font-normal text-sm mb-3">
                  Lifetime Total Volume
                </div>
                {getSymbolFromCurrency(platformEarning.currency)}
                {handleTotalPayments()}{" "}
                <span className="font-light uppercase">
                  {" "}
                  {platformEarning.currency}{" "}
                </span>{" "}
                + ${platformEarning.pendingTransfer.toFixed(2)}{" "}
                <span className="font-light"> USD </span>
              </div>

              {/* <div className="text-xl font-semibold">
              <div className="font-normal text-sm mb-3">
                Pending Transfer
              </div>
              $0.00 <span className="font-light"> USD </span>
            </div> */}
            </div>
          </div>

          <div className="my-5">
            <Separator />
          </div>
          <div className="font-light text-sm">PAYOUTS</div>
          <div className="my-3">
            <Separator />
          </div>
          <Table
            headers={headers}
            meetings={accountPayouts}
            currentPage={1}
            totalPages={1}
            loading={false}
            onNextPage={() => {}}
            onPreviousPage={() => {}}
            footer={false}
          />
        </div>
      ) : (
        <>
          <div className="my-10 w-[70%] mx-auto">
            <div className="text-white text-lg text-center ">
              Join Discoursefy's Creator community to share your expertise
              globally. Earn income by setting rates for discussions, expand
              your network, and enjoy flexible scheduling. Engage in meaningful
              discussions, mentorship, and personal growth opportunities while
              making a global impact.
            </div>
            <div className="text-center my-3">
              <Button
              onClick={handleRedirectButton}
                buttonText={"Become A Creator"}
                classes={"w-[200px] rounded-full"}
                isActive={true}
              />
            </div>
          </div>
        </>
      )}
      </div>: <div style={{height: '200px'}} className="flex justify-center items-center">
          <img src={Loader} />
      </div>}
    </div>
  );
};

EarnComponent.propTypes = {};

export { EarnComponent };
