import React, { useState } from "react";
import PropTypes from "prop-types";
import ProblemComponent from "../../components/Problem/Problem";

const Problem = (props) => {
  return (
    <div>
      <ProblemComponent />
    </div>
  );
};

Problem.propTypes = {};

export { Problem };
